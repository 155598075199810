import React ,{useState} from 'react';
import ProgressiveImage from 'react-progressive-image';

const Gallery = ({gallery}) => {

  const [page, setPage] = useState(0)


  const incPage = () => {
    if (page + 1 < gallery.length) 
      setPage(page+1);
  }
  const decPage = () => {
    if (page - 1 >= 0) 
      setPage(page-1);
  }

  const img = gallery[page]

  return (
    <div className="gallery-container">
      <Page key={img.url} img={img}/>
      <Controls {...{
        page: page+1,
        total: gallery.length,
        incPage,decPage,

      }}/>
    </div>
  );
};

const Controls = ({page,total,incPage, decPage}) => {
  return (
    <div className="gallery-controls">
      <div className="gallery-control-page">
        {page}{"/"}{total}
      </div>
      <div className="gallery-control-left" onClick={decPage}>
        {"≪"}
      </div>
      <div className="gallery-control-right" onClick={incPage}>
        {"≫"}
      </div>
    </div>
  );
}

const Page = ({
  img
}) => {

  const { url, thumbnail } = img;

  return (
    <div className="gallery-page-container">
      <ProgressiveImage src={url} placeholder={thumbnail}>
        {(src,loading) => <img 
          src={src} alt={''} 
          className={"gallery-image "+(loading?'gallery-image-loading':'gallery-image-loaded')}
        />}
      </ProgressiveImage>
    </div>
  )
}

export default Gallery;
