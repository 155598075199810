import React from 'react';

// We have to input it manually, __filename won't work with webpack
const year = '2021'

/** Converts a partial relative URL to an absolute one */
const abs = (url) => {
  // @see https://create-react-app.dev/docs/using-the-public-folder/#adding-assets-outside-of-the-module-system
  return `${process.env.PUBLIC_URL}/assets/${year}/${url}`
}

/*
* Because of the font, some letters are 
* inverted and moved up to keep desired aspect. 
'moved up' example: <div>Glacier Soundsca<em>p</em>e</div>,
'inverted' example: <div>On <span className='flip-letter'>E</span>arth</div>,

grid cells are of type: {video,pdf,text,album (audio)}
assets are uploaded to amazon s3. 
*/

const data = {
  year,
  title: <span>THE <em>Rewilding</em> YEARS <em>3 – 12 September</em>, 2021</span>,
  date: '',
  // Link opened when clicking the year's title (can be null for no link)
  headerAsset: abs('pdfs/Climate_Care-Programme-2021.pdf'),
  // Shown as soon as the page starts
  introVideo: abs('floating.mp4'),
  // Shown while the video loads
  introFrame: abs('cover.jpg'),
  // This asset is the grid's tile background+border
  backgroundAsset: abs('tile.png'),
}

/* Content for grid and windows */
const grid = [
  //ROW 1
  //1
  {
    title: <div>Selected Readings on Dirt</div>,
    author: 'Guided by spätispäti',
    caption:'spätispäti is a human collective based in Berlin and founded in 2019. It actively questions institutional learning structures, space production, consumerism and relations of trust and power within the city and beyond.',
    pdf: {
      url:abs('pdfs/25-selected_readings_on_dirt-spatispati.pdf'),
    },
    category:'Reading',
    date:'08.09 17:00–18:00',
    alt: true,
  },
  //2
  {
    title: <div>Rewilding Urban Spaces</div>,
    author: <div>Johanna Wehkamp, Barbora Sedova</div>,
    text:<div>
      <div>
        Urban areas offer an often untapped potential for biodiversity conservation and ecosystem restoration. The concept of “rewilding” is gaining momentum in public debate and has been both promoted and criticised. Central elements in these discussions are social acceptability and benefits – especially in densely populated areas – and the role of humans in the restoration efforts more broadly. Discussions around different restoration and conservation strategies have also gained prominence, because climate change damages are increasingly felt – also in urban areas. In this conversation, the role and potential of rewilding will be discussed, both as a concept for conservation in urban areas and in the context of a warming climate. To what extent is rewilding useful as a strategy in urban areas to a.o. mitigate and adapt to the changing climate?
      </div><br/>
    
      <div>
        The conversation is moderated by Johanna Wehkamp and Barbora Sedova both alumnis of the Mercator Research Institute on Global Commons and Climate Change (MCC). Panelist are Dr. Andrea Perino, ecologist at the German Centre of Integrative Biodiversity Research (iDiv) in Leipzig, Ulrich Stöcker/Katrin Schikorr,  NGO Deutsche Umwelthilfe (DUH) and the initiative “Rewilding Oder Delta”, Sina Ribak, researcher for ecologies and the art,  Aljoscha Hofmann, team leader of the strategy team Tempelhof Project GmbH. 
      </div><br/>

      <div className="text-footnote">
        Johanna Wehkamp works as a policy officer at the Federal Ministry for the Environment, Nature Conservation and Nuclear Safety.
      </div><br/>
      <div className="text-footnote">
        Barbora Sedova  is a researcher, studying climate change impacts on human security at the Potsdam institute for Climate Impact Research (PIK).
      </div>
    </div>,
    category:'Conversation',
    date:'11.09.2021 14:00-17:00',
  },
  //3
  {
    title: <div>Zabriskie Bookshop</div>,
    author: <div>Climate Care Selection</div>,
    text:<div>
      <div>
        Zabriskie is a specialist bookshop with a focus on sub-cultural and natural phenomena. It is almost like a private library because we only present books that we personally value and like. At Zabriskie you can find extraordinary things from the fields of natural history, nature writing, ecology and sustainability; gardening, self-sufficiency and DIY; underground music and film; utopian ideas and alternative ways of living; small press and artist publications; idleness and slowness; drugs and consciousness; walking, travelling and places; essays on philosophical and social issues; countercultural movements; strange and occult phenomena; fantastic, weird and experimental fiction. A temporary bookshop will manifest on-site for the duration of Climate Care.
      </div>
      <br/>

      <div className="text-footnote">
        Some Titles specially selected for Climate Care: The Rewilding Years<br/>
        <br/>
        Robin Wall Kimmerer, Braiding Sweetgrass: Indigenous Wisdom, Scientific Knowledge and the Teachings of Plants<br/>
        <br/>
        Irene de Craen (ed.) Errant Journal, Slow Violence - Issue 2<br/>
        <br/>
        Stefano Mancuso, Die Pflanzen und Ihre Rechte – Eine Charta zur Erhaltung unserer Natur<br/>
        <br/>
        Richard Mabey,The Unofficial Countryside<br/>
        <br/>
        Franziska Klose, Detroit – Field Notes From a Wild City<br/>
        <br/>
        Claire Ratinon & Sam Ayre, Horticultural Appropriation<br/>
        <br/>
        Cathy Lane, Angus Carlyle (eds.), In the Field – The Art of Field Recording<br/>
        <br/>
        Matthew Schneider-Mayerson and Brent Ryan Bellamy (eds.), An Ecotopian Lexicon<br/>
        <br/>
        Sandor Ellix Katz, Fermentation as Metaphor<br/>
        <br/>
        Lynn Margulis, Der symbiotische Planet oder wie die Evolution wirklich verlief<br/>
        <br/>
        Adrian Cooper (ed.), Arboreal – A Collection of Words from the Woods<br/>
        <br/>
        Suzanne Simard, Finding the Mother Tree – Uncovering the Wisdom and Intelligence<br/>
        <br/>
        Sandra Bartoli und Silvan Linden (Hg.), AG8: Berliner Bäume. Eine Bestandsaufnahme<br/>
        <br/>
        Jay Griffiths, Wild – An Elemental Journey<br/>
        <br/>
        Lucy Jones, Losing Eden – Why Our Minds Need the Wild <br/>
        <br/>
        Emmanuel Vaughan-Lee, Seanna Quinn, Bethany Ritz (eds.), Emergence Magazine – Volume 2
      </div>

    </div>,
    category:'Bookshop',
    date:'"3.09. – 5.09. 16:00 – 20:00 10.09. – 12.09. 16:00 – 20:00',
  },
  //4
  {
    title: <div>A Parasitic Reading Room</div>,
    author: <div>Guided by Gilly Karjesvky and Rosario Talevi (Soft Agency)</div>,
    caption:<div>
      The Parasitic Reading Room is an open format formed by a multitude of voices in a spontaneous set of reading spaces. Texts gathered in a reader are spoken out loud by participants who should have a willingness to be affected by other voices and ideas. A Parasitic Reading Room intends to provoke a contagion of knowledge by acting as a parasite on its chosen site, as well as its reading participants.
      <br /><br />
      Soft Agency is a diasporic group of female architects, artists, curators, scholars and writers working with spatial practices.
    </div>,
    gallery:[
      {
        url: abs('04/1_12-09_reading_soft-agency_10.jpg'),
        thumbnail: abs('04/1_12-09_reading_soft-agency_10.jpg'),
      },
      {
        url: abs('04/2_12-09_reading_soft-agency_05.jpg'),
        thumbnail: abs('04/2_12-09_reading_soft-agency_05.jpg'),
      },
      {
        url: abs('04/3_07-09_reading_michelle-marc_10.jpg'),
        thumbnail: abs('04/3_07-09_reading_michelle-marc_10.jpg'),
      },
      {
        url: abs('04/4_2021_Floating_CLIMATECARE_CFlamme_038.jpg'),
        thumbnail: abs('04/4_2021_Floating_CLIMATECARE_CFlamme_038.jpg'),
      },
      {
        url: abs('04/5_2021_Floating_CLIMATECARE_CFlamme_806.jpg'),
        thumbnail: abs('04/5_2021_Floating_CLIMATECARE_CFlamme_806.jpg'),
      },
      {
        url: abs('04/6_2021_Floating_CLIMATECARE_CFlamme_910.jpg'),
        thumbnail: abs('04/6_2021_Floating_CLIMATECARE_CFlamme_910.jpg'),
      },
    ]
  }, 
  //5
  {
    title: <div>Listening at the Threshold</div>,
    author: 'Siobhan Leddy',
    text:<div>
      <div>
        Part-lecture performance, part-deep listening session, this workshop takes the soundscape of Floating University as its natural/cultural starting point. The work uses processes of defamiliarisation and technological mediation to open up moments of more-than-human listening. Beginning with cochlear (ear-based) listening, the audience will be guided through various auditory thresholds and increasingly non-human scales to generate moments of reconfigured, even uncanny, sensing.
      </div>
      <br/>

      <div className="text-footnote">
        Siobhan Leddy is a researcher, writer, and artist. Her research focuses on how artworks can offer methodologies for more-than-human communication and para-linguistic knowledge-creation. She is currently working towards a PhD at the Freie Universität Berlin, where she also teaches an MA seminar in media theory and practice.
      </div>
    </div>,
    category:'Deep Listening',
    date:'09.09 19:00–20:00',
  },
  //6
  {
    title: <div>Becoming Pattern</div>,
    author: <div>Valentina Karga<br/> Marjetica Potrč'</div>,
    caption:<div>
      Nature is never monochromatic. Her visual complexity is a shelter for all critters. Our architectures reflect a larger ontological issue: a black and white Cartesian mentality where everything is organised into monochromatic categories. Providing visual shelter for city critters is about accepting our animal selves. In the two days of collective activity we will work with patterns that originate in nature. We will paint patterns with earthen pigments on the architecture of the Floating. 
      <br /><br />
      Valentina Karga was born in Chalkidiki, a peninsula in northern Greece. She lives in Berlin and she is a professor at the Hochschule für bildende Künste (HFBK) in Hamburg.
      <br /><br />
      Marjetica Potrč is an artist and architect based in Ljubljana, Slovenia. Potrč’s practice includes drawing series, architectural case studies and public art projects.
    </div>,
    gallery:[
      {
        url: abs('06/0_2021_Floating_CLIMATECARE_CFlamme_864.jpg'),
        thumbnail: abs('06/0_2021_Floating_CLIMATECARE_CFlamme_864.jpg'),
      },
      {
        url: abs('06/1_2021_Floating_CLIMATECARE_CFlamme_638.jpg'),
        thumbnail: abs('06/1_2021_Floating_CLIMATECARE_CFlamme_638.jpg'),
      },
      {
        url: abs('06/2_2021_Floating_CLIMATECARE_CFlamme_643.jpg'),
        thumbnail: abs('06/2_2021_Floating_CLIMATECARE_CFlamme_643.jpg'),
      },
      {
        url: abs('06/3_11-09_becoming-pattern_valentina-karga_marjetica-potrc_13.jpg'),
        thumbnail: abs('06/3_11-09_becoming-pattern_valentina-karga_marjetica-potrc_13.jpg'),
      },
      {
        url: abs('06/4_2021_Floating_CLIMATECARE_CFlamme_595.jpg'),
        thumbnail: abs('06/4_2021_Floating_CLIMATECARE_CFlamme_595.jpg'),
      },
      {
        url: abs('06/5_-09_becoming-pattern_valentina-karga_marjetica-potrc_14.jpg'),
        thumbnail: abs('06/5_-09_becoming-pattern_valentina-karga_marjetica-potrc_14.jpg'),
      },
      {
         url: abs('06/6_12-09_becoming-pattern_valentina-karga_marjetica-potrc_23.jpg'),
        thumbnail: abs('06/6_12-09_becoming-pattern_valentina-karga_marjetica-potrc_23.jpg'),
      },
      {
        url: abs('06/7_2021_Floating_CLIMATECARE_CFlamme_906.jpg'),
        thumbnail: abs('06/7_2021_Floating_CLIMATECARE_CFlamme_906.jpg'),
      },
      {
        url: abs('06/8_2021_Floating_CLIMATECARE_CFlamme_920.jpg'),
        thumbnail: abs('06/8_2021_Floating_CLIMATECARE_CFlamme_920.jpg'),
      },
      {
        url: abs('06/9_12-09_becoming-pattern_valentina-karga_marjetica-potrc_29.jpg'),
        thumbnail: abs('06/9_12-09_becoming-pattern_valentina-karga_marjetica-potrc_29.jpg'),
      },
    ]
  }, 
  //7
  {
    title: <div>Becoming Animal,<br/> an Earthly Cosmology</div>,
    windowTitle:<div>Becoming Animal, an Earthly Cosmology by David Abram and Performing Ground, Space, Camouflage  and the Art of Blending In by Laura Levin</div>,
    author: <div>Guided by Valentina Karga and Marjetica Potrč</div>,
    pdf: {
      url:abs('pdfs/32-becoming_animal_earthly_cosmology.pdf'),
    },
    category:'Reading',
    date:'10.09.2021 17:00-18:00',
    alt:true,
  },
  //8
  {
    title: <div>Panarchist Dinner in Berlin 2100</div>,
    author: <div>Kate Tyndall and Sarah Friend</div>,
    caption:<div>
        The Panarchist Dinner in Berlin 2100 spans multiple possible futures, exploring the ways that changing biological, technological, social, and political spheres will impact our eating. The migrations of a multitude of species and cultures, as well as the intervention of anthropic science, have created flavors and aromas of which the city’s ancestors of 2021 could only dream.
          <br />
        We do the dreaming actively: in the form of a tasting menu and discursive dinner, in full awareness of the silliness of the exercise of prediction and the risk of lapsing into retrofuturism. 
          <br /><br />
        Katharine Tyndall is an SES (social-ecological systems) researcher and Sarah Friend is artist and software engineer.  
          <br /><br />
      </div>,
    albums:[
      {
        cover:abs('08/10-09_panarchist-dinner_kate-tyndal_sarah-friend_25.jpg'),
        thumbnail:abs('08/10-09_panarchist-dinner_kate-tyndal_sarah-friend_25.jpg'),
        tracks:[
          {id: '01',title:'Mushroom Stew with Hardtack, Nettle Tea and a Mood-Lifter. Story by Katharine Tyndall, read by Dietrich Meyer',url:abs('08/1_mushroom and hard tack.mp3')},
          {id: '02',title:'GMO drought-resistant Tomato (pickled with fresh herbs). Story and reading by Sarah Friend',url:abs('08/2_green-roof.mp3')},
          {id: '03',title:'Cattail Birthday Cake with Dwellberry Jam. Story by Katharine Tyndall, read by Elena Frau',url:abs('08/3_Cattail Pancake.mp3')},
          {id: '04',title:'Meatstock and Nutrient Pellet Arrays. Story by Sarah Friend, read by Wassim Alsindi',url:abs('08/4_meatstock.mp3')},
          {id: '05',title:'Acorn Jelly and Dulse Salad. Story and reading by Katharine Tyndall',url:abs('08/5_algae-farm.mp3')},
        ]
      }
    ],
  },
  
  //ROW 2
  //9
  {
    title:<div>Faunal soundscapes / Ambientphiles</div>,
    author:'Infuso Giallo + Fog Puma',
    category:'b2b DJ set',
    text:<div>
       Playing back-to-back for the first time, these two collectors of ambient esoterica will fill the Floating basin with sounds that sway both non-humans and humans alike. Bringing soundscapes of remote regions, different climates and previously-unimagined musical colours to the site, Infuso Giallo and Fog Puma’s playing styles will range from Hiroshi Yoshimura to Stevie Wonder to create an atmosphere of wonderment and wondering. 
    <br /><br />
       Infuso Giallo is a Berlin-based producer and DJ. His live sets are a fluid, ever-changing, ever-new collection and reinterpretation of his tracks, alternating between ambient and club contexts. 
    <br/><br/>
       Fog Puma aka Jean-Marie Dhur is a DJ and radio host. He curates and moderates the music radio show Abendlandung on Berlin’s experimental radio platform Cashmere Radio.
    </div>,
    },
  
  //10
  {
    title: <div>Wild Design</div>,
    author: 'Monaí de Paula Antunes',
    text:<div>
      Wild Design is a framework for researching design practices in which humans are not driven by notions of control, stability, durability or safety, instead they resonate with synonyms of “wild”, such as untamed, radical, marginal, uncontrollable, uncivilized, informal, undisciplined, unruly and unconventional. 
      <br />
        <a href="https://drive.google.com/file/d/18vGGojrI51BOHVyA5HIKW5sVDNvMWI1q/view?usp=sharing">Read Monai’s  text: Wild Design: Gambiarra, Complexity and Responsibility</a>
      <br/> <br />
      Monaí de Paula Antunes is an artistic researcher interested in complexity and communication together with their material, spatial and political entanglements.
    </div>,
   },
  //11
  {
    title: <div> Unsettling the Coloniality of (...) An Argument by Sylvia Wynter</div>,
    windowTitle:<div>Unsettling the Coloniality of Being/Power/ Truth/Freedom: Towards the Human, After Man, Its Overrepresentation – An Argument by Sylvia Wynter</div>,
    author: <div>Guided by Black Earth Collective </div>,
    pdf: {
      url:abs('pdfs/11-unsettling_the_Coloniality.pdf'),
    },
    category:'Reading',
    date:'10.09.2021 17:00-18:00',
    alt:true,
  },
  //12
  {
    title: <div>Natura Urbana - The Brachen of Berlin</div>,
    windowTitle: <div>Natura Urbana - The Brachen of Berlin</div>,
    author: 'Directed by Matthew Gandy',
    caption:<div>United Kingdom, Germany, 2017, 72', color</div>,
    category:'Film screening',
    date:'04.09 20:30–22:00',
    url:'https://vimeo.com/239804870',
    type:'vimeo',
  },
  //13
  {
    title: <div>On the Trail of Water</div>,
    author: 'Selina Syed',
    text:<div>
      <div>
        Where does the drinking water in Berlin actually come from? Where does the wastewater go? How does the natural water cycle work and how is it influenced by humans? Natural groundwater recharge is a slow process and our water consumption exceeds recharge in many parts of the world, where water is also polluted with contaminants. The aim of the On the Trail of Water is to better orient participants with the fragility of water as a valuable resource that needs protection. 
      </div>
      <br/>
    
      <div>
        The workshop will trace the path of water from ground to tap – and use Berlin as a lens through which to understand access to water worldwide. 
      </div>
      <br/>

      <div className="text-footnote">
        Selina Syed studied geoecology in Potsdam. She is active in the field of sustainability and works in a community garden following the principles of permaculture. 
      </div>
    </div>,
    category:'Workshop',
    date:'05.09 + 12.09 14:00–17:00',
  },
  //14
  {
    title: <div>Marx’s Ecological Notebooks <br/>by Kohei Saito</div>,
    windowTitle: <div>Marx’s Ecological Notebooks by Kohei Saito</div>,
    author: 'Guided by Patricia Reed',
    pdf: {
      url:abs('pdfs/5-marx_ecological_notebooks.pdf'),
    },
    category:'Reading',
    date:'04.09 17:00-18:00',
    alt: true,
  },
  //15
  {
    title: <div>Visual Language</div>,
    author: 'Studio YUKIKO',
    caption:<div>
      Gathering rich, varied textures from the site of the Floating University the visual identity is “wild” both materially and aesthetically, an overlay of type and tree bark that celebrates the complex entanglements of the world around us. While floating amongst the reed and mud, Climate Care reminds us of why we should imagine and create new forms of living.
      <br /><br />
      Studio Yukiko is a Berlin-based creative agency specialising in creative direction, art direction, brand strategy, concept generation and graphic design for commercial and cultural clients alike.
    </div>,
    gallery:[
      {
        url: abs('15/CC_poster.jpg'),
        thumbnail: abs('15/CC_poster.jpg'),
      },
      {
        url: abs('15/climate-care-insta_4.jpg'),
        thumbnail: abs('15/climate-care-insta_4.jpg'),
      },
      {
        url: abs('15/climate-care-insta_5.jpg'),
        thumbnail: abs('15/climate-care-insta_5.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-4.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-4.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-5.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-5.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-6.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-6.jpg'),
      },
      {
         url: abs('15/PROGRAM_ClimateCare-PreviewNew-7.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-7.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-9.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-9.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-10.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-10.jpg'),
      },
      {
        url: abs('15/PROGRAM_ClimateCare-PreviewNew-11.jpg'),
        thumbnail: abs('15/PROGRAM_ClimateCare-PreviewNew-11.jpg'),
      },
    ]
  }, 
  //16
  {
    title: <div>Wangari Maathai: Centering Black Voices (...) </div>,
    windowTitle: <div>Wangari Maathai: Centering Black Voices in the Environmental Movement</div>,
    author: <div>Black Earth Collective and Rahab Njeri</div>,
    text:<div>
      <div>
        “It is the people who must save the environment. It is the people who must make their leaders change. And we cannot be intimidated. So we must stand up for what we believe in.” Wangari Maathai
      </div>
      <br/>

      <div>
        Rahab Njeri and Tatu would like to bring to the fore the legacy of one of the most important women of the climate change and environmental movement. Before intersectionality was a buzzword for the mainstream, Wanagare Maathai understood how the wellbeing of the environment affected Kenyan women and gender justice as a whole. From the perspective of the Africana Womanist framework, this screening and conversation will look at the life and struggles of one of the strongest fighters of the Kenyan environmental movement who has been active since the 1970s. Starting with input from Rahab Njeri and Tatu, the event will move on to a shared discussion on how current environmental movements can marginalise frontline fighters from the Global South. The evening will end with screenings that centre Black voices and their vision and stories of Wangare Maathai.
      </div>
      <br/>

      <div className="text-footnote">
        Black Earth Collective is a Berlin-based climate justice collective pursuing QTBIPoC solidarity. We understand anthropogenic climate change as a historical development, beginning with slavery and colonialism which fuelled the industrial revolution. Black Earth agitates for an intersectional climate movement, with decolonial, queer-feminist and ecological perspectives. 
      </div><br/>

      <div className="text-footnote">
        Rahab Njeri studied British and North American History, African Studies, and English Studies at the University of Cologne. In 2013, she finished her interdisciplinary master’s thesis with the title “If you’re light, you’re Alright”: Skin Bleaching among African American Women (1945-1970). In 2013, Njeri was a doctoral student at the University of Trier, working on her Ph.D. project Black Canadas: Construction and Representation of Blackness in Halifax, Toronto, and Montreal from 1960 to 1990.
      </div><br/>

      <div className="text-footnote">
        Tatu completed her Bachelor’s degree in African Studies at the University of Leipzig before moving to the Netherlands for her Master’s degree in International Relations and International Organisations at the University of Groningen. She specialised on topics such as global environmental politics and international development. Throughout her studies, she sought to apply an intersectional feminist and post-colonial lens on scientific research and critical analysis of various international development  and climate policies.
      </div>
    </div>,
    category:'Screening and conversation',
    date:'11.09 19:00-22:00',
  },
  

  //ROW 3
  //17
  {
    title: <div>Purity is not an option<br /> Selected readings</div>,
    windowTitle:<div>Purity is not an option - Selected readings</div>,
    author: 'Guided by Hooops',
    caption:'Hooops is an experimental platform where knowledge around medicinal plants and fungi is shared, documented and practiced.',
    pdf: {
      url:abs('pdfs/28-purity_is_not_an_option-hooops.pdf'),
    },
    category:'Reading',
    date:'09.09 17:00–18:00',
    alt: true,
  },
  //18
  {
    title:<div>Climate Care 2021</div>,
    author:'Festival Schedule',
    category:'',
    //caption:'this goes bellow windowTitle when it opens',
    date:'',
    pdf: {
      url:abs('pdfs/Climate_Care-Schedule-2021.pdf'),
    },
  },
  //19
  {
    title: <div>The Rewilding Years</div>,
    author: 'Research Map',
    pdf: {
      url:abs('pdfs/Climate_Care-ResearchMap-2021.pdf'),
    },
    category:'',
    date:'',
    alt: true,
  },
  //20
  {
    title: <div>Acasa My Home</div>,
    author: 'Directed by Radu Ciorniciuc',
    caption:<div>Romania and Germany, 2020, 86', color</div>,
    category:'Film screening',
    date:'07.09 20:00–22:00',
    url:'https://vimeo.com/390997288',
    type:'vimeo',
  },
  //21
  {
    title:<div>Symbiotic Planet 
    <br/>(7. Ashore) 
    <br/>by Lynn Margulis</div>,
    windowTitle:<div>Symbiotic Planet (7. Ashore) by Lynn Margulis</div>,
    author:<div>Guided by<br/> Between Us and Nature</div>,
    category:'Reading',
    pdf: {
      url:abs('pdfs/3-symbiotic_planet.pdf'),
    },
    caption:<div>
      <div>
        For Climate Care, Between Us and Nature will be reading the writing of Lynn Margulis’. The session will pay attention to her notion of the “hypersea” and the principle of recycling of matter as a fundamental ecosystem service. Participants will learn about the symbiogenetic interconnections that allow living matter to move from the ocean to the land. The reading is an invitation to speculate on the possibilities of symbiosis at the human-made rainwater retention basin, looking at it as a site of animated water.
      </div>
    </div>,
    date:'03.09 17:00–18:00',
    alt:true,
  },
  //22
  {
    title: <div>c. 250 Hz</div>,
    windowTitle: <div>c. 250 Hz</div>,
    // cellTitle: <div>{'"Should Trees'}<br/> Have Standing?<br/> Law, Morality and the<br/>{'Environment"'}</div>,
    // title: 'Should Trees Have Standing? Law Morality and the Environment',
    author: 'Lisa Ertl and Siobhan Leddy',
    caption:<div>
        Is it possible to cultivate a more-than-human sensory practice? Could certain modes of sensing better attune us to environmental change and species loss, perhaps even fostering more response-able relations with non-human entities? Building on these questions, c. 250 Hz is an experiment in more-than-human listening and the limits of translation.  c. 250 Hz imperfectly translates the bees’ “sonic messages” from the hive’s substrate and into the architecture of Floating University, to be encountered with and through the human visitor’s body.
          <br /><br />
        Siobhan Leddy is a researcher, writer, and artist. <br />
        Lisa Ertl is a consultant for systemic organisational development and a beekeeper. <br />
         </div>,
    albums:[
      {
        cover:abs('22/Screenshot 2022-05-09 at 17.46.04.png'),
        thumbnail:abs('22/Screenshot 2022-05-09 at 17.46.04.png'),
        tracks:[
          {id: '01',title:'C.250 Herz I',url:abs('22/C.250Herz_I.m4a')},
          {id: '02',title:'C.250 Herz II',url:abs('22/C.250Herz_II.m4a')},
          {id: '03',title:'C.250 Herz III',url:abs('22/C.250Herz_III.mp3')},
        ]
      }
    ],
  },

  //23
  {
    title:<div>Wild On!</div>,
    author:'Gilly Karjevsky and Rosario Talevi',
    category:'',
    date: '',
    caption:'',
    text: <div class="curatorial">
      <div>
        The last public event we attended before entering the first coronavirus lockdown in March 2020, was the launch of the digital archive of the first edition of Climate Care festival, held in 2019. Designed as a non-hierarchical depository, the online archive provided access to several media, documenting the first edition and expanding on its events. The aim of that edition of the festival was to chart the intersection of practices of care and practices addressing environmental breakdown.  Spanning all scales of action, we looked to better understand how the paradigm shift offered by ethics of care could be introduced into European society and could help us address the climate challenges we face on a planetary scale. 
      </div><br/>

      <div>
        At the launch of the archive a few of us were already practicing physical distancing, for which we were rewarded with awkward responses of disbelief. It was impossible for most people attending the small gathering at the Zabriskie bookshop to imagine what would come next. Within less than a month the world as we knew it – already broken – spiraled into new realities. It was a scary year, the effects of which are only partially visible. The Coronavirus pandemic also  hit marginalised communities around the world the hardest, making social differences and divides ever more clear, ever more brutal. At the same time that these communities were ravaged by the virus and widespread loss of income, networks of solidarity emerged to step in where the state was lacking, with food and water distribution networks, support for solitary elderly neighbours, self-organised childcare and much more. Preventive, home-based self-care practices, such as meditation and exercise, slow cooking or edible gardening, became vital to mental and physical public health. 
      </div><br/>

      <div>
        For the first time since feminist thinkers begun advocating for them in the 1970s, practices of care were being discussed, recognised and enacted through mainstream channels. This widespread adoption of care has given it a renewed centrality for the paradigm shift that we all seek – moving away from the ruins of capitalist logics towards a paradigm that calculates social and environmental wellbeing, domestic labour and equality of access as central factors in our "gross national product". 
      </div><br/>
    
      <div>
        Time will tell whether or not the Covid-19 pandemic has brought us closer to this crucial shift. While we await the verdict, we turn towards repairing what we can: our own relationship to ourselves and our environment; the ways we assign value; the ways we listen, act, affect, build, consume, relate, learn and unlearn. 
      </div><br/>
    
      <div class="title">
        Climate Care 2021: The Rewilding Years
      </div><br/>
    
      <div class="quote">
        “The Center for Native Peoples and the Environment defines bio-cultural restoration as the science and practice of restoring not only ecosystems, but human and cultural relationships to place, so that cultures are strengthened and revitalized along with the lands to which they are inextricably linked.” Riccardo Rozzi, 2001
      </div><br/>
    
      <div>
        Climate Care is a festival engaged with theory and practice at the intersection of climate challenges, ethics of care and environmental humanities. Emerging from weathering the conditions of its site – a rainwater retention basin in Berlin – the programme is a result of in-depth cohabitation with the constructed water infrastructure, its human culture and its multi-species overlays. 
      </div><br/>
    
      <div>
        Since Climate Care’s first edition things have changed. Activities are still heavily restricted and the formats we can host on site have been reshaped as a result. The pandemic has also already reshaped our conversations on care and climate. As we prepared for the 2021 edition of the festival, we learned of the plans to “re-naturalise” – or “rewild”, to use a different term – the basin, proposed by our landlord Tempelhof GmbH, the state-owned company that manages the site. This means the environment enjoyed by the life forms currently occupying the site will be dramatically altered. For example, the thick concrete floor will be removed and replaced with a porous layer to allow incoming polluted rainwater to filter into the ground. Of this kind of action is not only technical. This will deeply affect both the biodiversity on site, the social use of it and our ability to fill it with cultural programming.
      </div><br/>
    
      <div class="title">
        Natura Basina
      </div><br/>
    
      <div>
        It is simple to see how our presence has been beneficial for the diverse life forms on site. Look at the edges of the buildings and you will see plants growing along them in greater numbers than in the open center of the basin. By slowing the water down, our disturbances  – in the forms of temporary and mobile architectures, walking, growing edible plants and studying this site – have supported the build up of sediment in the basin.  
      </div><br/>
    
      <div>
        When water slows down, sediment (particles of sand, silt and clay) falls out of it. In this sediment, plant roots can take hold and create habitat for frogs to procreate, baby ducks to be born and foxes to hunt. The plants also slow the water down, enabling more sediment to build up and more plants to grow. The cycle is co-constituting. Algae is another entity in the basin that contributes to the build up of the mud. After floods, algae often blooms in thick mats. When the water drains out of the basin into the Landwehrkanal and Spree River, the algae dries up and integrates into the mud as organic matter, broken down by microorganisms. The mud you see in the basin is a product of our disturbances, working in tandem with the reed plants, time, algae and the original engineering of the basin as a holding place for water when floods come. The cattail reed beds have tripled in size since our inhabitation of the site. We see new plants taking hold and old ones multiplying. Young baby willow trees take root in the centre of the concrete basin. 
      </div><br/>
    
      <div>
        We believe this wetland is in the process of transitioning into a grassland and eventually a forest. This habitat is shifting away from aquatic plants and towards grasses, purslane and willow trees that are now showing up. Human disturbances and interventions, such as Floating e.V.’s activities and passivities, working in tandem with the self-will of the burgeoning grassland could, one day, become a small, naturally generated forest – a very rare thing in Berlin.
      </div><br/>
    
      <div class="title">
        From Wild to Rewild
      </div><br/>
    
      <div class="quote">
        “I advance rewilding as a cluster concept: that is, a concept of several overlapping aspects that lacks jointly necessary and sufficient conditions. Conceptualised in this way, ‘rewilding’ as a term can encompass the various meanings already in circulation, meanings that are necessary to understanding the cultural interest in rewilding as an emerging environmental phenomenon”. Andrea R. Gammon, 2018
      </div><br/>
    
      <div>
        Climate Care advances an understanding of the site informed by Gammon. The “cluster concept” we seek for Floating is one that holds the site and it’s biological diversity, the association and its cultural diversity, the Berlin context and situation, all in one bag. To be on site is to be connected to the larger context of Berlin. To think of rewilding in this specific city is to allude to the empty spaces of postwar, post-wall Berlin, where unprecedentedly large areas of free space meant subversive botanical and cultural movements could grow in the cracks: a period known as the “wild years”. 
      </div><br/>
    
      <div>
        This wildness was the result of a particular set of conditions: a sudden regime collapse, an absence of a consolidated state, a city in a slow process of reunification and incredible amount of space – from Second World War bomb sites to empty apartments abandoned by fleeing East Germans. Wildness was also what characterised the unique subcultures and nightlife that emerged exploring, seizing and inhabiting those spaces. 
      </div><br/>
    
      <div>
        While the party was going wild, so was a neoliberal urban development agenda. In order to resolve the city’s debt issues and urgent need for capital, 14 million square metres of Berlin’s public land and empty real estate were sold between 2001 and 2013 for a total of €2.4 billion. As a result, free spaces in the city have become increasingly scarce and the number of displaced residents has soared. In response, a number of citizen-led movements have emerged. For example, Stadt Neudenken successfully demanded more transparent and sustainable urban development policy from Berlin's House of Representatives. Kotti&Co. and its gezekondu began as a protest camp to fight rising rents and later became a local landmark at Kottbusser Tor. The successful Tempelhof referendum prevented the city administration from unfolding their development plans. And Haus der Statistik is currently being developed through an unprecedented collaboration between civic and state actors to collectively invent its future. 
      </div><br/>
    
      <div>
        Despite these efforts, the feeling remains: Berlin has lost its wildness. Wild urban lives have been tamed by higher rents, an absence of a caring policy for public lands, enclosures and privatisation. Profit-driven city-making has displaced the wildness and ushered in waves of gentrification.
      </div><br/>
    
      <div>
        We want to connect with those wild years and reflect on what Berlin and its inhabitants can learn from them. Can we reclaim wildness and rewilding as an attitude to shape our cities and our lives? Can qualities such as openness, otherness, togetherness, joyfulness and playfulness, without romanticising the past, bring us together to foster bonds and interconnectedness, on both the local and the planetary scale?
      </div><br/>
    
      <div>
        Most recently, rewilding has been introduced in cities’ green new deals as a strategy to help downsize the production of CO2 and therefore mitigate climate breakdown. Sometimes these efforts include interventions such as green envelopes (roofs and façades), vertical farming, wildlife corridors, urban gardens, the planting of trees or just allowing “nature” to regenerate. These are welcome efforts, but rewilding should not just be understood and implemented in a reductionist way. Rewilding efforts should be always paired with  social and climate justice and is, therefore, a plea for the systemic changes they require, rather than the solution-oriented approach that continues to drive capitalism, even if it is tinted green.
      </div><br/>
    
      <div>
        Climate Care: The Rewilding Years aims to reclaim some of the qualities and lessons of Berlin’s wild years, and to claim “rewilding” as a careful practice, before it becomes another buzzword devoid of meaning. 
      </div><br/>
    
      <div class="title">
        The Future of the Site
      </div><br/>
    
      <div class="quote">
        "Ultimately, we came to the calm conclusion that no matter who is in power, it is not easy to drive out plants and animals. They may differ from the ones before, but Nature, if I may misuse this word, will return." Herbert Sukopp, in Urbana Natura - the Brachen of Berlin 
      </div><br/>
    
      <div>
        Floating <span class="linethrough">University</span> is a hybrid organisation. It is an artist-run association (Floating e.V) which sits on top of a fully functioning urban water infrastructure, a bio-techno-social site. This infrastructure site has been serving the airfield since it's construction and was closed to the public for many decades forming a third landscape (Clément), or a natureculture (Harraway). Since 2018, the site has opened to the residents of Berlin and provides a place for the artistic exploration of more-than-human cohabitation. 
      </div><br/>
    
      <div>
        As an association, we understand our role not as simple tenants of the basin space but as stewards and caretakers of the different life forms that inhabit it. Now, with plans for refurbishment underway, the future of the site after the intervention is unclear. However, the process is still open and has the potential to be defined collaboratively.
      </div><br/>
    
      <div>
        An urban transformation process is a learning process and therefore should include social and pedagogical components that allow legibility, assimilation and participation. The basin’s rewilding process holds the potential for an urgently needed mediation around eco-social renewal of urban infrastructures in ways that expose how cities are made and maintained, and how they respond to the current climate breakdown and pandemic realities. 
      </div><br/>
    
      <div>
        Could the rewilding process explore the relationship between urban nature and urban infrastructures by establishing a dialogue between artists, academics, engineers, gardeners and technocrats to prototype different possible systems on site?  Dismantling artificial divisions between these forms of practice – artistic, academic and scientific; civic and governmental – should be at the centre of this dialogue.
      </div><br/>
    
      <div>
        Climate Care: The Rewilding Years is a first impulse to bring into public debate what the city of Berlin can learn from different rewilding practices, be they social, technical, ecological, artistic or political. The festival will look critically at the notion of "rewilding" by questioning both the biological and ethical implications of this action on micro and macro scales. Through these different notions of rewilding, we come to need complex definitions: intertwined and entangled terms that can hold more than one, perhaps even contradictory ideas (see page xx).As we work to repair our relationships to nature, the environment, and cultures defined as foreign to us, we first require a carrier bag to hold these together.
      </div><br/>
    </div>,
  },
  //24
  {
    title: <div>Calling into the Now (Part Two)</div>,
    author: 'Teresa Dillon + Members of the Floating e.V.',
    caption:<div>
      Calling into the Now looks to the role that ritual plays in supporting the ontological shifts necessary to move from human-centered to more earth-bound perspectives, whereby the Earth’s inviolability is placed at the heart of what we do, think and make. A multi-layered environment, the Floating site is one in which significant human intervention has unfolded: from the control of rainwater to experiments in multispecies living. Witching the logics and languages of site “use”, this three-part piece conjures the non-human animal, watery life forms, avian creatures and other species spirits that make up this site.
      <br /><br />
      Teresa Dillon is an artist and researcher. Her work is primarily situated within urban spaces and explores the performative, lived entanglements of techno-civic systems. 
    </div>,
    gallery:[
       {
         url: abs('24/03-09_ritual-dillon_06.jpg'),
         thumbnail: abs('24/03-09_ritual-dillon_06.jpg'),
       },
       {
         url: abs('24/2021_Floating_CLIMATECARE_CFlamme_089.jpg'),
         thumbnail: abs('24/2021_Floating_CLIMATECARE_CFlamme_089.jpg'),
       },
       {
         url: abs('24/2021_Floating_CLIMATECARE_CFlamme_092.jpg'),
         thumbnail: abs('24/2021_Floating_CLIMATECARE_CFlamme_092.jpg'),
       },
     ]  
  },
  //ROW 4
  //25
  {
    title: <div>Xoir</div>,
    author: <div>Colin Self</div>,
    caption:<div>
      Xoir (pronounced “choir”) is a vocal workshop led by Colin Self which has been conducted in different settings across the world. Typically used as a tool for experimentation and exploration in group settings, its focus lies on somatic awareness and collective organising, as opposed to memorisation and reciting of sheet music.
      <br /><br />
      Colin Self is an artist currently based in New York and Berlin. They compose and choreograph music, performance and environments for expanding consciousness, troubling binaries and boundaries of perception and communication.
    </div>,
    gallery:[
       {
         url: abs('25/1_2021_Floating_CLIMATECARE_CFlamme_940.jpg'),
         thumbnail: abs('25/1_2021_Floating_CLIMATECARE_CFlamme_940.jpg'),
       },
       {
         url: abs('25/2_2021_Floating_CLIMATECARE_CFlamme_930.jpg'),
         thumbnail: abs('25/2_2021_Floating_CLIMATECARE_CFlamme_930.jpg'),
       },
       {
         url: abs('25/3_2021_Floating_CLIMATECARE_CFlamme_934.jpg'),
         thumbnail: abs('25/3_2021_Floating_CLIMATECARE_CFlamme_934.jpg'),
       },
       {
         url: abs('25/4_12-09_Xoir_Colin-Self_17.jpg'),
         thumbnail: abs('25/4_12-09_Xoir_Colin-Self_17.jpg'),
       },
       {
         url: abs('25/5_2021_Floating_CLIMATECARE_CFlamme_950.jpg'),
         thumbnail: abs('25/5_2021_Floating_CLIMATECARE_CFlamme_950.jpg'),
       },
       {
         url: abs('25/6_IMG_0129.jpg'),
         thumbnail: abs('25/6_IMG_0129.jpg'),
       },
     ]  
  }, 
  //26
  {
    title: <div>Rewilding Berlin</div>,
    windowTitle: <div>Rewilding Berlin: from urban biotopes towards the multispecies city</div>,
    author: 'Sandra Jasper',
    text:<div>
      <div>
        Former railway yards, decommissioned airports and other infrastructural relics provide the substrate for new ecological assemblages. Rather than being empty terrains, these marginal zones of urban wilderness are in fact rich repositories of urban life from which alternative socio-ecological futures can be gleaned. Wasteland spaces are a key focus for ecological research on the adaptability of other-than-human life to urban environments, speculating about future ecologies in future cities. In Berlin, initiatives to protect these unusual zones against imminent development in the interest of finance or urban planning can now draw on a substantial archive of activism that dates back at least half a decade. In recent years, however, many spaces of spontaneous nature have been lost to speculative forms of urban development. At the same time, biodiversity initiatives carve out new urban interstices like traffic islands, façades, and rooftops. Drawing on a range of historical and contemporary examples from Berlin, the talk examines how wastelands have sparked new conservation practices and ideas about urban nature from the biotope city to a multispecies urbanism.
      </div>
      <br/>

      <div className="text-footnote">
        Sandra Jasper is Assistant Professor for Geography of Gender in Human-Environment-Systems at the Humboldt-Universität zu Berlin. Her research interests are in urban nature, soundscapes, and feminist theory. 
      </div>
    </div>,
    category:'Talk',
    date:'04.09 19:00–20:00',
  },
  //27
  {
    title: <div>The Botanical City</div>,
    author: 'Sandra Jasper',
    pdf: {
      url:abs('pdfs/4-the_botanical_city_reading_sandra_jasper.pdf'),
    },
    category:'Reading',
    date:'04.09 17:00-18:00',
    alt: true,
  },
  //28
  {
    title: <div>A Soft Atlas of Floating Trees</div>,
    author: 'Cleo Wächter',
    caption:<div>
      <div >
        City trees are often regarded as bystanders: silent passersby in our daily lives. Cultivated, controlled and planted for different reasons, embedded in strong bureaucratic and historic structures, they make up a large part of our urban surroundings and contribute to our wellbeing. And the trees at the Floating? In terms of urban nature, they are somewhat anarchistic. They grow as if they are squatting in the former rainwater basin. They have developed slowly over time, growing as they wish and on their own terms. They create a cosmopolitan site that is constantly in flux. This audio guide we will take a closer look at the tree ring that surrounds the Floating – an inverse island that surrounds a body of water. Let's take a moment to walk among them and be properly introduced.
      <br/> <br/>
        Cleo Wächter is a photographer and visual researcher. Her research is primarily focused on one’s relationship to their environment and how one inscribes the landscape with meaning. 
      </div>
    </div>,
    albums:[
      {
        cover:abs('28/walk_cleo resize.png'),
        thumbnail:abs('28/walk_cleo resize.png'),
        tracks:[
          {id: '01',title:'EN - A Soft Atlas of Floating Trees',url:abs('28/20210831-asoftguidetothefloatingtrees.mp3')},
          {id: '02',title:'DE - A Soft Atlas of Floating Trees',url:abs('28/20210830-de-asoftguidetothefloatingtrees.mp3')},
        ]
      },
    ]
  },
  //29
  {
    title: <div>SoundS Ambient Night</div>,
    author: <div>Diane Barbé, Mitchell Keaney, Ben Glas, and Menghan Wang</div>,
    category:'DJ Set',
    date:'05.09 22:00–24:00',
    text:'An ambient set with field recordings to close the night.',
  },
  //30
  {
    title:<div>Selected Bedtime Stories for Sleeping in Common</div>,
    windowTitle: <div>Selected bedtime stories for sleeping in common (which is politics)</div>,
    author: 'Guided by Michelle Teran, Marc Herbst',
    pdf: {
      url:abs('pdfs/21-bedtime_stories_for_sleeping_in_common-michelle_teran.pdf'),
    },
    category:'Reading',
    date:'07.09 17:00–18:00',
    alt: true,
  },
  //31
  {
    title: <div>Un-Domesticating the Wild</div>,
    author: 'Sound Studies and Sonic Arts, UdK Berlin',
    category:'Sound Installations and Performances',
    date:'05.09 20:00–24:00',
    caption:<div>
      How can the natural elements become an agent of the auditory landscape? What can more-than-human songs be? Students of Sound Studies and Sonic Arts at UdK Berlin investigate, question, analyse and shape auditory cultures and practices. These installations and performances respond to the festival's theme by engaging with various forms of site-specific sound-based making at the rainwater basin. 
      <br /><br />
      With andyvazul,  Diane Barbé and Vilte Gustyte, Ben Glas,  Mitchell Keaney, Anna Fainareti Lioka and Hyewon Suk, Menghan Wang, Daisuke Ishida    </div>,
    gallery:[
      {
        url: abs('31/1_2021_Floating_CLIMATECARE_CFlamme_134.jpg'),
        thumbnail: abs('31/1_2021_Floating_CLIMATECARE_CFlamme_134.jpg'),
      },
      {
        url: abs('31/2_2021_Floating_CLIMATECARE_CFlamme_271.jpg'),
        thumbnail: abs('31/2_2021_Floating_CLIMATECARE_CFlamme_271.jpg'),
      },
      {
        url: abs('31/3_05-09_un-domesticating-the-wild_udk_28.jpg'),
        thumbnail: abs('31/3_05-09_un-domesticating-the-wild_udk_28.jpg'),
      },
      {
        url: abs('31/4_05-09_un-domesticating-the-wild_udk_29.jpg'),
        thumbnail: abs('31/4_05-09_un-domesticating-the-wild_udk_29.jpg'),
      },
      {
        url: abs('31/5_2021_Floating_CLIMATECARE_CFlamme_257.jpg'),
        thumbnail: abs('31/5_2021_Floating_CLIMATECARE_CFlamme_257.jpg'),
      },
    ]
  }, 
  //32
  {
    title: <div> A Fantasy Journey to the Planet Zarg</div>,
    author: 'Maternal Fantasies',
    caption:<div>
        For a mini residency at the Floating University in June 2021, the Maternal Fantasies explored the element of water as a transient medium that flows through and connects all growing life; as the common denominator of all that breathes and breeds; and as a breeding and testing ground for new species and new modes of interconnectivity. Collaborating with children of all ages, this short film captures the artistic process of intergenerational intervention. 
      <br /><br />
        Maternal Fantasies is an interdisciplinary collective of international artists and cultural producers based in Berlin. 
      </div>,
    url:'https://vimeo.com/650348981',
    type:'vimeo',
  },
  
  //ROW 5
  //33
  {
    title: <div>On Exhaustion and Metabolic Rifts</div>,
    author: 'Patricia Reed',
    text:<div>
      <div>
        The recognition of the climate crisis is coincident with a recognition of the inseparability of things, activities and forces: the inseparability of figure from ground; the mixing of human, microbial and geological temporalities; and the complex causal consequences of daily events that suture disparate geographies into a common problem space. This inseparability manifests in the concept of the planetary, a coalescence that demands new frameworks for concept-making and activity that emphasise synthetic thought (and pedagogies) that one can succinctly describe as a shift from an emphasis on existence (of particularities) to coexistence (how particularities are put or situated into relation). 
      </div>
      <br/>

      <div className="text-footnote">
        Patricia Reed is an artist, theorist and designer based in Berlin. Her work concerns the social transformation of coexistence for and at planetary dimensions in practical (technical), conceptual (epistemic), and axiological terms.
      </div>
    </div>,
    category:'Talk',
    date:'05.09 19:00–20:00',
  },
  //34
  {
    title: <div>Fog Harvest</div>,
    author: 'Garance Maurer',
    caption:<div>
        Fog catchers, particularly those adapted to desert, coastal and mountainous areas, collect water from the atmosphere by condensing it along a mesh net suspended on frames that respond to varying temperatures. Lightweight and low-tech, but intelligent nonetheless, this fog-catcher will be built collectively by sharing techniques and visions for foggy futures. 
        <br /><br />
        Garance Maurer is a designer and transdisciplinary artist specialising in textiles. She combines fieldwork with the creation of materials, colors, universes, forms and stories.
      </div>,
      gallery:[
        {
          url: abs('34/1_11-09_fog-catcher_garance_12.jpg'),
          thumbnail: abs('34/1_11-09_fog-catcher_garance_12.jpg'),
        },
        {
          url: abs('34/2_09-09_fog-catcher_garance_04.jpg'),
          thumbnail: abs('34/2_09-09_fog-catcher_garance_04.jpg'),
        },
        {
          url: abs('34/3_09-09_fog-catcher_garance_09.jpg'),
          thumbnail: abs('34/3_09-09_fog-catcher_garance_09.jpg'),
        },
        {
          url: abs('34/4_2021_Floating_CLIMATECARE_CFlamme_651.jpg'),
          thumbnail: abs('34/4_2021_Floating_CLIMATECARE_CFlamme_651.jpg'),
        },
        {
          url: abs('34/5_2021_Floating_CLIMATECARE_CFlamme_655.jpg'),
          thumbnail: abs('34/5_2021_Floating_CLIMATECARE_CFlamme_655.jpg'),
        },
        {
         url: abs('34/6_2021_Floating_CLIMATECARE_CFlamme_657.jpg'),
         thumbnail: abs('34/6_2021_Floating_CLIMATECARE_CFlamme_657.jpg'),
      },
      {
         url: abs('34/7_11-09_fog-catcher_garance_21.jpg'),
         thumbnail: abs('34/7_11-09_fog-catcher_garance_21.jpg'),
      },
    ]
  },
  //35
  {
    title: <div>Nature Protection and Right Wing Politics</div>,
    author: 'Amanda Machin, Özgür Özvatan, Hanna Rullmann, Alex Nehmer',
    text:<div>
      <div>
        Commitment to the environment and other species is not automatically emancipatory; it can be instrumentalised for anti-human political purposes. In this conversation, participants will discuss how notions of supposedly pure, unadulterated nature provide an excuse for right wing groups that turn nature and animal protection against racialized people. As Hanna Rullmann and Faiza Ahmad Khan show in their documentary Habitat 2190, on the rewilding of a former refugee camp in Calais, nature conservation has become a weapon used to justify inhumane border regimes. Does the rise of a right wing green populism also point to failings of mainstream environmental politics?<br/>
        This event is part of Cohabitation Discourse: Zoopolis Berlin, which is funded by the Berlin Senate Department for Culture and Europe. 
      </div><br/>
      <div>
        <a href="https://www.cohabitation.de">www.cohabitation.de</a>
      </div><br/>
      <div className="text-footnote">
        Amanda Machin is professor of International Political Studies at the University of Witten/Herdecke in Germany. Her research focuses on the politics of citizenship, the environment and agonistic democracy.
      </div><br/>
    
      <div className="text-footnote">
        Özgür Özvatan  holds a PhD in sociology and political science. He is deputy head of two departments at the Berlin Institute for Integration and Migration Research (BIM), conducting research on belonging, racism and the Far Right.
      </div><br/>

      <div className="text-footnote">
        Hanna Rullmann is a researcher and designer, developing a practice around questions of conservation, environmental policy and legal/political production of natures.
      </div><br/>
    
      <div className="text-footnote">
        Alex Nehmer is an editor at ARCH+ magazine for architecture and urbanism and a co-curator of the exhibition and event series Cohabitation.
      </div><br/>
    </div>,
    category:'Conversation',
    date:'09.09 20:00-21:30',
  },
  //36
  {
    title: <div>Order from Noise</div>,
    author: 'Lisa Ertl',
    caption:<div>
      How do bees organise the division of labour? What modes of decision-making are applied? What is the role of the queen bee? And what do beekeeping and systemic consulting have in common? This workshop dives into the complex processes of a beehive to ask what humans can learn from it.
      <br /><br />
      Lisa Ertl is a consultant for systemic organisational development and a beekeeper, always passionate about the nature, evolution and cooperation of social systems. She has studied Cultural and European Studies and is co-founder of nyoo organisational development.     </div>,
    gallery:[
      {
        url: abs('36/1_2021_Floating_CLIMATECARE_CFlamme_874.jpg'),
        thumbnail: abs('36/1_2021_Floating_CLIMATECARE_CFlamme_874.jpg'),
      },
      {
        url: abs('36/3_12-09_250Hz_Lisa_siobhan_02.jpg'),
        thumbnail: abs('36/3_12-09_250Hz_Lisa_siobhan_02.jpg'),
      },
      {
        url: abs('36/4_2021_Floating_CLIMATECARE_CFlamme_494.jpg'),
        thumbnail: abs('36/4_2021_Floating_CLIMATECARE_CFlamme_494.jpg'),
      },
      {
        url: abs('36/5_2021_Floating_CLIMATECARE_CFlamme_865.jpg'),
        thumbnail: abs('36/5_2021_Floating_CLIMATECARE_CFlamme_865.jpg'),
      },
      {
        url: abs('36/7_2021_Floating_CLIMATECARE_CFlamme_869.jpg'),
        thumbnail: abs('36/7_2021_Floating_CLIMATECARE_CFlamme_869.jpg'),
      },
     ]
  },
  
  //37
  {
    title:<div>To sleep comfortably, in common (which is politics)</div>,
    author:'Michelle Teran, Marc Herbst and invited guests',
    category:'Sleepover',
    //caption:'this goes bellow windowTitle when it opens',
    date:' 27.06–28.06 17:00–14:00',
    text:<div>
      <div>
        How can we create and maintain a generative space in which not everybody agrees but comfort is still nourished? Can we recognise difference and disagreement as abundance? Where does disagreement not mean failure? What is the feeling of the individual in the diverse collective?
      </div>
      <br/>

      <div>
        The focus of the event will be experiencing and reflecting upon conviviality through speaking and listening practices, writing, sleeping and late-night dreaming. Over the course of 24 hours, including an overnight stay at the Floating University, we intend to write and rewrite, based on our collective experience of being together.  The politics of the event are based around the understanding that to sleep alongside each other is a conscious project of collective organisation. The inclusion of dreaming in this event is key, as dreams are understood here to be an accelerator/magnifier of time and openness to the world. What vulnerabilities and entanglements are further manifested when dreams are utilised as a tool through which collective articulation is consciously composed? 
      </div>
      <br/>
    
      <div>
        For us, “rewilding” refers to a horizontal return to the commons, to the entangled social relations that exist between us. We think about rewilding only to the extent that it helps us conceptualise how productive social differences have been oppressed by patriarchal and singular forms of order. As a way to envisage how wild the common social sphere already is, we appreciate the term. 
      </div>
      <br/>

      <div>
        Artefacts from the sleepover will be made public at a Climate Care event on September 7th 
      </div>
      <br/>

      <div className="text-footnote">
        Michelle Teran (born in Canada) is an educator, artist, and researcher. She is practice-oriented Research Professor Social Practices at Willem de Kooning Academy (WdKA). Her research areas encompass socially engaged and site-specific art, transmedia storytelling, counter-cartographies, social movements, feminist practices, and critical pedagogy. 
        <br/><br/>
        Marc Herbst is an editor, artist, publisher and writer with a long-term engagement in social movements. He is a co-editor of the Journal of Aesthetics & Protest that he co-founded in 2001 in Los Angeles, and is currently co-editing a book about the eco-social entanglements of an insurrectionary community near Nantes authored by the Laboratory of Insurrectionary Imagination (Pluto Press Vagabonds).
      </div>
    </div>,
  },
  //38
  {
    title: <div>Calling into the Now<br/> (Part 3)</div>,
    windowTitle: 'Calling into the Now (Part 3)',
    author: 'Teresa Dillon',
    text:<div>
      <div>
        Calling into the Now looks to the role that ritual plays in supporting the ontological shifts necessary to move from human-centered to more earth-bound perspectives, whereby the Earth’s inviolability is placed at the heart of what we do, think and make. A multi-layered environment, the Floating site is one in which significant human intervention has unfolded: from the control of rainwater to experiments in multispecies living. The third part of Calling into the Now takes the form of a public discussion that situates the work in relation to post-human legalities.
      </div>
      <br/>

      <div className="text-footnote">
        Teresa Dillon is an artist and researcher. Her work is primarily situated within urban spaces and explores the performative, lived entanglements of techno-civic systems.. She is a Humboldt Fellow and has participated in numerous exhibitions, art residencies and currently holds the post of Professor of City Futures, at the School of Art and Design, UWE Bristol. 
      </div>
    </div>,
    category:'Research conversation',
    date:'08.09 20:00–21:00',
  },  
  //39
  {
    title: <div>Being the Basin</div>,
    author: 'Jakob Walter',
    caption:<div>
        The government of the city of Berlin plans to demolish the rainwater basin of the former Tempelhof airport – where the Floating University sits today – to create a natural-looking pond. The bottom of the basin consists of two layers of concrete and an extra bitumen layer. After the concrete is removed, a protected biotope is planned to be established. Despite its rotten concrete, the basin is already a habitat for many species. What might they think about the government’s plans? How might the fate of the rainwater basin be different if the basin itself decided its future?
          <br />
        Jakob Walter speaks with Joulia Strauss, Andreas Philippopoulos-Mihalopoulos, Mauricio Corbalan, Bertram Lomfeld to consider how the agency of the basin might be considered in the planning process. 
          <br /><br />
        Jakob Walter trained as an architect in Leipzig, Weimar, and Zürich.
      </div>,
    albums:[
      {
        cover:abs('39/Screenshot 2022-05-09 at 18.00.04.png'),
        thumbnail:abs('39/Screenshot 2022-05-09 at 18.00.04.png'),
        tracks:[
          {id: '01',title:'Being the Basin',url:abs('39/BeingTheBasin_Teaser.mp3')}
        ]
      }
    ],
  },
  //40
  {
    title: <div>Faunal soundscapes 2</div>,
    author: <div>Infusio Gallo</div>,
    caption:<div>
       Infuso Giallo compiles sounds and recordings that fit in the context of the festival's rewilding theme. Ranging from songs and records made by, or for, plants, to releases that mimic faunal or environmental phenomena. 
    <br /><br />
       Infuso Giallo is a Berlin-based producer and DJ. His live sets are a fluid, ever-changing, ever-new collection and reinterpretation of his tracks, alternating between ambient and club contexts. 
    </div>,
    url:'https://www.youtube.com/embed/Sc0EcLkIK1s',
    type:'youtube',
  },
]


export default { ...data, grid };
