import React ,{useState} from 'react';
import ProgressiveImage from 'react-progressive-image';
import Video from './Video.js'


const Audio = ({albums}) => {

  const [players, setPlayers] = useState({})
  const [isPlaying, setIsPlaying] = useState([false, false])
  const [currentTrackIndex,setCurrentTrackIndex] = useState([0,0])

  const handleSetPlayer = (_player, index) => {
    _player.on('timeupdate', function(e) {
      const now = _player.currentTime()
      const dur = _player.duration()
      if (now/dur > 0.98) {
        onTrackEnd(index)
      }
    });
    setPlayers(p => ({
      ...p,
      [index]:_player
    }));
  }

  // const handlePlayerEnded = (_player, index) => {
    // console.log('player ended event',index)
  // }

  const onAlbumClick = (album, id, e) => {
    Object.values(players).forEach((p,i) => {
      if (id === i) {
        if (p.paused()) {p.play() }
        else {p.pause()}
      }
      else if (!p.paused()) {
        p.pause() 
      }
    });

    e.stopPropagation()
    setIsPlaying(isPlaying.map((x,i)=>id===i?!x:false))
  }

  const handleTrackClick = (album, albumIndex, track, trackIndex) => {
    setCurrentTrackIndex(currentTrackIndex.map((x,i) => i === albumIndex ? trackIndex : x));
    setIsPlaying(isPlaying.map((x,i) => (i === albumIndex ? true : false)))
    Object.values(players).forEach((p,i) => {
      // if (id === i && p.paused()) p.play();
      if (albumIndex !== i && !p.paused()) p.pause() 
    });

    // players[albumIndex].src({
      // src: albums[albumIndex].tracks[trackIndex].url,
      // type: 'audio/mp3',
    // });
    // players[albumIndex].play()
  }

  const onTrackEnd = (albumIndex) => {
    console.log('trackend')
    setCurrentTrackIndex(
      currentTrackIndex.map((x,i)=>(i===albumIndex?((x+1)%albums[albumIndex].tracks.length):x))
    );
    // setCurrentTrackIndex
  }

  return (
    <div className="albums-container">
      {albums.map((a,i) => <Album 
          album={a} key={a.title} 
          isPlaying={isPlaying[i]}
          handleSetPlayer={(p) => handleSetPlayer(p,i)}
          onAlbumClick={e => onAlbumClick(a,i,e)} 
          handleTrackClick={(t,j) => handleTrackClick(a,i,t,j)}
          currentTrackIndex={currentTrackIndex[i]}
        />
      )}
    </div>
  );
};

      // <div data-vjs-player>
        // <video ref={ node => this.videoNode = node } className="video-js"></video>
      // </div>

const Album = ({
  album, 
  onAlbumClick, 
  isPlaying, 
  handleSetPlayer, 
  handlePlayerEnded,
  handleTrackClick,
  currentTrackIndex,
}) => {

  const { 
    title, 
    author,
    tracks, 
    cover,
    thumbnail,
    singleTrack,
  } = album;

  const currentTrack = !!singleTrack ? tracks[0] : tracks[currentTrackIndex]

  const videoJsOptions = {
    controls: false,loop: true,preload: true,fill:true,loadingSpinner:false,fluid:true,
    autoplay:isPlaying,
    handleSetPlayer,
    handlePlayerEnded,
    poster: cover,
    sources: [
      {
        src: currentTrack.url,
        type: 'audio/mp3',
      },
    ]
    // sources: album.tracks.map((t,i) => {
      // return {
        // src: t.url,
        // type: 'audio/mp3',
      // }
    // })
  }


  return (
    <div className={`album-container ${singleTrack ? 'single-track' : ''}`}>
      <div className="audio-player-container" key={currentTrack.url}>
        <Video {...videoJsOptions} />
      </div>

      <div className="album-top">
        {!!author && <div className="album-author">{author}</div>}
        {!!title && <div className="album-title">{title}</div>}
      </div>

      <div className="album-bottom">
        <div onClick={onAlbumClick} className="album-cover-container">
          <ProgressiveImage src={cover} placeholder={thumbnail}>
            {(src,loading) => <img 
              src={src} alt={title} 
              className={"album-cover "+(loading?'cover-loading':'cover-loaded')}
            />}
          </ProgressiveImage>

          <div className={"play-btn "+(isPlaying?'playing':'paused')}/>
        </div>

        {!singleTrack && 
          <div className="album-tracks">
            {tracks.map((t, j) => {
              const isActive = false;
              return <TrackLabel 
                isPlaying={j === currentTrackIndex}
                track={t} 
                active={isActive} 
                handleClick={() => handleTrackClick(t,j)}
                key={t.id}
              />
            })}
          </div>
        }
      </div>
    </div>
  )
}

const TrackLabel = ({track,isPlaying,handleClick}) => {
  return <div className={"album-track " + (isPlaying ? 'active' : '')} onClick={handleClick}>
    <div className="album-track-text">
      {track.id}{' '}{track.title}
    </div>
  </div>
}

export default Audio;
