import React from 'react';

// We have to input it manually, __filename won't work with webpack
const year = '2019'

/** Converts a partial relative URL to an absolute one */
const abs = (url) => {
  // @see https://create-react-app.dev/docs/using-the-public-folder/#adding-assets-outside-of-the-module-system
  return `${process.env.PUBLIC_URL}/assets/${year}/${url}`
}

/*
* Because of the font, some letters are 
* inverted and moved up to keep desired aspect. 
'moved up' example: <div>Glacier Soundsca<em>p</em>e</div>,
'inverted' example: <div>On <span className='flip-letter'>E</span>arth</div>,

grid cells are of type: {video,pdf,text,album (audio)}
assets are uploaded to amazon s3. 
*/

const data = {
  year,
  title: 'A CURRICULUM FOR URBAN PRACTICE',
  date: '1 – 10 AUGUST, 2019',
  // Link opened when clicking the year's title (can be null for no link)
  headerAsset: abs('pdfs/ClimateCare_Program_Doubble_Web_31-07.pdf'),
  // Shown as soon as the page starts
  introVideo: abs('introcc.mp4'),
  // Shown while the video loads
  introFrame: abs('cover.jpg'),
  // This asset is the grid's tile background+border
  backgroundAsset: abs('tile.png'),
}

/* Content for grid and windows */
const grid = [
  {
    title: <div>{'"Should Trees'}<br/> Have Standing?<br/> Law, Morality and the<br/>Environment</div>,
    windowTitle: <div>"Should Trees Have Standing? Law, Morality and the Environment"</div>,
    // cellTitle: <div>{'"Should Trees'}<br/> Have Standing?<br/> Law, Morality and the<br/>{'Environment"'}</div>,
    // title: 'Should Trees Have Standing? Law Morality and the Environment',
    author: 'By Christopher Stone',
    pdf: {
      url:abs('pdfs/1_shouldtreeshavestanding.pdf'),
    },
    category:'Reading',
    date:'01.08.2019',
    alt: true,
  },
  {
    title:<div>Al<em>g</em>enlabor</div>,
    author:'Selina Syed',
    category:'Workshop',
    date: '02.08 - 04.08.2019 + 09.08 - 10.08.2019',
    caption:'Geoecologist Selina Syed teaches families about the dominating algae on site - the water net or hydrodictyon - and how this two and half billion old species helped make planet earth inhabitable for humans too. ',
    url: 'https://vimeo.com/371698189',
    type: 'vimeo',
  },
  //003
  {
    title: <div>Glacier Soundsca<em>p</em>e</div>,
    caption:'Sound artist Olivier Gobelet explores imaginary Antarctic ecosystems. Sound installation and performance.',
    author:'Olivier Gobelet',
    category:'Sound Installation, Performance',
    date:'01.08.2019',
    albums:[
      {
        title:'Antartic studies 2012-2015',
        author:'Lewis Snowfield',
        cover:abs('antartic_studies_vol_1.jpg'),
        thumbnail:abs('antartic_studies_vol_1_thumbnail.jpg'),
        tracks:[
          {id: '01',title:'Waipuke Beach',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+01+Waipuke+Beach.mp3')},
          {id: '02',title:'Horteflaket Névé',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+02+Horteflaket+Ne%CC%81ve%CC%81.mp3')},
          {id: '03',title:'Mizuho Plateau',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+03+Mizuho+Plateau.mp3')},
          {id: '04',title:'MacQuarrie Edge',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+04+MacQuarrie+Edge.mp3')},
          {id: '05',title:'Moonlight Point',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+05+Moonlight+Point.mp3')},
          {id: '06',title:<span>N <span style={{marginLeft:-3}}>o</span>xious Bluff</span>,url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+06+Noxious+Bluff.mp3')},
          {id: '07',title:'Deception Island',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+07+Deception+Island.mp3')},
          {id: '08',title:'Crescent Scarp',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+2012-2015/Lewis+Snowfield+-+Antarctic+studies+2012-2015+-+08+Crescent+Scarp.mp3')},
        ]
      },
      {
        title:'Antartic studies Vol.2 (2016-2018)',
        author:'Lewis Snowfield',
        cover:abs('antartic_studies_vol_2.jpg'),
        thumbnail:abs('antartic_studies_vol_2_thumbnail.jpg'),
        tracks:[
          {id: '01',title:'Zograf Peak',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+01+Zograf+Peak.mp3')},
          {id: '02',title:'Rustad Knoll',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+02+Rustad+Knoll.mp3')},
          {id: '03',title:'Pjrogov Glacier',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+03+Pjrogov+Glacier.mp3')},
          {id: '04',title:'Colbeck Archipelago',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+04+Colbeck+Archipelago.mp3')},
          {id: '05',title:'Akademik Feredov Canyon',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+04+Colbeck+Archipelago.mp3')},
          {id: '06',title:'Khufu Corrie',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+06+Khufu+Corrie.mp3')},
          {id: '07',title:'Beacon Supergroup',url:abs('antartic_studies/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)/Lewis+Snowfield+-+Antarctic+studies+Vol.2+(2016-2018)+-+07+Beacon+Supergroup.mp3')},
        ]
      },
    ]
  },
  //004
  {
    title:<div>We Can<br/>be Rebels</div>,
    windowTitle:<div>We Can be Rebels</div>,
    author:'Extinction Rebellion',
    category:'Workshops, Reading Group',
    date:'04.08.2019',
    text:<div>
      <div>
        {'Extinction Rebellion is an international movement that uses non-violent civil disobedience in an attempt to halt the sixth mass extinction and minimise the risk of social and ecological collapse. We promote civil disobedience and rebellion because we think it is necessary – we ask people to find their courage and to collectively do what is necessary to bring about change. We organise in small groups and these groups are connected in a complex web that is constantly evolving as we grow and learn. We are working to build a movement that is participatory, decentralised, and inclusive. All are welcome who are willing to adhere to our principles and values.'}
      </div>
      <br/>

      <div>
        {'14:00-17:00 Workshop'}
      </div>
      <div>
        {'What does it take to become a Rebel? Do I care? Dare I? In this workshop participants will discover and experience the various beliefs, demands and ways of action developed and practiced by Extinction Rebellion. Bring yourself, your passion, a t-shirt (that you would like to make rebellious!), and by the end of the workshop you too might become an XR Rebel.'}
      </div>
      <br/>

      <div>
        {'17:00–18:00 Reading Circle'}
      </div>
      <div>
        {'Join us in a reflective circle as we collectively share and listen to readings from a range of Extinction Rebellion texts. These include writings that respond to our central principles of Tell the Truth and Act Now, as well as extracts from personally written Letters to the Earth. '}
      </div>
      <br/>

      <div className="text-footnote">
        <b>Extinction Rebellion</b>{' is a rapidly growing international climate justice movement. Through non-violent civil disobedience we pressure our governments to carry out the radical changes which are needed in the face of this global climate emergency. For more information please visit: www.extinctionrebellion.de or www.rebellion.earth'}
      </div>
  </div>,
  },
  //005
  {
    title:<div>"Hydrofeminism:<br/> Or, On Becoming<br/>a Body of Water"</div>,
    windowTitle:<div>"Hydrofeminism: Or, On Becoming a Body of Water"</div>,
    author:'By Astrida Neimanis',
    category:'Reading',
    date:'05.08.2019',
    alt: true,
    pdf: {
      url:abs('pdfs/5_hydrofeminism.pdf'),
    },
  },
  //006
  {
    title: <div>What is Care at Planetar<em>y</em> Dimensions?</div>,
    author:'Patricia Reed',
    category:'Lecture',
    date:'06.08.2019',
    text:<div>
      <div>
        {'Care can no longer be exclusively figured at an intimate scale of interpersonal relations when it concerns the life and livability for over 7 billion humans – not to mention the necessary expansion of care to account for non-humans and the biospheric conditions that support and embed life. In this way, care is fully intertwined with epistemological projects (understood in the broadest sense possible), since we cannot adequately care without producing ‘better accounts of reality’ that guide us on how to care. '}
      </div>
      <br/>

      <div>
        {'Taking up the notion of ‘care’ from the perspective of nested scales of co-existence and drawing from feminist epistemologies, this talk asserts that learning to care in this expanded way necessitates new frames of spatial, ethical, and representational reference that transform the concept of what ‘location’ or a ‘site’ even is as a way to situate ‘care’ at the scale of the unfamiliar and impersonal. The question of care today ultimately concerns learning how to practice it from smallworlds nested within a big-world continuum, where local-global relationships can, if not must, become more readily accessible to thought. '}
      </div>
      <br/>

      <div className="text-footnote">
        <b>Patricia Reed</b>{' is an artist, writer and designer based in Berlin. Her recent writings have been published in e-flux Journal, Making & Breaking, Angelaki 24, Para-Platforms (Sternberg); Post-Meme (Punctum Books, forthcoming); e-flux Architecture; Xeno-Architecture (Sternberg Press); Cold War Cold World (Urbanomic); and Distributed (Open Editions). Reed is also part of the Laboria Cuboniks, a techno-material feminist working group whose Xenofeminist Manifesto (2015) was republished by Verso Books in 2018 with the French book translation forthcoming with entremonde in 2019.'}
      </div>
  </div>,
  },
  //007
  {
    windowTitle: <div>"The Falling Sky - Words of a Yanomami Shaman"</div>,
    title: <div>"The Falling Sky -<br/> Words of a<br/>Yanomami Shaman"</div>,
    author:<div>By Davi Kopenawa<br/>and Bruce Albert</div>,
    category:'Reading',
    date:'08.08.2019',
    alt: true,
    pdf: {
      url:abs('pdfs/8_the+falling+sky.pdf'),
    },
  },
  //010
  {
    title: 'Jet Streams',
    author:'Pinewax',
    category:'DJ Session',
    date:'09.08.2019',
    text:<div>
      <div>
        {'The Pinewax squad deejays - umglottis, mowtif and mare - will serve a fusion of diverse beat structures, mixing synthetic and organic sounds from every possible climate; from Chicago to Rio, from Montréal to Lisbon.'}
      </div>
      <br/>

      <div className="text-footnote">
        <b> Pinewax</b>{' is a collectively run record label; a group of friends who love music and press records. Rooted in the Black Forest and in Quebec, the Pinewax Squad mixes diverse beat structures with organic and synthetic sounds from all over. Whether they are a musician, film maker, deejay, turntablist, producer or graphic artist, its members share a dedication to bringing a diversity of influences to the mix, always with a keen sense for the particular, the different, the moving. Fully independent, the group utilizes this freedom to feature artists that would otherwise not have the chance to be on vinyl, on stage, on film or in the digital world. Each project is thus a cooperative process bringing together different minds and inspirations. The wax stands for the records; the pine for our roots.'}
      </div>
  </div>,
  },

  //011
  {
    title: <div>Floatin<em>g</em><br/> For Future</div>,
    author:'i-collective',
    category:'Workshop',
    date:'02.08.2019',
    text:<div>
      <div>
        In 2018 at age 15 a Swedish schoolgirl named Greta Thunberg began to strike from school. On every Friday she would sit in front of the Swedish parliament with a homemade sign – Skolstrmatet (school strike for climate). Through the repeated renunciation of her civic duty on each Friday, she set in motion a campaign of civil disobedience that would gain global traction.
      </div>
      <br/>

      <div>
        The Institut für alltägliche Umwälzungen is a participatory project from iCollective e.V. that reflects on the daily habits of our everyday life. Arguably, if people observe continuous micro-decisions in the global context, clear reasons of the environmental crisis in which humanity is immersed are easily observed. In theory the solution appears easier than in practice: would it be enough if, every day, every person living in the industrial countries were to break another habit, making different choices? What is it that stops us from these decisions?
      </div>
      <br/>

      <div>
        Young people between the ages of 9-16 are invited to speculate on the possibility of provoking daily, incremental, mini revolutions within their own area of influence. The workshop will employ performance, drawing and writing for participants to formulate their own small scale every-day revolutions for the environment!
      </div>
      <br/>

      <div className="text-footnote">
        <b>Betiana Bellofatto</b> and <b>Valeria Schwarz</b> of iCollective, an organic collaborative platform of artists, curators and researchers working at the intersection of arts, urban interventions and socially engaged projects. A horizontal network, we explore the interaction of individual experiences and collective ideas, recombining notions of private and public. Using different approaches we develop strategies that activate citizens to re-imagine the cityscape, and we propose platforms to foster the configuration of symbolic places, and the creation of temporary communities that gather for a variety of collective creative purposes. The city is our playground: come play with us!
      </div>
  </div>
  },
  //012
    {
      windowTitle: <div>Dee<em>p</em> Care Plant Drawin<em>g</em></div>,
      title: <div>Dee<em>p</em> Care<br/>Plant<br/>Drawin<em>g</em></div>,
      author:'The Perennial Institute',
      caption:'The Perennial Institute leads participants in deep observation and drawing of the unknown plants at the Floating University',
      category:'Drawing Workshop',
      date:'03.08 - 04.08.2019',
      footnote:'all pictures courtesy of Perennial Institute - by Martina della Valle.',
      gallery:[
        {
          url: abs('deep_care/2_PERENNIALinst2019-byMartinadellaValle-037.jpg'),
          thumbnail: abs('deep_care/thumbnails/2_PERENNIALinst2019-byMartinadellaValle-037.jpg'),
        },
        {
          url: abs('deep_care/1_PERENNIALinst2019-byMartinadellaValle-024.jpg'),
          thumbnail: abs('deep_care/thumbnails/1_PERENNIALinst2019-byMartinadellaValle-024.jpg'),
        },
        {
          url: abs('deep_care/3_PERENNIALinst2019-byMartinadellaValle-052.jpg'),
          thumbnail: abs('deep_care/thumbnails/3_PERENNIALinst2019-byMartinadellaValle-052.jpg'),
        },
        {
          url: abs('deep_care/4_PERENNIALinst2019-byMartinadellaValle-054.jpg'),
          thumbnail: abs('deep_care/thumbnails/4_PERENNIALinst2019-byMartinadellaValle-054.jpg'),
        },
        {
          url: abs('deep_care/5_PERENNIALinst2019-byMartinadellaValle-075.jpg'),
          thumbnail: abs('deep_care/thumbnails/5_PERENNIALinst2019-byMartinadellaValle-075.jpg'),
        },
        {
          url: abs('deep_care/6_PERENNIALinst2019-byMartinadellaValle-080.jpg'),
          thumbnail: abs('deep_care/thumbnails/6_PERENNIALinst2019-byMartinadellaValle-080.jpg'),
        },
        {
          url: abs('deep_care/7_PERENNIALinst2019-byMartinadellaValle-084.jpg'),
          thumbnail: abs('deep_care/thumbnails/7_PERENNIALinst2019-byMartinadellaValle-084.jpg'),
        },
      ]
    },
    //013
    {
      title: <div>On <span className='flip-letter'>E</span>arth</div>,
      author:<div>Gilly Karjevsky<br/>Rosario Talevi</div>,
      category:'Introduction',
      date:'',
      text: <div>
        <p>How on earth are we going to do this? As day-to-day changes in weather patterns are experienced in ways that can no longer be ignored, public awareness has reached a tipping point and civic action is picking up in cities around the world. Yet these varying protest movements, organisations, campaigns and events are confronting the crisis by using a rhetoric of urgency, fatality and catastrophe. Thus it’s no wonder that the most common response to the situation is either denial or depression – an inability to act. </p>
        <p>As much as the need for urgent action on our changing environment is vital and valid, this rhetoric of crisis is arguably rooted in an epistemological error, or even a crisis of representation. The recognition of changing climate systems in mainstream discourse is not some grand discovery, but more a process of catching up with marginalised environmental movements which have long been advocating for change. Perhaps it is finally time for all of us to acknowledge that climate change is rooted in the perpetuation of a system of extraction, depletion and unequal distribution of the earth’s limited resources. And perhaps what is most crucial to be aware of is the entanglement of this ecological crisis with struggles for racial, gender, social and economic equality at a global scale. </p>
        <p>But are we really equipped to deal with this? How can we change the way in which complex social, economical and political systems shape our lives? </p>
        <p>Centring María Puig de la Bellacasa’s notion of care in our ethical and political discourse might help us do this.(1) The notion of ‘care’ reframes the human as a ‘caretaker’, a custodial figure in the ongoing recuperation of the planet and its people. To care is to recognise the fragility of the bonds between both humans and non-humans. Care asks us to cultivate a set of skills and sensibilities to maintain and sustain all kinds of life. </p>
        <p>On the site of the Floating University, a rainwater retention basin that serves Berlin’s Tempelhofer Feld, a variety of animals, plants and algae have taken root and given birth to a unique landscape: a man-made environment reclaimed by nature where polluted water coexists with the university’s relatively new presence, a natureculture or a third landscape.  It is the complexity of the basin site and the various forms of knowledge growing on it that have led us to consider climate and care for this year's Floating University programme. </p>
        <p>In Climate Care, artists, thinkers, scientists, activists and designers are invited to propose educational modules that encourage embodied and tacit knowledge to emerge from experiences on site. From compost making, experimentation with bio-materials and the construction of urban hives, to weather writing and “tuning-in” methods, looking at care on a planetary scale and exploring the concept of environmental personhood within the basin itself,  the programme is conceived of as a curriculum for urban practice.</p>
        <p>With only a distant, defunct airport traffic control tower reminding us of the surrounding city, being on site at the Floating University is an experience of care with the environment; to care and to be cared for by greenery, rain, still water, algae, birds, weird smells and open sky. In this context, the Climate Care programme will develop tools to work beyond crisis with networks and systems already here, on earth.</p>
        <p>--</p>
        <p>Bellacasa describes care as ‘an ethical and political obligation for thinking in the more than human worlds of technoscience and naturecultures.’ María Puig de la Bellacasa: Matters of Care Speculative Ethics in More Than Human Worlds. UMP, 2017.</p>
        <p>DE</p>
        <p>Wie um Himmels Willen sollen wir das anstellen? 2019 ist das die Frage auf jedermanns</p>
        <p>Lippen hat. Tag-täglich erfahrbare Wetterschwankungen machen es unmöglich die Klimakrise weiter zu ignorieren. Das öffentliche Bewusstsein hat einen Wendepunkt erreicht - Proteste und Aktionen der Zivilbevölkerung nehmen in Städten rund um die Welt zu. Die verschiedenen Protestbewegungen, Organisationen, Kampagnen und Veranstaltungen konfrontieren die Krise mit einer Rhetorik der Dringlichkeit, Fatalität und Katastrophe. Da ist es kein Wunder, dass die gewöhnliche Reaktion auf die Klimakrise entweder Klimaleugnung oder Klimadepression ist – ein Ausdruck der Handlungsunfähigkeit.</p>
        <p>So sehr die Notwendigkeit dringender Maßnahmen in unserem sich verändernden Umfeld von entscheidender Bedeutung und Gültigkeit ist, so sehr ist diese Krisenrhetorik wohl auf einen erkenntnistheoretischen Fehler oder gar eine Krise der Repräsentation zurückzuführen. Die Anerkennung der sich wandelnden Klimasysteme im Mainstream-Diskurs ist keine große Entdeckung, sondern vielmehr ein Prozess des Aufschließens zu marginalisierten Umweltbewegungen, die sich seit langem für Veränderungen einsetzen. Vielleicht ist es endlich an der Zeit, dass wir alle anerkennen, dass der Klimawandel auf der Aufrechterhaltung eines Systems der Gewinnung, Erschöpfung und ungleichen Verteilung der limitierten Ressourcen der Erde beruht. Und vielleicht das Wichtigste, dessen man sich bewusst sein sollte, ist die Verflechtung dieser ökologischen Krise mit dem Kampf gegen Rassismus und für Geschlechtergleichheit sowie soziale und wirtschaftliche Gleichberechtigung aller im globalen Maßstab.</p>
        <p>Aber sind wir wirklich gerüstet, um damit umzugehen? Wie können wir die Art und Weise verändern, auf die komplexe soziale, wirtschaftliche und politische Systeme unser Leben gestalten? </p>
        <p>Die Zentrierung des Care-Begriffs von María Puig de la Bellacasa in unserem ethischen und politischen Diskurs könnte uns dabei helfen.(1) Der Begriff "Care" konzeptualisiert den Menschen als eine Art "Hausmeister", eine pflegende Figur in der fortlaufenden Erholung des Planeten und seiner Menschen. Zu pflegen bedeutet, die Zerbrechlichkeit der Bindungen zwischen Menschen und Nicht-Menschen anzuerkennen. Care bittet uns, eine Reihe von Fähigkeiten und Sensibilitäten zu entwickeln, um alle Arten von Leben zu beschützen, zu erhalten und zu unterstützen. </p>
        <p>Auf dem Gelände der Floating University, einem Regenwasserrückhaltebecken für das Tempelhofer Feld in Berlin, haben sich verschiedene Tiere, Pflanzen und Algen angesiedelt und eine einzigartige Landschaft geschaffen: ein menschengemachter Ort, zurückerobert von der Natur, in dem verunreinigtes Wasser mit der relativ neuen Präsenz der Floating University koexistiert - eine Naturkultur oder eine dritten Landschaft.  Die Komplexität des Ortes und die diversen Formen des Wissens, die hier wachsen, haben uns dazu veranlasst, die Themen Klima und Care in den Mittelpunkt des diesjährigen Programms in der Floating University zu stellen.</p>
        <p>Im Climate Care Programm sind Künstler, Denker, Wissenschaftler, Aktivisten und Designer eingeladen, Bildungsmodule vorzuschlagen, die versuchen verkörpertes und implizites Wissen aus Erfahrungen vor Ort hervorzubringen. Mit Formaten von Kompostierung über das Experimentieren mit biologischen Materialien und den Bau von urbanen Insektenstöcken bis hin zum Schreiben über Wetter und "Reinschalt"-Methoden, die Care auf globaler Ebene betrachten und das Konzept von Natur als juristischer Person im Becken selbst erkunden, ist das Programm als Lehrplan für urbane Praxis konzipiert. </p>
        <p>Mit bloß einem entfernten, stillgelegten Flughafentower, als einzige Erinnerung an die umliegende Stadt, ist es ein Erlebnis von Umwelt und Care in der Floating University vor Ort zu sein; man kümmert sich um Pflanzen, Regen, stilles Wasser, Algen, Vögel, seltsame Gerüche und offenen Himmel und wird von ihnen gepflegt. In diesem Zusammenhang wird das Climate Care Programm Werkzeuge entwickeln, um über die Krise hinaus mit Netzwerken und Systemen zu arbeiten, die bereits hier auf der Erde vorhanden sind.</p>
        <p>--</p>
        <p>Bellacasa beschreibt Care als "eine ethische und politische Verpflichtung zum Denken in der mehr als menschlichen Welt von Technowissenschaft und Naturkulturen". Übersetzt aus: María Puig de la Bellacasa. Matters of Care Speculative Ethics in More Than Human Worlds. UMP, 2017.</p>
      </div>,
    },
  {
    windowTitle: <div>"This is not a drill - The Extinction Rebellion handbook" and <div> "Letters to the Earth"</div></div>,
    title: <div>"This is not a drill<br/>- The Extinction Rebe-<br/>llion handbook" and<br/> "Letters to the Earth"</div>,
    // cellTitle: <div>"This is not a drill<br/>- The Extinction Rebe-<br/>llion handbook" and<br/> "Letters to the Earth"</div>,
    // title: <div>This is not a drill - The <div style={{display:'inline-block'}}><span className='flip-letter'>E</span>xtinction</div> Rebellion handbook and Letters to the <span className='flip-letter'>E</span>arth</div>,
    author:<div>By Extinction Rebellion</div>,
    category:'Reading',
    date:'04.08.2019',
    alt: true,
    pdf: {
      url:abs('pdfs/4_XR+letters+to+earth.pdf')
    },
  },
  {
    title:<div>Tunin<em>g</em>-in</div>,
    author:'Sabine Vogel',
    caption:'Flutist and composer Sabine Vogel challenges thresholds of perception through exercises of listening and awareness. Workshop and participatory performance.',
    category:'Performance',
    url: 'https://vimeo.com/373924694',
    type: 'vimeo',
    date:'09.08.2019',
  },


  {
    title: <div>An Image of<br/>a Bird is<br/> an Ima<em>g</em>e of the World</div>,
    windowTitle: <div>An Image of a Bird is an Ima<em>g</em>e of the World</div>,
    author:<div>Christian Schwägerl<br/>Hemal Naik</div>,
    url:'https://vimeo.com/219635289',
    type:'vimeo',
    wide:true,
    category:'Lecture, Conversation',
    caption:'ICARUS is a global collaboration among scientists who are committed to study the movement patterns of small migratory animals. Presentation and walk by Christian Schwägerl and Hemal Naik.',
    date:'06.08.2019',
  },
  {
    title: <div>Climate<br/> De<em>p</em>ression<br/> Thera<em>p</em>y</div>,
    author:'Ela Spalding',
    category:'Group Session',
    date:'08.08.2019',
    text:<div>
      <div>
        As we engage in climate care we are faced daily with the facts that climate crisis is real and that our future is endangered. We often feel worried, overwhelmed, discouraged, anxious and depressed by the seriousness of the situation and the immensity of the task ahead. To help relieve the symptoms of climate trauma we will share a session focus on existing practices and strategies and finding new ways of coping, and engage in a set of practical exercises designed to enable us to continue being productive and stay positive in the wake of the environmental and psychological crisis we are experiencing daily. 
      </div>
      <br/>

      <div className="text-footnote">
        <b>Climate Care festival team</b>
      </div>
    </div>,
  },
  {
    windowTitle: <div>Climate Care Parasitic Reading Room<br/><div>A series of re-readings</div></div>,
    title: <div>Climate Care<br/>Parasitic Reading Room<br/>A series of re-readings</div>,
    pdf: {
      url:abs('pdfs/10_parasitic+reading+room.pdf'),
    },
    author:'By Rosario Talevi',
    category:'Reading',
    date:'10.08.2019',
    alt: true,
  },

  {
    title:'Feast Procedure',
    author:'Nicole Sartirani',
    date:'01.08.2019',
    category:'Aperitivo and Insect Tasting',
    text:<div>
      <div>
  The population of humans is increasing while resources are decreasing. Already, meat production is reaching the limit of what the planet can maintain. If every meal is the result of the right combination of carbohydrates, proteins, minerals and vitamins, how might we adjust the sources of food production to accommodate a growing human population? A potential solution to reduce livestock production could be achieved by eating insects, ensuring a diet rich in proteins, vitamins and minerals.
      </div>
      <br/>

      <div>
      This feast procedure will reveal how the art of cooking can be enriched by the use of new ingredients. Edible insects are easy to cook; the taste is delicate and the required quantity is small. They can be added to every dish. They can be sweet and they can be salty.
      </div>
      <br/>

      <div>
        Through a growing practice, Nicole Sartirani will demonstrate how easy and cheap it is to grow flour worms at home, requiring only a small space, a freezer, a few tools, some worms from a pet shop, fresh vegetables, fruit, a mixture of different bio flours and care.
      </div>
      <br/>

      <div className="text-footnote">
        <b>Nicole Sartirani</b> is a chef, bio-insect farmer, and a micro-blogger. Focused on making and maintaining contact with nature, wherever one is, she has farmed and cared for creatures first as a child in Bergamo, and now, as an adult, in Berlin. Since attending training in Switzerland two years ago, Sartirani has been producing her own proteins at home in two square metres by learning how to grow and cook edible insects.
      </div>
  </div>,
  },
  {
    title: 'The Force Majeure: Selected readings on climate change',
    pdf: {
      url:abs('pdfs/2_force+majuretere.pdf'),
    },
    author:'By Teresa Dillon',
    category:'Reading',
    date:'02.08.2019',
    alt: true,
  },
  {
    windowTitle:<div>Ich habe meinen Re<em>g</em>enschirm <span className="one-word">ver<em>g</em>essen</span></div>,
    title:<div>Ich habe<br/>meinen<br/>Re<em>g</em>enschirm<br/>ver<em>g</em>essen</div>,
    author:'Julien Fargetton',
    caption:'Artist Julien Fargetton casts a curious eye on the afterlife of used umbrellas in this repair workshop.',
    category:'Workshop',
    date:'03.08 + 04.08.2019',
    url: 'https://vimeo.com/373177258',
    type: 'vimeo',
  },
  {
    title:<div>S<em>y</em>mbiosfera</div>,
    author:<div>Symbiosfera<br/>Organic Structure<br/>Kollektiv Eigenklang</div>,
    caption:'Symbiosfera project brings Organicstruktur and Kollektiv Eigenklang to lead a session of attunement and improvisational ambient experimentation highlighting the potential of music to connect us to the environment.',
    category:'Talk, Deep Listening, Impro Jam',
    date:'06.08.2019',
    albums:[
      {
        cover:abs('symbiosphera/symb.jpg'),
        thumbnail:abs('symbiosphera/symb.jpg'),
        singleTrack:true,
        tracks:[
          {id: '01',title:'Symbiosfera',url:abs('symbiosphera/Floating+university+symbiosfera+_+eigenklang+-recording.mp3')},
        ]
      },
    ]
  },

  {
    title:'"Can Rocks Die?" from Geontologies - A Requiem to Late Liberalism',
    // cellTitle:'"Can Rocks Die?" from Geontologies - A Requiem to Late Liberalism',
    // title: 'Can Rocks Die? from Geontologies - A Requiem to Late Liberalism',
    author:'By Elisabeth Povinelli',
    category:'Reading',
    date:'07.08.2019',
    alt: true,
    pdf: {
      url:abs('pdfs/7_can+rocks+die.pdf'),
    },
  },

  { /* ***********/
    title: <div>Co-Hivin<em>g</em></div>,
    url: 'https://vimeo.com/371699495',
    type: 'vimeo',
    author:'Alexandra Genis',
    caption:'Critical food designer Alexandra Genis, invited participants of this workshop to learn about biomimicry through the making of edible hives for the microflora on site.',
    category:'Workshop',
    date:'02.08 - 04.08.2019',
  },

  
  {
    title:<div>Com<em>p</em>ost Care</div>,
    author:'Die Boden Schafft',
    caption:'Biochemist Martina Kolarek of Die Boden Schafft teaches participants to construct a hot compost pile behind the kitchen of the Floating University in an orgy of organisms. ',
    category:'Workshop',
    date:'09.08 - 10.08.2019',
    gallery:[
      {
        url: abs('compost_care/1_IMG2291.jpg'),
        thumbnail: abs('compost_care/thumbnails/1_IMG2291.jpg'),
      },
      {
        url: abs('compost_care/2_IMG2347.jpg'),
        thumbnail: abs('compost_care/thumbnails/2_IMG2347.jpg'),
      },
      {
        url: abs('compost_care/3_IMG2393.jpg'),
        thumbnail: abs('compost_care/thumbnails/3_IMG2393.jpg'),
      },
      {
        url: abs('compost_care/4_IMG2404.jpg'),
        thumbnail: abs('compost_care/thumbnails/4_IMG2404.jpg'),
      },
      {
        url: abs('compost_care/5_IMG2430.jpg'),
        thumbnail: abs('compost_care/thumbnails/5_IMG2430.jpg'),
      },
      {
        url: abs('compost_care/6_IMG2437.jpg'),
        thumbnail: abs('compost_care/thumbnails/6_IMG2437.jpg'),
      },
      {
        url: abs('compost_care/7_IMG2676.jpg'),
        thumbnail: abs('compost_care/thumbnails/7_IMG2676.jpg'),
      },
      {
        url: abs('compost_care/8_IMG2491.jpg'),
        thumbnail: abs('compost_care/thumbnails/8_IMG2491.jpg'),
      },
      {
        url: abs('compost_care/9_IMG2497.jpg'),
        thumbnail: abs('compost_care/thumbnails/9_IMG2497.jpg'),
      },
    ]
  },

  {
    windowTitle:<div>Footnotes on the <span className="one-word">S<em>p</em>atial</span></div>,
    title:<div>Footnotes<br/>on the<br/>S<em>p</em>atial</div>,
    author:'Way Too Concrete',
    category:'Discursive Format',
    caption:'<b>Way Too Concrete</b> is a sporadic event series exploring aspects of spatial production and representation. Observing how conversations often ended with the cheery refrain, “I’ll send you the link”, the creators of Way Too Concrete decided to gather together all those tangential, digital footnotes to lively discussions, and share them with like-minded audiences. The freewheeling salon is based around screenings and presentations and the approach is speculative, personal and entertaining: specific enough to inspire new trains of thought, but loose enough to avoid becoming way too concrete. Way Too Concrete is created and hosted by Sandra Bartoli, architect and cofounder of the Büros für Konstruktivismus; Fiona Shipwright, writer and editor and founding member of the &beyond collective; and Ian Warner, communication designer and writer, cofounder of the design consultancy State and founder of online magazine Slab Mag.',
    date:'10.08.2019',
    text:<div>
      <div>
        The United Arab Emirates has announced plans to tow icebergs from Antarctica to Fujairah to provide its citizens with fresh drinking water. Meanwhile, artist Olafur Eliasson has been depositing icebergs from Greenland in public spaces in London, Copenhagen and Paris. Both are making a point about climate change, but one seems to be inviting us to perceive the iceberg as a spectacular commodity, and the other as an affecting metaphor.
      </div>
      <br/>

      <div>
        This special edition of Way Too Concrete explores the iceberg from within this field of tension, directing its gaze to the invisible 90%, lurking just below the surface. 
      </div>
    </div>,
  },
  { /* ***********/
    title:<div>Li<em>q</em>uidation</div>,
    author:'Rachid Moro',
    // url:abs('liquidation.mp4'),
    // type:'video',
    url: 'https://vimeo.com/371701793',
    type: 'vimeo',
    caption:'Accelerated from natural time, every evening at dusk, this light installation from musician and artist Rachid Moro makes us aware of the melting iceberg and the setting sun.',
    category: 'Light Installation, Performance',
    date: '01.08 - 10.08.2019',
  },
  {
    title: <div>Rituals<br/>for Collective Self-Care</div>,
    windowTitle: <div>Rituals for Collective Self-Care</div>,
    author:'Extinction Rebellion',
    category:'Workshops, Reading Group',
    date:'04.08.2019',
    text:<div>
      <div>
        The planet in the midst of the sixth mass extinction and communities around the world are feeling the disastrous effects of climate breakdown. Change is already happening all around, and whatever the future holds, it will be different from the lives we live today. How can we face a crisis of this scale without becoming paralysed by our fear and grief? How do we deal with loss? Join members of Extinction Rebellion as we come together in love and rage to create new rituals to meet our collective needs.
      </div>
      <br/>

      <div className="text-footnote">
        <b>Extinction Rebellion</b> is a rapidly growing international climate justice movement. Through non-violent civil disobedience we pressure our governments to carry out the radical changes which are needed in the face of this global climate emergency. For more information please visit: www.extinctionrebellion.de or www.rebellion.earth
      </div>
    </div>,
  },
  {
    title:<div>Animal Instinct</div>,
    author:'Animalesque',
    caption:'A ten-day visiting architectural school exploring human-animal co-existence led by Ana Zatezalo Schenk, Jorge Godoy, Sjoerd Krijnen, Erika Mayr and Florentin Steininger. ',
    date:'01.08 - 10:08.2019',
    category:'Visiting School',
    url:'https://vimeo.com/373337727',
    type:'vimeo',
  },
  {
    title:<div>Make Material Sense</div>,
    caption:'Curator Ana-Lisa Alperovich teaches a hands-on workshop on what could biomaterials be, how they are made, preserved and used, as part of a growing archive of resourceful alternatives to toxic commodities produced today.',
    category:'Workshop',
    date:'05.08 - 07.08.2019',
    author:'Ana Lisa Alperovich',
    gallery:[
      {
        url: abs('make_material_sense/1.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/1.jpg'),
      },
      {
        url: abs('make_material_sense/2.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/2.jpg'),
      },
      {
        url: abs('make_material_sense/3.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/3.jpg'),
      },
      {
        url: abs('make_material_sense/4.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/4.jpg'),
      },
      {
        url: abs('make_material_sense/5.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/5.jpg'),
      },
      {
        url: abs('make_material_sense/6.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/6.jpg'),
      },
      {
        url: abs('make_material_sense/7.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/7.jpg'),
      },
      {
        url: abs('make_material_sense/8.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/8.jpg'),
      },
      {
        url: abs('make_material_sense/9.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/9.jpg'),
      },
      {
        url: abs('make_material_sense/10.jpg'),
        thumbnail: abs('make_material_sense/thumbnails/10.jpg'),
      },
    ]
  },
  {
    title:<div>Wasserlabor</div>,
    author:'Katherine Ball',
    // caption:'Artist Kathrine Ball hosts different sessions on the care for water - from practical water filtration system construction to physical exercises on water in our bodies. With Jose Ordonez, Erwin Nolde, Holger Sack and Naama Yatil. ',
    category:'Workshop',
    date:'06.08 - 08.08.2019',
    text:<div>
      <div>
        An avulsion occurs when a river floods over its banks to such an extreme that it jumps to a new course: the river abandons its old path and finds a new path of least resistance to follow.
      </div>
      <br/>
      <div>
        The Climate Care Water Session’s curriculum will seek to flood bodies with feelings, experiences, and knowledge about water to such an extreme we breach our banks of social constructions around water, finding a new path in which we flow with water, in new relationships and practices. 
      </div>
      <br/>

      <div>
        06.08     14:00 - 17:00 
      </div>
      <div>
        <b>Constructed wetland filters and membranes </b>
      </div>
      <div>
        with <i>Jose Ordonez, Martin Membranes</i>
      </div>
      <br/>

      <div>
    07.08     14:00 - 17:00
      </div>
      <div>
        <b>Water rituals: Water bodies. </b>
      </div>
      <div>
    A day of physical practices and experiments with water in the body with Paula Malherbe moving water in the body, through organs and circulation systems through the practice of kundalini yoga, with Sara Bauchmüller and Luka Agreš, balancing water in the body through hand balancing, muscular and skeletal realignment, and games, and with Naama Ityel, dancing water in the body, including brushing and breathing through improvisational dance and a touch of shamanism. Please bring a swimsuit and towel if you want to get wet. 
      </div>
      <br/>

      <div>
    08.08     9:00 - 17:00
      </div>
      <div>
        <b>Moving Bed Reactors and water curriculums</b>
      </div>
      <div>
        with <i>Erwin Nolde</i> and <en>Holger Sack</en> (Innovative Wasserkonzepte)
      </div>
      <br/>

      <div>
        An avulsion occurs when a river floods over its banks to such an extreme that it jumps to a new course: the river abandons its old path and finds a new path of least resistance to follow.
      </div>
      <br/>
      <div>
        An avulsion occurs when a river floods over its banks to such an extreme that it jumps to a new course: the river abandons its old path and finds a new path of least resistance to follow.
      </div>
      <br/>

      <div className="text-footnote">
        <b>Katherine Ball</b> is a habitat for fungi and bacteria located on planet Earth. Their artistic interventions reimagine the infrastructure of everyday life and have included: living in an off-grid floating island building mushroom filters to clean a polluted lake, learning the art of biological sabotage in an apprenticeship with nature, and bicycling across the USA looking for small-scale solutions to the climate crisis. Katherine has a Master of Fine Arts in Social Practice from Portland State University and studied at the School of Walls and Space in Copenhagen. Last year Katherine was the ‘Water Filtration and Infiltration’ artist-in-residence at Floating University. For more, please visit: www.katherineball.com
      </div>
    </div>,
  },

  { /* ***********/
    title:<div>Water is Life b<em>y</em> Seed</div>,
    url:'https://vimeo.com/261023308',
    type:'vimeo',
    category: 'Screening and Discussion',
    date: '07.08.2019',
    caption:'Presented in collaboration with BIPoC vs. CO2 and the Gastivisits Berlin, this documentary screening tells the story of the struggle of Aboriginal communities against fracking in the bush, and how their struggle relates to Berlin today.',
    author: <div>Sumugan Sivanesan<br/>Die Gastivists Berlin</div>,
  },

  {
    title:'"Ocean Sensing and Navigating the End of this World"',
    // cellTitle:'"Ocean Sensing and Navigating the End of this World"',
    // title:<div>Ocean Sensing and Navigating the <span className='flip-letter'>E</span>nd of this World</div>,
    pdf: {
      url: abs('pdfs/9_ocean+sensing.pdf'),
    },
    author:'By Jennifer Gabrys',
    category:'Reading',
    date:'09.08.2019',
    alt: true,
  },

  {
    windowTitle:<div>Closing Part<em>y</em></div>,
    title:<div>Closing<br/>Part<em>y</em></div>,
    author:<div>No Shade</div>,
    date:'10.08.2019',
    category:'Closing Party',
    text: <div>
      <div className="text-footnote">
        <i>NO SHADE</i> is a club night and DJ training programme for female, trans and non-binary DJs based in Berlin. Developed in collaboration with <i>ACUD MACHT NEU</i> and funded by <i>Music Board Berlin</i>, two confirmed and paid slots are reserved in every <i>NO SHADE</i> party lineup for those in the training programme. Each party lineup features two NO SHADE DJs, an experienced local DJ and one well-known headliner. The two slots come with a one-month training programme to ensure a comfortable experience and is focused on DJing using <i>CDJ-2000NXS</i> and includes live hardware and software tutorials, private practice sessions, basic music theory knowledge, useful technical knowledge as well as partnering those selected with mentors who share tips, tricks and personal experiences.
      </div>
    </div>
  },
  { /* ***********/
    title:<div>Wan<em>g</em>ari Maathai</div>,
    url:'https://vimeo.com/35631869',
    type:'vimeo',
    category: 'Screening and Discussion',
    date: '05.08.2019',
    author:<div>Rebecca Abena<br/>Kennedy-Asante,<br/>Imeh Ituen</div>,
    caption:'Presented by BiPOC vs. CO2, this film screening and discussion tells the story of Wangari Maathai - the nobel prize winning ecofeminist.',
  },
  
  {
    title: 'Textures of a Restless Earth: Selected Readings on Water',
    author:'By Elise Hunchuck',
    category:'Reading',
    date:'03.08.2019',
    alt: true,
    pdf: { 
      url: abs('pdfs/3_readings+on+water+elise+hunchuk.pdf'),
    },
  },
  {
    windowTitle:<div>Ran<em>g</em>e of sounds</div>,
    title:<div>Ran<em>g</em>e<br/>of<br/>sounds</div>,
    author:'DJ Lehmann',
    date:'03.08.2018',
    category:'Party',
    text:<div>
      <div className="text-footnote">
        <i>DJ Lehmann</i> is a natural researcher who spends most of his time in a quest for musical rarities on vinyl, a journalist who has written about musical adventures since 1984, and a DJ living in Berlin since 1988. Lehmann has played Berlin’s legendary clubs, bars and underground projects including Festsaal Kreuzberg, Kaffee Burger, Soulcat, Mysliwska, Club 39 and others. He recently started a radio show with Valie Dee at <a href="http://reboot.fm">reboot.fm</a> and continues to release mixtapes with selections that reach back through the musical ages, resulting in a range of sounds from surreal landscapes to pulsating party action, interwoven into fascinating, unpredictable atmospheres.
      </div>
    </div>
  },
  {
    title:<div>Skinshi<em>p</em></div>,
    author:'Susan Ploetz',
    // caption:'Artist Susan Ploetz explores the idea os Skinship - how to create bonds through physical contact and the expansion of our touch competency in this somatic workshop and LARP.',
    category:'Workshop, Mini-Larp',
    date:'05.08 + 07.08.2019',
    text:<div>
      <div>
      Skinship is a word mostly used in Japan and Korea to denote the act of bonding through physical contact. In many cultures (especially western), this is experienced mainly through romantic or close family relationships, despite being creatures made to experience the world through touch, through contact. This workshop will use live action role play (LARP), through consensual and non-sexual physical contact, to work towards expanding participant’s touch competency: how can a human’s sense of touch be expanded? How this can develop a kinship to what is inside and what is around each human body, including other humans, creatures, and the environment / materials?
      </div>
      <br/>

      <div>
  WORKSHOP: 
      </div>
      <div>
    05.08. / 14:00-17:00
      </div>
      <div>
  Participants will zoom in, learning the qualities of touch that tune into internal states and tissues, which in turn helps these tissues tune into their innate self-awareness which can transform feelings, often with healing effects. Through this same awareness towards qualities (texture, weight, movement, sound, etc.), participants will zoom out, connecting with the materials and environment around, tuning in to how these qualities are an expression of material intelligence and awareness, exploring how this could also be a method to heal participant’s relationship to their environment.
      </div>
      <br/>

      <div>
    Mini-Larp: 
      </div>
      <div>
    07.08 / 14:00-18:00 
      </div>
      <div>
  Participants will expand and explore the material of the workshop within a fictional narrative: one that will explore what it might feel like to live in a culture that is centered around Skinship, and if and how it could somehow provide a climate of care, or care for the climate, or care for the environment. Participants will be given a framework to co-create narratives and methods of interactions which could include movements, object making, magic/rituals, and poetry/sounding. The LARP is preceded by a short workshop and followed by a short debrief time where participants can share their experiences if they so desire. 
      </div>
      <br/>

      <div>
      Participation in both the workshop and the LARP is highly encouraged but not required. Participants should wear clothes that are weather appropriate but comfortable to move around in and to be touched in: bare skin is not necessary. All touch will be consensual and non-sexual, checking-in with each other is of paramount importance. Workshop will involve movement which should always be engaged in at each participant’s own comfort level.
      </div>
      <br/>

      <div className="text-footnote">
        <b>Susan Ploetz</b> is an artist-researcher working with somatics, theory, writing, performance and live action role play (LARP) in various configurations. Her work deals with the overlapping spaces of soma and technos, using imagination, magical materiality and protocol to induce emancipatory emotive dissonances and perceptual expansion. She has presented her work and taught at Martin Gropius Bau/Berliner Festspiele, Universität der Künste Berlin, The Pervasive Media Studio (Bristol), Sophiensaele, ABC Art Fair, Dutch Art Institute, dOCUMENTA (13), Portland Institute for Contemporary Art, and Performa.
      </div>
    </div>
  },

  {
    title: '"The Planetary Turn: Relationality and Geoaesthetics in the 20th Century"',
    // cellTitle: '"The Planetary Turn: Relationality and Geoaesthetics in the 20th Century"',
    // title:'The Planetary Turn: Relationality and Geoaesthetics in the 20th Century',
    author:'By Amy J. Elias & Christian Moraru',
    category:'Reading',
    date:'06.08.2019',
    alt: true,
    pdf: {
      url:abs('pdfs/6_planetary+turn.pdf'),

    },
  },
  {
    title: <div>Weather Writin<em>g</em></div>,
    author: <div>Laboratory for<br/>Aesthetics and Ecology</div>,
      date: '05.08.2019',
    category:'Reading Group, Workshop',
    text:<div>

      <div>
        The weather has always affected us. We become with weather, but we do not all weather equally. As feminist philosopher Astrida Neimanis reminds us: “even though we’re all in the same planetary boat when it comes to global warming, we’re not all in it in the same way… Climate change is undoubtedly political – but all the more so because of these uneven individual and collective experiences of the weather.” To build embodied cognition allows us to imagine the many lives and worlds of this – our – endangered planet, to weather together across human and more-than-human lines.
      </div>
      <br/>

      <div>
        Originally developed by Neimanis and further reworked by author Ida Marie Hede and the Laboratory for Aesthetics and Ecology, weathering as a practice borrows methods from écriture feminine, eco feminism and posthuman theory, inviting participants to write their weathered bodies in order to develop a more dense understanding of the co-constitutions of human and climactic natures. Participants will stimulate meteorological imaginaries and understanding of human-weather interrelations by asking: 
      </div>
      <div>
        Where is the weather? 
      </div>
      <div>
        What does the weather remember? 
      </div>
      <div>
        What does it forget?
      </div>
      <br/>

      <div>
        Together participants will investigate how attuning to the weather allows us to relate sustainably and ethically to the objects and systems around us. The workshop is open to everyone no matter their experience and previous writing practice and will be a combination of dialogue, writing and sharing – please be prepared to write and share writing with the group. Each participant will be sent a selection of shorter texts to read in preparation for the workshop. Although the workshop will be held in English, all are welcome to write in any language. Also, please note that parts of the workshop will take place outdoors no matter the weather, so please dress appropriately!
      </div>
      <br/>

      <div className="text-footnote">
        <b>Laboratory for Aesthetics and Ecology</b> is a platform for planetary becomings. The laboratory works with the embodied, theoretical and political implications of global, but unequally distributed environmental breakdown in response to multispecies worlds in urgent need of regeneration and care. We facilitate a myriad of multidisciplinary events, exhibitions, workshops and symposia across landscapes, disciplines, contexts and histories spanning from rural spaces, festival sites, ocean shores, boats, academic- and exhibition spaces and more. Our methods are performative, experimental and flow through and across genres. We aim for long durational, cross-pollinating knowledge productions in conversation with many partners.
      </div>
    </div>
    },
  {
    windowTitle: <div style={{whiteSpace:'nowrap'}}><span className='flip-letter'>E</span>nvironmental Personhood</div>,
    title: <div style={{whiteSpace:'nowrap'}}>
      <span className='flip-letter'>E</span>nvironmental<br/>Personhood
    </div>,
    author: 'Mauricio Corbalan',
    caption:'Architect Mauricio Corbalan takes us on a slide projection journey through Latin America in search of connections between a polluted river, a captive orangutan and juridical mechanism of environmental personhood in the struggle for political and ecological justice.',
    category:'Lecture',
    date:'09.08.2019',
    // videos: [
      // {
        url: 'https://vimeo.com/369932188',
        type: 'vimeo',
      // },
      // {
        // url: abs('environmental_personhood/sandra.mp4'),
        // type: 'video',
      // },
    // ]

  },
  {
    title:'Die Foodbrücke',
    author:'',
    date:'03.08 - 04.08.2019',
    category:'Cooking Workshop, Mobile Kitchen',
    text: <div>
      <div>
  Cooking is a perfect tool to connect different people, even if they are not able to speak the same language. Cooking together gives us a framework to ask: What is a neighbourhood? How can we mobilise and connect different neighbourhoods?
      </div>
      <br/>

      <div>
    Die Foodbrücke invites participants to discuss, rethink and analyse global-local sustainability challenges by creating small urban interventions in the area surrounding the Floating University.
      </div>
      <br/>

      <div>
      On Saturday the workshop will address food sustainability and neighborhood connections in these times of climate change from a local to a global scale. On Sunday, the K‘EAT‘Z Dinner & Fete de la Nachbarschaft provides the opportunity to cook your favourite meals and eat together with the Tempelhofer neighbourhood.
      </div>
      <br/>

      <div className="text-footnote">
        <b>Die Foodbrücke</b> is a mobile kitchen that also acts as a communication and connection tool as it links three global issues – neighbourhood communication, economic and social inclusion, and food sustainability. Die Foodbrücke also mobilizes people, leads participation processes and distributes knowledge about food sustainability and global-local climate issues. As a physical structure, co-produced together with local initiatives, Die Foodbrücke includes cooking together with different neighbours while getting to know each other’s cultures. Nothing connects people more than sharing a good meal.
      </div>
    </div>
  },
]

export default { ...data, grid };
