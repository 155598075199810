import d2019 from './2019'
import d2021 from './2021'
import d2023 from './2023'

export const years = [d2019, d2021, d2023]

// Set here the one to be shown in the homepage (/)
const homepage = d2023

// Make it so current changes based on the URL (e.g. /2021/)
// eslint-disable-next-line no-restricted-globals
const year = location.pathname.replace(/\//g, '')
const current = years.find(data => data.year === year) || homepage

export { current }