import React, {useEffect,useState} from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"; 

import useWindowSize from './components/windowSize.js'; // hook to get window size

import Header from './components/Header';
import Windows from './components/Windows'; // windows container
import './App.css';

import About from './about.js'; // about content
import Impressium from './impressium.js'; // impressium content
import { current, years } from './data'; // all other content

import Video from './components/Window/Video.js' // video container component
import  Modal  from './components/Window/MobileWarning.js';


const grid = current.grid.map((w,i) => ({...w,i:i%8,j:parseInt(i/8),}));

// Main react component
const App = () => {
  const size = useWindowSize();
  const mobile = size.width < 850 || (size.height < 500 && size.width < 900);
  const horizontal = mobile && (size.width > size.height);
   
  useEffect(() => { // this workaround is used for mobile phones for when the address bar gets hidden after initial load
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },[]);
  const routes = ['', ...years.map(data => data.year)].map(year => `/${year}`)
  
  return (
    <div className={`App ${mobile ? 'mobile' :''} ${horizontal ? 'horizontal' : ''} year-${current.year}`}>
      <Router>
        <Switch>
          {/* @see https://reactrouter.com/web/api/Route */}
          <Route key="/" path={routes} exact render={() => <ScheduleContainer mobile={mobile}/>} />
        </Switch>
      </Router>
      <Modal />
    </div>
  );
}

export default App;

// Splash screen containing video and grid "behind"
const Cover = ({onClick}) => {
  const size = useWindowSize();
  const mobile = size.width < 500 || (size.height < 500 && size.width < 900);
  const [mousePos, setMousePos] = useState({x:size.width/2,y:size.height/2})
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  },[])
  const handleMouseMove = (e) => setMousePos({x:e.clientX,y:e.clientY})
  if (mobile) {
    return <div className="app-cover cover-mobile" onClick={onClick}/>
  }

  const videoJsOptions = {
    loop:true,cover:true,fill:true,muted:true,preload:true,autoplay:true,
    controls:false,loadingSpinner:false,handleSetPlayer:() => {},
    poster: current.introFrame,
    sources: [{
      src: current.introVideo,
      type: 'video/mp4',
    }]
  }

  return <div className='app-cover' onClick={onClick}>
    <div className="homepage-video-container">
      {current.introVideo && <Video {...videoJsOptions} />}
    </div>
    <div className='cover-mouse' style={{transform:`translate(${mousePos.x - 100}px, ${mousePos.y - 100}px)`,}}>
      <div className="background-schedule"
        style={{transform:`translate(${-1 * (mousePos.x - 100)}px, ${-1*(mousePos.y - 100)}px)`,}}>
        <Schedule />
      </div>
    </div>
  </div>
}

// GRID CONTAINER
const ScheduleContainer = ({mobile}) => {
  const size = useWindowSize();
  const horizontal = size.width > size.height;

  const onCoverClick = () => {
    const _html = document.getElementsByTagName('html')[0]
    _html.style.overflow = 'auto'
    setShowCover(false)
  }

  const [showCover, setShowCover] = useState(true);
  const [windows, setWindows] = useState([])

  const videoJsOptions = {
    loop:true,cover:true,fill:true,muted:true,preload:true,autoplay:true,
    controls: false, loadingSpinner: false, handleSetPlayer: () => { },
    poster: current.introFrame,
    sources: [{
      src: current.introVideo,
      type: 'video/mp4',
    }]
  }

  const setShowCover2 = () => {
    setShowCover(true)
    // setShowImpressium(true)
    // show cover
    showAbout()
    // show about
  }
  const showAbout = () => {
    const imp = {id:'impressium',center:true,title:'Impressum',author: '',text: <Impressium/>}
    const ww = {id:'about',center:true,title: 'About',author: '',
      text: <About handleImpressiumClick={() => {
        setWindows(w => [...w,imp])
      }}/>
    }

    setWindows(w => [ww])
  }

  const handleWindowClose = (id) => setWindows(windows.filter(w => w.id !== id));

  const setWindowToFront = (id) => {
    if (id === 'about' || id === 'impressium' || id === undefined) return;
    setWindows([
      ...windows.filter(w => w.id !== id), 
      windows.find(w => w.id === id)
    ]);
  }

  if (mobile) {
    return <div className={`cover-mobile-container-${showCover ? 'cover' : ''}`}>
      {showCover && <div>
        <div><Schedule mobile={true}  horizontal={horizontal} setShowCover={setShowCover2}/></div>
        <div
          className={`${horizontal ? 'cover-mobile-horizontal' : 'cover-mobile'}`} 
          onClick={() => setShowCover(false)}
        >
          {current.introVideo && <>
            <Video
              {...videoJsOptions} />
            <Windows {...{ setWindowToFront, handleWindowClose, windows, mobile }} />
          </>}
        </div>
      </div>
      }
      {!showCover && <Schedule mobile={mobile} horizontal={horizontal} setShowCover={setShowCover2}/>}
    </div>
  }
  return <div className={showCover ? 'app-content-cover' : 'app-content'}>
    {showCover && <div>
      <Cover onClick={onCoverClick}/>
      <Windows {...{setWindowToFront, handleWindowClose, windows, mobile}} />
    </div>
      }
    {!showCover && <Schedule mobile={mobile} horizontal={horizontal} setShowCover={setShowCover2}/>}
  </div>
}

// Main layout. includes header, grid and windows
const Schedule = ({mobile,horizontal, setShowCover}) => {
  const [windows, setWindows] = useState([])
  const handleWindowClose = (id) => setWindows(windows.filter(w => w.id !== id));
  const showCover = () => setShowCover(true)

  const setWindowToFront = (id) => {
    if (id === 'about' || id === 'impressium' || id === undefined) return;
    setWindows([
      ...windows.filter(w => w.id !== id), 
      windows.find(w => w.id === id)
    ]);
  }

  const handleCellClick = (i, j, classes) => {
    const _id = 20*i + j
    const newWindows = windows.filter(w => w.id !== _id)
    let toggledWindow = []
    if (windows.find(w => w.id === _id)) {
      toggledWindow = []
    }
    else {
      toggledWindow = [{
        id:_id,
        classes,
        ...grid[(mobile?2:8)*i+j]
      }]
    }
    if (toggledWindow[0] && !toggledWindow[0].disabled) {
      setWindows([...newWindows,...toggledWindow])
    }
  }



  return <div className={`${mobile ? 'mobile' : ''} ${horizontal ? 'mobile-horizontal' : ''}`}>
    <div>
      <Header mobile={mobile} onMainHeaderClick={() => showCover()}/>
      <Grid {...{mobile, handleCellClick,horizontal}}/>
      <Windows {...{setWindowToFront, handleWindowClose, windows, mobile}} />

    </div>
  </div>
}

// Background Grid
const Grid = ({handleCellClick,mobile,horizontal}) => {
  let rows = []
  const cols = !mobile ? 8 : (horizontal ? 4 : 2);
  for (let i = 0; i < 40/cols; i++) {
    rows = [...rows, grid.slice(i*cols, (i+1)*cols)]
  }
  let k = -1
  return (
    <div className="grid-container-container" style={{backgroundImage:`url("${current.backgroundAsset}")`}}>
    <div className="grid-container">
      {rows.map((row,i) => {
        return <div key={i} className="row">
          {row.map((cell,j) => {
            k = k + 1;
            const classes = []
            if (cell.alt) {
              classes.push('alt')
            }
            if (cell.category) {
              classes.push('cat-' + cell.category.toLowerCase().replace(/\s+/g, '-'))
            }
            if (cell.fonts) {
              classes.push('font-' + cell.fonts);
            }
            return <div
              key={j}
              className={`cell ${classes.join(' ')}`}
            >
              <span className={`cell-content ${cell.disabled ? 'disabled' : ''}`}
                onClick={() => handleCellClick(i, j, classes)}
              >
                {!!cell.cellTitle ? cell.cellTitle : cell.title}
                <span className="cell-author">
                  {cell.author}
                </span>
              </span>
            </div>
          })}
        </div>
      })}
    </div>
    </div>
  );
}
