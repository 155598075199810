import React from 'react';
import { current, years } from '../../data'

const Nav = () => (
  <div className="app-header-right">
    <ul className="navigation">
      {years.map(data => {
        if (data === current) {
          return <li key={`nav-/${data.year}/`} className="selected">{data.year}</li>
        }
        return <li key={`nav-/${data.year}/`}><a href={`/${data.year}/`}>{data.year}</a></li>
      })}
    </ul>
  </div>
)
export default Nav;
