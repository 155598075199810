import React from 'react';
import { withRouter } from "react-router-dom";
import Nav from './Nav'
import { current } from '../../data'

let Header = ({history, mobile, onMainHeaderClick}) => {

  const link = current.headerAsset
  return (<div>
    {!mobile ? 
    <div className="app-header">
      <div className="app-header-left">
        <div onClick={onMainHeaderClick} className="main-header">
          {"CLIMAT"}<span className="flip-letter" style={{marginLeft:-1}}>E</span>{" CAR"}<span className="flip-letter" style={{marginLeft:-1}}>E</span>
        </div>
      </div>
      <a className="app-header-center" href={link || '#'} target={link && '_blank'} rel="noopener noreferrer">
        {current.title} {current.date}
      </a>
      <Nav mobile={mobile} />
    </div>
    : 
    <div className="app-header">
      <div className="app-header-links">
        <div className="app-header-right app-header-top" href="https://www.floatinguniversity.org/en/" onClick={onMainHeaderClick}>
          <span>
            {"CLIMAT"}<span className="flip-letter" style={{marginLeft:-1}}>E</span>{" CAR"}<span className="flip-letter" style={{marginLeft:-1}}>E</span>
          </span>
        </div>
      </div>
      <Nav mobile={mobile} />
      <div>
        <a className="app-header-middle app-header-center" href={link || '#'} target={link && '_blank'} rel="noopener noreferrer">
          {current.title} 
          <br/>
          {current.date}
        </a>
      </div>
    </div>
    }
  </div>
  );
}
Header = withRouter(Header);
export default Header;
