import React, {useState} from 'react';
import { Rnd } from 'react-rnd';
import ReactPlayer from 'react-player'
import useWindowSize from '../windowSize.js';
import Audio from './Audio.js';
import Gallery from './Gallery.js';
import Pdf from './Pdf.js';
import Videos from './Videos.js';

const Window = ({
  id, mobile,i,j,k,classes,
  title,category,date,url,type,author,albums,gallery,pdf,videos,text,caption,footnote,
  handleWindowClose, handleClick, center,windowTitle,dynamicContent,wide,
}) => {
  const screenSize = useWindowSize();

  /* replace br with comma in author */
  try {
  if (author.props) {
  author = author.props.children.map(a => {if (a.type === 'br') {return ' '} else return a;})
  }
  }catch{}

  /* WIDTH */
  let _width = 500;
  if (!mobile && url) _width = 580;
  if (!mobile && albums) _width = screenSize.width - screenSize.width * 0.4;;
  if (!mobile && albums && albums[0].singleTrack) _width = 520;
  if (!mobile && pdf) _width = 800;
  if (!mobile && (type === 'vimeo' || type === 'youtube')) _width = screenSize.width - screenSize.width * 0.4;
  if (!mobile && wide) _width = 800;
  if (!mobile && text) _width = 700;
  if (!mobile && gallery) _width = screenSize.width - screenSize.width * 0.4;

  /* HEIGHT */
  let _height = 380;
  if (url) _height = 450;
  if (mobile) _height = screenSize.height - 100;
  if (!mobile && albums) _height = screenSize.height - 100;;
  if (!mobile && albums && albums[0].singleTrack) _height = 420;
  if (!mobile && pdf) _height = screenSize.height - 100;
  if (!mobile && (type === 'vimeo' || type === 'youtube')) _height = screenSize.height - 100;
  if (!mobile && wide) _height = 340;
  if (!mobile && text) _height = 500;
  if (!mobile && gallery) _height = screenSize.height - 100;
  /* window specific options */
  if ((id === 63 || id === 86) && !mobile && albums) {
      _height = screenSize.height - 200;
  }
  if (id === 2 && !mobile && albums) {
      _height = screenSize.height - 250;
  }



  /* POSITION */
  let l = (screenSize.width / 2) - _width/2 - 20
  l =  l < 20 ? 20 : l;
  l = ((l+_width)>(screenSize.width-20))?(screenSize.width-_width-20):l;
  l += 25*k % 140

  let t = 60 + (screenSize.height / 5.5) * j - _height/2
  t = t < 50 ? 50 : t;
  t += 20 + 45*k % 205

  if (!mobile && center) {
    _width = 700
    _height = 450
    l = screenSize.width/2 - _width/2
    t = screenSize.height/2 - _height/2
  }

  /* STATE */
  const [size, setSize] = useState({width:_width,height:_height});
  const [position, setPosition] = useState({left: l,top: t,});

  /* METHODS */
  const handleDragStop = (e,d) => setPosition({left: d.x,top: d.y})
  const handleResize = (e, direction, ref, delta, position) => {
    setSize({width:parseInt(ref.style.width), height:parseInt(ref.style.height)});
  }
  const handleWindowCloseClick = (e) => {
    e.stopPropagation()
    handleWindowClose();
  }

  /* CONTENT */
  let content = lorem;
  content = !!url ? <ReactPlayer controls url={url} playing loop={true}/> : content
  content = videos ? <Videos videos={videos}/> : content
  content = albums ? <Audio albums={albums}/> : content
  content = gallery ? <Gallery gallery={gallery}/> : content
  content = pdf ? <Pdf i={i} pdf={pdf}/> : content
  content = dynamicContent ? dynamicContent() : content
  content = !!text ? text : content

  const contentContainer = <React.Fragment>
    <div className="window-close-btn" onClick={handleWindowCloseClick}/>
    <div className="window-header">{windowTitle ? windowTitle: title}</div>
    <div className="window-subheader">{author}</div>
    <div className="window-subheader caption">{caption}</div>
    <div className="window-content-container">
      <div className="window-content">{content}</div>
      <div className="window-corner">
        <div className="window-category">{date}</div>
        <div className="window-date">{category}</div>
      </div>
      <div className="window-footnote">{footnote}</div>
    </div>
  </React.Fragment>

  if (mobile) {
    return <div 
      className={`id-${id} window ${size.width < 640 ? 'narrow' : ''} ${pdf ? 'pdf' : ''} ${classes ? classes.join(' ') : ''}`} 
      style={{top:window.scrollY + 300}}>
      {contentContainer}
    </div>
  }

  return <Rnd className={`id-${id} window ${size.width < 640 ? 'narrow' : ''} ${classes ? classes.join(' ') : ''}`}
    default={{x:position.left,y:position.top,width:size.width,height:size.height}}
    onDragStop={handleDragStop} onResize={handleResize}
    onClick={handleClick}>
    {contentContainer}
  </Rnd>
};

export default Window;

const lorem = `Zufällige Begegnungen in Mumbai, Shanghai und Sao Paulo: The Visitor ist ein filmisches Experiment.
Keine der Szenen im Film wurde geplant oder geschrieben. Eine junge Frau, die Regisseurin selbst,
wandert stumm durch die drei Metropolen, beobachtet und lässt sich vom Zufall treiben.
Ohne mit den Menschen die ihr begegnen zu sprechen, kommt sie drei Menschen überraschend nah:
einem brasilianischer Taxifahrer, der seit 15 Jahren in seinem Taxi wohnt, einer erfolgreichen
Marketingmanagerin in Shanghai und einem chinesischen Wanderarbeiter. Mit ihnen teilt die
Regisseurin Zeit, Essen und Schlafplatz. Die Besucherin/Regisseurin bricht die Regel
der Bezugslosigkeit im öffentlichen Raum, nimmt Bezug auf zu einzelnen Menschen,
ohne die üblichen Höflichkeitsformen und Erklärungen, überhaupt ohne Sprache,
dafür in Anwesenheit der Kamera. Was dann passiert, ist aussergewöhnlich.
The Visitor lässt die Zusehenden buchstäblich über die Schulter der Regisseurin
auf verschiedene urbane Mikrokosmen blicken.
`;
