import React  from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { IconContext } from "react-icons";
// import useWindowSize from './../windowSize.js';

 

const Pdf = ({i,pdf}) => {
  // const size = useWindowSize();
  // const mobile = size.width < 500 || (size.height < 500 && size.width < 900);
  const loading = <div className="loading-pdf">
    {"Loading pdf..."}
  </div>

  return (
    <div className="pdf-container">
      <PDFViewer
        loader={loading}
        document={{url: pdf.url}}
        page={1}
        scale={0.6}
        scaleStep={0.15}
        minScale={0.2}
        navigation={Controls}
        css='window-pdf-canvas-container'
        canvasCss='window-pdf-canvas'
      />

    </div>
  );
};

const Controls = ({scale,page,pages,handleNextClick,handlePrevClick,handleZoomIn, handleZoomOut}) => {

  const _handleZoomOut = () => {
    activateZoomFreedom();
    handleZoomOut()
  }
  const _handleZoomIn = () => {
    activateZoomFreedom();
    handleZoomIn()
  }

  /* modify restraining css variables that keep it fitted on first load */
  const activateZoomFreedom = () => {
    /* get element setting the restraining css variables */
    let obj = document.getElementsByClassName('window-pdf-canvas')
    if (!obj.length) { return; } // we dont want no errores here
    obj[0].style.height = "auto";
    obj[0].style.width = "auto";
  }

  return (
    <div className="gallery-controls">

      <IconContext.Provider value={{ className: "iconss" }}>
        <div className="pdf-zoom-group" >
          <div className="pdf-zoom-out" onClick={_handleZoomOut}>
            <FiZoomOut/>
          </div>
          <div className="pdf-zoom-in" onClick={_handleZoomIn} >
            <FiZoomIn/>
          </div>
        </div>
      </IconContext.Provider>

      <div>
        <div className="gallery-control-page">{page}{"/"}{pages}</div>
        <div className="gallery-control-left" onClick={handlePrevClick}>{"≪"}</div>
        <div className="gallery-control-right" onClick={handleNextClick}>{"≫"}</div>
      </div>

    </div>
  );
}

export default Pdf;


// import { Document, Page, pdfjs } from 'react-pdf';
// import useWindowSize from '../../components/windowSize.js';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // const size = useWindowSize();

  // const [pageNumber, setPageNumber] = useState(1)
  // const [numPages, setNumPages] = useState(null)
  // const [scale, setScale] = useState(1)

  // const onDocumentLoadSuccess = (data) => setNumPages(data.numPages)
  // const incPage = () => {
    // if (pageNumber + 1 < numPages) 
      // setPageNumber(pageNumber+1)
  // }
  // const decPage = () => {
    // if (pageNumber - 1 > 0) 
      // setPageNumber(pageNumber-1)
  // }

  // const zoomIn = () => setScale(scale + 1)
  // const zoomOut = () => setScale(scale - 1)

  // if (i%2===1) {
  // return (
    // <object data={pdf.url} type="application/pdf">
      // <embed src={pdf.url} type="application/pdf" />
    // </object>
  // );
  // }

      // <Document
        // file={pdf.url}
        // onLoadSuccess={onDocumentLoadSuccess}
      // >
        // <Page 
          // pageNumber={pageNumber} 
          // height={400}
        // />
      // </Document>

    // page:pageNumber,
    // total:numPages,
    // incPage,decPage,
    // zoomIn, zoomOut,
    // scale
  //

