import React from 'react';
const year = '2023';

const abs = (url) => {

    return `${process.env.PUBLIC_URL}/assets/${year}/${url}`
  }

const PDFDownloadCritter = () => {
  const handleDownloadClick = () => {
    const url = abs('pdfs/Critterkratia_Hybrid_Worlds.pdf'); 
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Critterkratia_Hybrid Worlds.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <span onClick={handleDownloadClick} style={{ cursor: 'pointer', textDecoration: 'underline'}}>Download Curatorial Text</span>
    </div>
  );
};

export default PDFDownloadCritter;