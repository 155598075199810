import React from 'react';
const year = '2023'

const abs = (url) => {

    return `${process.env.PUBLIC_URL}/assets/${year}/${url}`
  }

const PDFDownloadSpaces = () => {
  const handleDownloadClick = () => {
    const url = abs('pdfs/Spaces-Processes-Creatures_research-map.pdf'); 
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Map.pdf'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <span onClick={handleDownloadClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Download Research Map</span>
    </div>
  );
};

export default PDFDownloadSpaces;