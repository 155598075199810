import React from 'react';

const About = ({handleImpressiumClick}) => {
  const handleClick = (e) => {
    e.stopPropagation();
  }

  
  return <div onClick={handleClick}>
    <b>Climate Care:</b><br/>
    Theory and Practice on a Natureculture Learning Site<br/>
    <br/>
    Climate Care is a festival engaged with theory and practice at the intersection of climate challenges, ethics of care and environmental humanities. Emerging from weathering the conditions of its site, the program is a result of in-depth cohabitation with the constructed water infrastructure, its human culture and its multispecies overlays. <br/>
    <br/>
    Curated by Gilly Karjevsky & Rosario Talevi<br/>
    <br/>
    ~ ~<br/>
    <br/>
    <b>Climate Care 2023: Critterkratia</b><br/>

    Curators: Gilly Karjevsky, Rosario Talevi <br/>

Assistant Curators: Jeanne Astrup-Chauvaux, Vida Rucli<br/>

Production: Serena Abbondanza, Eliza Chojnacka, Ute Lindenbeck, Carmen
Staiano<br/>

Graphic Design: Y-U-K-I-K-O<br/>

Web Design: Roman Karrer<br/>

Photography: Mor Arkadir<br/>

Programme Editor: George Kafka<br/>

Hospitality: TDD - Tischlein Deck Dich<br/>

Landing Page 2023 video: Constanze Flamme<br/>

Artists: With Gabriela Aquije, Between Us and Nature, Lorene Blanche, Elisa
Bosisio, BXLunatics (Bill Green, Elise Borgese), Lorena Carras, Sebastian Díaz
de León, Teresa Dillon, Cilia Hermann, Jacky Hess, Hybrid Infrastructures Gaze
(Jeanne Astrup-Chauvaux, Katherine Ball, Jöran Mandik, Garance Maurer),
Gülsüm Güler, David Horvitz, Susanne Jaschko, Mónica Kisic, Halina Kliem,
Eva-Fiore Kovacovsky, Klaas Kuitenbrouwer, Michael Marder, MELT (Ren
Loren Britton & Iz Paehr), MOULD (Sarah Bovelett, Anthony Powis, Tatjana
Schneider, Christina Serifi, Jeremy Till, Becca Voelcker), PARKS (Franziska
Dehm, Johanna Padge, Nuriye Tohermes), Yasmine Ostendorf-Rodríguez,
Lucy Powell, Sina Ribak, Alona Rodeh, ruangrupa, Colin Self, Alizée Sérazin,
Sinema Transtopia (Eirini Fountedaki, Rachel Pronger), Studio YUKIKO, Marek
Tuszynski, Stefanie Wenner, Zabriskie bookshop, ZAKOLE.<br/>

The 2023 edition of the spatial experiments at Floating University took place
within the framework of the “BUILD+CARE+REPAIR” programme.
Technique: Felix Wierschbitzki<br/>
Architecture: Lorenz Kuschnig, Florian Stirnemann, Felix Wierschbitzki
Building and Programme Organisation: Lorenz Kuschnig Lefort
Interpreted and Built by Leonard Strübin, Mathilde Dewavrin, Jade Dreyfuss,
Stefan Klopfer.<br/>

Food: TDD- Tischlein Deck Dich, Anna Herbert, Indira Colin<br/>

Volunteers: Adriana Gahona, Andrew Wu, Anna Jannicke, Eva Körber, Felix
Werner, Fernanda Ayala Torres, Fotini Takirdiki, Johanna Stodte, Julia Walk,
Justin Sante, Katharina Ripea, Konstatin Prishep, Louisa Kohlhoff, Mado
Lenius, Marc Schmidt, Marie Dietze, Sarra Abid.
    <br/>
    ~ ~<br/>
    <br/>
    <b>Climate Care 2021: The Rewilding Years</b><br/>
    <br/>
    Programme Assistants: Jeanne Astrup-Chauvaux, Eliza Chojnacka, Hannah Lu Verse, Lorène Blanche Goesele<br/>
    Production: Felix Wierschbitzki, Maddalena Pornaro, Serena Abbondanza, Johan Kirsmäe<br/>
    Graphic Design: Y-U-K-i-K-O <br/>
    Web Design: Roman Karrer<br/>
    Photography: Lena Giovanazzi, Constanze Flamme<br/>
    Programme Editor: George Kafka<br/>
    Artists:  Andyvazul, Anna Fainareti Lioka, Barbora Sedova, Ben Glas, Black Earth Collective, Cleo Wächter, Daisuke Ishida, Diane Barbé, Elisabeth Povinelli, Fog Puma, Garance Maurer, Hoops, Hyewon Suk, Infuso Giallo, Jakob Walter, Johanna Wehkamp, Joulia Strauss, Kate Tyndall, Katherine Ball, Lisa Ertl, Marc Herbst, Marjetica Potrč, Maternal Fantasies, Menghan Wang, Michelle Teran, Mitchell Keaney, Monaí de Paula Antunes, Between Nature and Us, Nonhuman Nonsense, Patricia Reed, Radu Ciorniciuc, Rahab Njeri, Sandra Jasper, Sarah Friend, Selina Seyd, Siobhan Leddy, Sumugan Sivanesan, Susan Ploetzly, Tatu, Teresa Dillon, Valentina Karga, Vilte Gustyte, Zabriskie Bookshop<br/>
    Spatial Experiments: Felix Wierschbitzki, Florian Stirnemann, Jeanne Astrup-Chauvaux, Katherine Ball, Lorenz Kuschnig, Stefan Klopfer<br/>
    Construction Management: Felix Wierschbitzki, Lorenz Kuschnig<br/>
    Building Team: Ariel Curtelin, Benjamin Frick, Ester Bonneau, Florian Kurzenberger, Jan Schlake, Jan Theiler, Jeanne Astrup-Chauvaux, Johan Kirsimäe, Jonas Johnke, Katherine Ball, Maddalena Pornaro, Maxi Schneider, Moritz Wermelskirch, Samuel Boche, Serena Abbondanza, Stefan Klopfer<br/>
    Volunteers: Alice, Alina, Anne, Begs, Caroline, Deneb, Elias, Garance, Gribouille, Helene, Jade, Katie, Lenny, Lilith, Liva, Lorenz, Lotti, Luca, Ludwig, Magda, Matea, Matheu, Mathilda, Max, Paula, Rose, Sunniva, Timo, Vilma und Lumi<br/>
    Food by TischleindDeckDich <br/>
    <br/>
    ~ ~<br/>
    <br/>
    <b>Climate Care 2019: A Curriculum for Urban Practice</b><br/>
    <br/>
    Programme Associate: Carla Kienz<br/>
    Production: Teresa Huppertz<br/>
    Architecture: Florian Stirnemann<br/>
    Programme Assistants: Serena Abbondanza, Jeanne Astrup Chauvaux, Sarah Bovelett, Ronja Schratzenstaller<br/>
    Graphic Design, Webdesign: Roman Karrer<br/>
    Video production, Webdesign: ATLAS/STUDIO<br/>
    Photography: Lena Giovanazzi<br/>
    Copy-Editing: Elise Hunchuck<br/>
    Translation: Constantin Abbondanza<br/>
    Bookkeeping: Nina Klöckner<br/>
    Construction Manager: Malin Mohr<br/>
    Building Team: Aghate Beierholm, Alexander Rafter, Alizée Serazin, Ashmi Mridul, Ayaka Ishikawa, Benjamin Frick, Bozkurt Ceugiz Yasiu, Candice Brun, Celestine Peuchet, Ceola Tunstall-Behrens, Charlotte Peter, Chong Jin Chen, Corinna Esau, Eleanor Peres, Julien Fargetton, Felix Wierschbitzki, Flora Lechner, Helen Milne, Jan Schlake, Jan Theiler, Juliette Kaminski, Kristina Reed, Lorenz Kuschnig, Monica van Wingerde, Miguel Guevara, Marina Kuck, Mai Ta, Leslie Ranzoni, Lena Frank, Livia Walicer, Lydia Hwang, Maria Mellado, Masayoshy Waku, Meghan Clarke, Natalia De León Hernández, Niall Iceville, Noelle van den Dunegn, Pete Fung Ho Ching, Richard Thomas Fairley, Robert Fobbe, Sabine Rosemann, Samuel Boche, Sjoerd Krijnen, Theresa Lohse<br/>
    Raumteamleitung: Stefan Klopfer & Monica van Wingerde<br/>
    Raumteam: Anna Wentritt, Florian Kurzenberger, Elena Lochore-Ward, Yoni Carnice, Sabine Rosemann, Raphael Huber Dimitris Mitso<br/>
    Garden Team: Laureen Hünig, Lorène Goesele, Sarah Yaparsidi, Nina Peters<br/>
    Art of Cooking: tddblog.com<br/>
    Drinks: Hannah Lu Verse<br/>
    Graphic Design: Roman Karrer<br/>
    Photography: Lena Giovanazzi<br/>
    Video documentation: ATLAS/STUDIO<br/>
    Copy-Edit: Elise Hunchuck, George Kafka<br/>
    Translation: Constantin Abbondanza, Jöran Mandik<br/>
    Images: Lena Giovanazzi, Katherine Ball, Roman Karrer<br/>
    <br/>
    ~ ~<br/>
    <br/>
    <b>Digital Archive</b><br/>
    The digital archive was conceptualised by ATLAS/STUDIO (Lucía Alonso & Ernesto Bauer) and programmed by Lucas Pose, with graphic design by Roman Karrer.  For the 2021 and 2023 edition studio YUKIKO re-designed it and Nicolás Cohen, Antonela Ferraro Milano and Martina Mainero programmed it. <br/>
    <br/>~ ~
    <br/>
    Floating University is a natureculture learning site where practitioners from a wide range of backgrounds meet to investigate alternative, biodiverse forms of co-habitation in a partially contaminated rainwater basin, part of the former Tempelhof airport. Initiated as a temporary project in 2018 by architecture group raumlabor, since 2019 an association continues the experiment: to open, maintain, and take care of this unique site while bringing non-disciplinary, radical, and collaborative programs to the public. In other words, it is a place to learn to engage, to embrace the complexity and navigate the entanglements of the world, to imagine and create different forms of living.
    <br/><br/>
    Floating e.V. (an association of around 60 members) opens the space of Floating University to the public from May to October with various kinds of seasonal programs: workshops, cooking events, reading sessions, conferences, movement workshops, film screenings etc. Beside opening the site and making it accessible for humans, the role of the association is also to observe, understand, care and protect the different forms of life in the basin.  
    <br/><br/>
    Association Members: Adriana Gahona, Alexis De Raphelis, Andrea Hofmann, Andrew Wu, Anna Kokalanova, Benoît Verjat, Benjamin Foerster-Baldenius, Benjamin Frick, Benoît Verjat, Berit Fischer, Camilla Bausch, Carla Kienz, Carmen Staiano, Christof Mayer, Coco Wiss, David Morsi, Doro Halbrock, Eliza Chojnacka, Erika Mayr, Felix Wierschbitzki, Florian Förster, Florian Kurzenberger, Florian Stirnemann, Franziska Matthis, Garance Maurer, Gilly Karjevsky, Gusse Güler, Hannah Lu Verse, Inci Güler, Jade Dreyfuss, Jeanne Astrup-Chauvaux, Joanne Pouzenc, Jonas Dahm, Jöran Mandik, Katherine Ball, Katja Szymczak, Kristin Lazarova, Laura Raber, Leonard Strübin, Lorène Blanche Goesele, Lorenz Kuschnig Lefort, Maddalena Pornaro, Markus Bader, Martina Kolarek, Martin Kaltwasser († 30.12.2022), Mauricio Crobalan, Nina Peters, Raul Walch, Roman Karrer, Ronja Schratzenstaller, Rosario Talevi, Sabine Zahn, Sarah Bovelett, Serena Abbondanza, Sophia Tabatadze, Stefan Klopfer, Stefan Kreft, Silja Teresa Huppertz, Tobias Florek, Ute Lindenbeck. 
    <br/>
    <div className="impressium" onClick={handleImpressiumClick}>Impressum</div>
  </div>
}

export default About;
  // Board: Benjamin Foerster-Baldenius, Carla Kienz, Dr. Katja Szymczak
