import React, { useState, useEffect } from 'react';

const Modal = () => {
  const [isOpen, setIsOpen] = useState(false);

  
  useEffect(() => {
    const checkSize = () => {
      if (window.innerWidth < 769) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    
    checkSize();

    window.addEventListener('resize', checkSize);

    
    return () => window.removeEventListener('resize', checkSize);
  }, []);
  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isOpen && (
        <div className="popup">
          <div className="popup_inner">
          <button onClick={closePopup}>X</button><br/>
            This website is not optimised for mobile versions. Please visit the Climate Care Digital Archive on your desktop. Thank you!<br/>
          </div>
        </div>
      )}
    </div>
  );
};


export default Modal;