import React from 'react';
import Window from '../Window';

const Windows = ({handleWindowClose, windows, setWindowToFront, mobile}) => {
  windows = windows.filter(w => w !== undefined)
  return <React.Fragment>
    {windows.map((w,k) => <Window {...{
      key:w.id,id:w.id,k,...w,mobile,
      handleClick: () => setWindowToFront(w.id),
      handleWindowClose:() => handleWindowClose(w.id)
      }}/>
    )}
  </React.Fragment>
}
export default Windows;
