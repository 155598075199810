import React from 'react';
import ReactPlayer from 'react-player'

const Videos = ({videos}) => {

  return (
    <div className="videos-container">
      {videos.map((v,i) => {
      return <div className="video-container" key={i}>
        <ReactPlayer url={v.url} 
          playsinline={true}
          controls={true}
          playing={true} 
          loop={true}
          fileConfig={{ attributes: { playsinline: true,'webkit-playsinline': true } }}
        /> 
      </div>
      })}
    </div>
  )
}

export default Videos;
