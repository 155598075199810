import {useState, useEffect} from 'react';
/* Window Size Hook */
/*eslint-disable */
function useWindowSize() {
  const isClient = typeof window === 'object';
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    if (!isClient) return false;
    const handleResize = () => {
      setWindowSize(getSize())
      setTimeout(() => {
        return setWindowSize(getSize())
      },200);
      setTimeout(() => {
        return setWindowSize(getSize())
      },400);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
/*eslint-enable */

export default useWindowSize;

