import React from 'react';
import PDFDownloadSpaces from '../components/Window/downloadLink1.js'
import PDFDownloadCritter from '../components/Window/downloadLink2.js'

const year = '2023'


const abs = (url) => {

  return `${process.env.PUBLIC_URL}/assets/${year}/${url}`
}
/*
* Because of the font, some letters are 
* inverted and moved up to keep desired aspect. 
'moved up' example: <div>Glacier Soundsca<em>p</em>e</div>,
'inverted' example: <div>On <span className='flip-letter'>E</span>arth</div>,

grid cells are of type: {video,pdf,text,album (audio)}
assets are uploaded to amazon s3. 
*/

const data = {
  year,
  title: <div><div className='scaley'>Critterkratia</div><div className='inline'>21–30 September 2023</div></div>,
  date: '',
  // Link opened when clicking the year's title (can be null for no link)
  headerAsset: abs('pdfs/_CC23_Program_Web.pdf'),
  // Shown as soon as the page starts
  introVideo: abs('_opening-video-reeds-2023.mp4'),
  // Shown while the video loads
  introFrame: abs(''),
  // This asset is the grid's tile background+border
  backgroundAsset: abs('tile.png'),
}

/* Content for grid and windows */
const grid = [
  //ROW 1
  //1
  {
    title: <div>A Language Unknown</div>,
    author: <div>David Horvitz</div>,
    category: 'Performance',
    caption: <div>
      <div>
        This performance comprises a score for human voices, intended to mimic the sounds of the Ballona
        creek as it flows into the Pacific Ocean. By transliterating this to the English alphabet and bringing the
        sounds to Berlin – the rainwater retention basin, the canal system and finally to the River Spree – the
        sounds are carried from California to Berlin through an unknown language.</div>
      <div>
        <br />
        David Horvitz was born in Los Angeles, where he currently lives and works. Witty and poetic, his work
        meddles with systems of language, time and networks.
        <br /><br />
      </div></div>,
    date: '21.09.2023 20:00',
    gallery: [
      {
        url: abs('9/01_A-Language-Unknown.jpg'),
        thumbnail: abs('9/01_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/02_A-Language-Unknown.jpg'),
        thumbnail: abs('9/02_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/03_A-Language-Unknown.jpg'),
        thumbnail: abs('9/03_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/04_A-Language-Unknown.jpg'),
        thumbnail: abs('9/04_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/05_A-Language-Unknown.jpg'),
        thumbnail: abs('9/05_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/06_A-Language-Unknown.jpg'),
        thumbnail: abs('9/06_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/07_A-Language-Unknown.jpg'),
        thumbnail: abs('9/07_A-Language-Unknown.jpg'),
      },
      {
        url: abs('9/08_A-Language-Unknown.jpg'),
        thumbnail: abs('9/08_A-Language-Unknown.jpg'),
      },
    ],
    fonts: 1,

  },
  //2
  {
    title: <div>Critterkratia: Hybrid Worlds</div>,
    author: <div>Gilly Karjevsky<br /> Rosario Talevi<br /> Jeanne Astrup-Chauvaux<br /> Vida Rucli</div>,
    category: 'curatorial text',
    text:<div>
      <PDFDownloadCritter/><br/>
      <div>
        Critter (plural critters) (usually endearing) A creature, an animal.<br />
        Kratia Derived from κρᾰ́τος (krátos, “strength, power”) + -ῐ́ᾱῐ́ (-íā, noun-forming suffix).<br /> <br />
        <div className='text-footnote'>...”On what basis do we elect where to invest our allegiance? If I were forced, I would choose Maple
          Nation. If citizenship is a matter of shared beliefs, then I believe in the democracy of species. If
          citizenship means an oath of loyalty to a leader, then I choose the leader of the trees. If good citizens
          agree to uphold the laws of the nation, then I choose natural law, the law of reciprocity, of regeneration,
          of mutual flourishing.”...
          Robin Wall Kimmermer, Braiding Sweetgrass
        </div><br />
        Climate Care Festival is a site-symbiotic public programme. The festival invites
        Floating University association members (see p. 22 - Hybrid Gaze) , artists,
        thinkers, scientists, activists, designers and the general public to acknowledge
        that we are situated in relation and in cohabitation with an infrastructural site –
        a partially contaminated rainwater retention basin serving Tempelhofer Feld –
        and its many living forms. In 2019, the festival’s first edition explored the
        complex interplay between ecology, pedagogies and ethics of care and sought
        to address climate breakdown through a curriculum for urban practice. The
        second edition, in 2021, critically explored the notion of rewilding as an
        alternative to the city’s plans to “renaturalise” the basin. The programme
        questioned both the biological and ethical implications of this intervention at
        micro and macro levels. For this third edition, Climate Care 2023 continues its
        engagement with the site of the Floating University by prototyping a more-than-
        human collective and collaborative approach to the negotiation, care-taking,
        maintenance and mediation of hybrid urban infrastructures in Berlin and
        beyond.<br /><br />

        Following Robin Wall Kimmermer and others who call for The Democracy Of
        Species, we take the time and space of the festival to find what that democracy
        might look like on the Floating site. This edition emerged as a programme in
        search of thinking, language and models which could point Floating e.V
        towards more-than-human eco-organising (see p. 27 - The Parkmeister*In).
        This search very actively aims to include the basin, the reeds, the frogs and all
        life forms within and of the basin, in the decision-making processes of the
        association and its collaborators. (see p. 26 - How to Zöop?)<br /><br />

        Since Tempelhof GmbH – the company entrusted with managing the basin –
        has announced plans of an “infrastructural improvement”, Floating e.V. – the
        association caring for this unique site – has repeatedly, and through various
        means, tried to become involved in the process. For example by developing
        and presenting to the Berlin Senate an alternative concept and strategic
        infrastructural update plan respectful with the living forms inhabiting the basin,
        or by drafting a “Letter of Intent” in which the association proposed that the
        different human stakeholders work collaboratively in the transformation of the
        rainwater retention basin. Floating e.V. 's main concerns are to enable the
        coexistence of the basin’s technical infrastructure, including its maintenance,
        care and planned renewal, with our cultural programmes, and that all technical
        and cultural works take place in solidarity with the existing ecosystem. (see p. 6
        - On Coexistence)<br /><br />

        <p className='underline'>Toxicity unveils divergent worldviews</p>

        Despite its concrete floor (see p.23 - Against Curing) and the water's partial
        level of toxicity, the basin is already a habitat for many species. In addition to
        the spontaneous forest that has grown surrounding it, a vast reed bed has been
        growing in the basin’s centre. This reed bed has, in turn, welcomed algae,
        bacteria, mosses (see p. 25 - World of Moss), fungi, insects, amphibians, birds
        and occasional mammals to make a home, with such success that the reeds
        have become a protected species. This reed bed has an additional important
        function: it is a water cleansing mechanism that consequently acts as a natural
        filter. But beyond its rich ecological undertaking, the reed bed and the many life
        forms within it have become an affective entity imbued with multiple meanings
        for us humans present on site: a symbol of regeneration and restoration, a
        soundscape, a mystical creature, a floating companion. (see p. 33 - Listening to
        Reeds)<br /><br />

        On a cold morning last March, the reed bed was violently uprooted without any
        preliminary announcement by Tempelhof GmbH’s maintenance department.
        While the loss of this once complex sustaining web of life was felt heavily by
        association members and neighbouring gardeners, the violence of this
        expedited action was targeted at the non-humans. In shock and sadness,
        Floating e.V. demanded answers from the company, who disclosed that the
        reason for the removal was the detection of human faeces in the water.
        According to them, the faeces had contaminated the entirety of the basin and
        an exhaustive cleanse was enforced in order to prevent the polluted water from
        flowing into the city’s canal system. Coincidentally, this removal of the reeds
        happened as the same maintenance department moves forward with a
        feasibility study of the basin, which requires a reed-free basin in order to
        proceed. To complicate the matter further, this technical action has been
        contested not only by the Floating association, but by the Kreuzberg district’s
        environmental office, who had declared the reed bed a protected habitat.<br /><br />

        Days later after the attack on the reed bed, Floating association members
        salvaged and repurposed some of the “toxic” reed and replanted it throughout
        the basin as an act of resistance. Today, despite this violent disturbance, the
        reed continues to regenerate and thrive (see p. 27 - Disturbance Theory). The
        reed reminds us of the Greek myth of Hydra: the serpentine water monster that
        for every head chopped off, would regrow two new ones. Visitors to this edition
        of the Climate Care festival, can look up and find a small, green, young reed
        bed growing back.<br /><br />

        It remains a mystery where the faeces came from and its exact level of toxicity,
        even if it ever existed. This unidentified source of shit that was the cause for
        such a violent and irreversible action raises questions around the problematic
        interpretation of scientific measures used to justify urban development
        procedures: How are levels of toxicity interpreted and for what purposes? Might
        the level of pollution in water be interpreted by management companies and
        instrumentalised as a tactic to remove habitat and later on to remove us – the
        caretakers – from the site?<br /><br />

        The difference between Floating e.V. and the city of Berlin’s conception of
        “improvement” is stark. For the city, improving this water infrastructure means
        subjugating the biological community present on site, intervening in its ecology
        and remaking it in the image of technocracy; prioritising their ideas of
        productivity, progress and efficiency. Climate Care yearns for more nuanced
        ways of implementing urban transformation that are earthbound, friendly to
        multiple species and considerate of more-than-human life cycles.<br /><br />

        <p className='underline'>Approaching difference</p>

        Looking at the ways Floating e.V. is organised and its role as caretaker for the
        rainwater retention basin, Climate Care tries to map the association’s relation to
        its site through different understandings of commons (Federici, 2019) un-
        commons (De La Cadena, 2018) and practices of commoning (Linebaugh,
        2008) as different practices of collective stewardship of public resources. We
        see these practices as standing in contrast to the extractive and productive
        logics which dominate the urban politics of Berlin as well as across the globe
        (see p. 26 - Architecture is Climate). Commoning, in its many forms, teaches us
        to focus on reproductive relationships between people and their environments
        and accept the indissolubility of the natureculture bond. Bringing this approach
        to negotiating the space of Floating University is the main challenge the
        association has faced since its inception at the end of 2018.<br /><br />

        Following the thinking around the politics of location, and situated modes of
        knowledge production, from writers such as Rosi Braidotti, Adrienne Rich and
        Donna Haraway, Climate Care takes a bio-cultural approach to stewardship –
        which engages the wellbeing of both non-humans and human communities
        together through kinship-linked and place-based activities (Massardo & Rozzi,
        2011) in a dense urban context, such as the one that Floating University Berlin is
        set in. This involves the overlap and interaction of various institutions and
        communities with their own knowledge systems, interests and power structures
        (see p. 28 - lumbung). For those actors, to best answer these challenges of
        growing diversity and relating in difference, in the face of the rapid environmental,
        social and climate change and disastrous biodiversity loss, it is crucial to
        recognise and support the agency of communities to act as guardians, custodians
        and caretakers of the places they inhabit.<br /><br />

        <p className='underline'>Hybrid cosmologies to find our own</p>

        Despite being a momentous step towards ecosystem preservation and to
        provide support structures to promote traditions which consider earth as a
        person, the concept of environmental personhood – the representation of more-
        than-human entities in human legal systems – raises the problem of
        representation or voicing (Tănăsescu, 2016). A web of complex relations and
        conflicts is exposed when one group of humans represents the needs and wishes
        of a river, in need of protection from the activity of another group of humans (see
        p. 37 - What is a River?). Addressing these challenges requires the re-shaping of
        our parliaments, trials and assemblies to welcome a hybridisation of perspectives
        (De Toledo, 2021). For Climate Care, at Floating University Berlin, the questions of
        how to listen, speak with and for the basin persist?<br /><br />

        Such understandings of terrestrial entities as persons have long been silenced
        by Western societies, or by the One World: "the world that has granted itself the
        right to assimilate all other worlds and, by presenting itself as exclusive,
        cancels possibilities for what lies beyond its limits" (Law, 2012). Nevertheless in
        some cases, Indigenous practices and communities-led resistance have
        managed to achieve legal personhood standing in the courts for terrestrial
        entities such as rivers, mountains and rocks, using western legal frameworks.
        The Te Awa Tupua Act passed by the New Zealand parliament in 2017 to
        protect the rights of the Whanganui River, is one such successful example<br />
        (Lurgio, 2019).<br /><br />

        How Floating e.V and Climate Care can learn from hybridity towards how to more
        ecologically organise themselves, is the question at the heart of this programme.
        This edition of Climate Care draws from this detailed research and continues to
        reimagine the relationship between the rainwater retention basin and Floating e.V.:
        its steward. What organisational structure is needed to support Floating University
        Berlin as a site with a community and as a place of entanglements between a
        resilient wetland ecosystem and an outdated water infrastructure?<br /><br />

        <p className='underline'>"Reproduction is the fulcrum of political form and existence"</p>

        Through workshops, readings and artistic interventions in the basin, Climate Care
        festival offers a journey through ideas, models and techniques that emphasise
        social and environmental wellbeing by rethinking reproduction in hybridised modes
        (see p.28 - Let’s Become Fungal!). We look at models for more-than-human
        organising, hybridised modes of food production, alternative models for income,
        and radical sharing-based co-habitation (see p.27 - Care Income). Perhaps by
        shifting our political understanding of the role of reproduction in our lives and work,
        the reproductive gift of nature, among other forms of reproduction, we might start
        to gleam a more livable future (see p. 34-36 - Cinecologies). Michael Marder
        described the myth of the phoenix as a symbol for the whole of nature: a myth that
        has travelled through many cultures over millennia, and has been denied its
        reproductive essence (see p.34 - In due Time). “Reproduction is the fulcrum of
        political form and existence; that political life is actually produced through its
        reproduction, with all the mechanics and machinations of substitution (including
        the substitution of the irreplaceable) built into the phoenix complex. The idea
        and the practice of rebirth, then, spawns both the content and the form of
        politics in the full spectrum of regimes, from autocratic to democratic” (Marder,
        2023, p.219).<br /><br />

        It is the way reproductive-creative work is approached – the work of organising,
        maintaining, reporting, archiving, applying – aside from the artistic work or the
        public programs - that determines and delivers on individual and collective values,
        and helps to embody them. If communities of practice, such as the one at Floating
        University Berlin, hold dear care as a value, but existing protocols of organising do
        not deliver on the care values that are imagined, then those protocols should
        change. Drawing lessons from natural phenomena as to how social and material
        phenomena might be more organically organised, is at the heart of this edition of
        Climate Care festival. How can Floating e.V, bring and adapt forms of creation,
        exchange, sharing, working, caring on this site?<br /><br />

        <p className='underline'>Hybridise Otherwise</p>

        More than teaching us of hybridity – nature teaches us about the impossibility of
        purity – human ideas evolve much like life forms do. The challenge is in facilitating
        the space for hybridising these various worldviews, linguistic forms, and
        relationships. What could a space for this kind of hybridising bring? What would
        have to be negotiated, and what would have to be lost? (see p. 20 - Milpa and p.
        26 - Harvest Moon Dinner)<br /><br />

        If the basin is a hybrid infrastructure (urban, social, cultural, ecological), then what
        can the custodian association at the basin learn from hybridity to inform how to
        organise on this site? Hybridity is a metalanguage, a navigational tool, a “mode of
        conduct” (Whatmore, 2002), a “curatorial method” (Ndikung, 2023) and an “elastic
        metaphor” (Gomez-Peña in Fusco, 1989) that supported the research for the current
        edition of the festival (see p. 24 - A Language Unknown). This research builds a position
        toward a different way of staying with, collaborating with, stewarding, governing and
        taking care of the basin which we defined as eco-organising.<br /><br />

        Hybrid is a noun and adjective that appeared in the 17th century in agronomy, botany,
        zoology and biology to indicate the offsprings of plants and animals of different varieties
        or species. Its etymology refers to hubris, the foundational theological and ethical topos
        of Ancient Greek literature, which indicates violence, arrogance and presumption toward
        the gods. An animal or plant born out of a transgression of natural laws was therefore
        defined as a hybrid. This same meaning then served as a metaphor for the negative
        consequences of racial encounters and supported the development of eugenics
        “becoming an organising motif of the race science imposed on human populations,
        particularly in the 19th Century imperial imagination, for whom the hybrid became a
        charged source of both desire and disgust” (Lewis, 2020).<br /><br />

        The term was then redeemed in the last three decades of the 20th Century
        metamorphosing into an indispensable category to describe cultural identities and
        postmodern subjectivity. Hybridity was particularly embraced by decolonial scholars and
        authors who articulated it using different subcategories as transculturation (Morejon,
        1982), mestizaje (Anzaldúa, 1987), creolization (Glissant, 1990), creoleness (Bernabé et
        al., 1990), métissage (Vergès, 1999), pidginization (Ndikung, 2023). These terms
        became fertile grounds for the development of theories on postcolonial, mixed and
        complex identities, and stimulated political action against hegemonic languages and
        colonial oppression.<br /><br />

        <p className='underline'>Creatures, Cyborgs, Monsters, Ghosts, Holobionts</p>

        When approaching the term within decolonial studies, we understand hybridity as a
        process of identity formation which considers cultural mixture, elaborated by different
        authors as “ambiguity, ambivalence” (Verges, 1999), “interference, shock” (Glissant,
        1997) and “tolerance for contradictions'' (Anzaldúa, 1987) as core moments of the
        development of a consciousness of a relational self and community and the base for a
        politics of solidarity which demystifies essentialist glorifications of unity and purity (see p.
        37 - XOIR). Other disciplines use hybridity as an epistemological tool – to know hybridly
        (Wilson, 2009) – to negotiate and go beyond different binary oppositions, as the one
        between natural and social realms (Latour, 1991) or between human, animal and
        machine (Haraway, 1985).<br /><br />

        These interactive processes create hybrid creatures, cyborgs, monsters, ghosts,
        holobionts (see p.29 - Kids Workshop).<br />
        Among the long list of imaginary, real, conceptual or concrete hybrids, there are
        chimeras, mythical creatures compounded of incongruous parts (but also,
        metaphorically, illusions fabricated by the mind) and there is Metis, an ancient Greek
        nymph with a beautiful homonymity to métis (from which the term métissage derives),
        embodiment of wisdom and cunning intelligence, who could change her shape at will.
        There is the cyborg explored by Donna Haraway, a “spatiohistoric product of the
        boundary implosions of organism/machine, human/animal, and virtual/physical, the result
        being a kind of disassembled and reassembled postmodern collective self in which we
        are all implicated” (Lewis, 2020). And there is the phoenix, who “participates in
        heterogeneous regions of existence: plant and animal world, (...) dry high ground and the
        watery abyss” (Marder, 2023). Hybrids occupy multiple dimensions, from the hyper-small
        scale of microbial collaborations, as in the holobiont, theorised by Scott F. Gilbert (2017)
        as “an organism plus its persistent communities of symbionts”, to the world
        encompassing theory of Gaia developed by Lynn Margulis and James Lovelock which
        states that the “Earth, in the biological sense, has a body sustained by complex
        physiological processes” (1998).<br /><br />

        All these theoretical concepts and imaginary creatures open us to different ways of
        conceptualising subjects, from individual to coalitional, beyond our “specific-species
        arrogance” (Margulis, 1998) – or hubris, we could say – and more generally against the
        “conceit of the Individual” (Tsing, 2017), “shifting from the notion of identity that begins
        with ‘I’ – as does the inscription ‘I-dentity’, which announces ‘I am...’ – to a sense of ‘self’
        that is radically inclined toward others” (Carillo Rowe, 2005).<br /><br />

        And so, what forms of natureculture hybrids can the basin hold, host and co-produce?
        What are the hybrids we are inclined towards and which we share the basin with? How
        can the cohabitation with the Hydra-reed, who became a ghost when forcefully removed,
        and whose body is now re-formulating, re-mutating on the floor of the basin, help us to
        imagine a multi-species and collaborative way of organising within the basin? (see p. 21
        - Inside the Ballona Waachnga).<br /><br />

        <p className='underline'>Eco-organise!</p>

        The wish with eco-organising is to go beyond the management of ecosystems or
        the organising of ecologically-minded events – although both are for a large part
        still what Climate Care aims to carry out. To add to these, Climate Care also
        provides an opportunity to research eco-organising as the re-embedding of natural
        and ecological logics and processes into the way the Floating association might
        view, relate, communicate and work internally with the basin site. This might mean
        growing an awareness of how workflows entangle, how different processes inside
        the Floating association reflect and affect each other, how the association group
        moves together, how individuals inside the group are listened to, and what all of
        those processes have to learn from natural phenomena. Through this process of
        observation, the association is growing aware of how to flock, swarm, diverge,
        climb, ferment and compost together. For the re-embedding of ecological
        processes into eco-organising, there is a need to take the time to observe how
        plants, sediment and architecture might co-habit and help each other survive on
        the water-basin site. To support this, Climate Care provides a moment to consider
        how to do less prep and be more present (brown, 2017), how to view actions
        holistically, how to observe, follow and harness existing collective patterns (see p.
        33 - Staying with the Darkness). Keeping these intentions in mind, the festival
        offers a condensed experiment with various forms of organising which infuse the
        natural into the social, the systemic and the infrastructural.
      </div><br />

      <div>
      <p className='underline'>Bibliography</p>

        Gloria Anzaldúa, Borderlands / La Frontera: The New Mestiza, 1987 <br /><br />
        Jean Bernabé, Patrick Chamoiseau, Raphaël Confiant and Mohamed B. Taleb Khyar, In
        Praise of Creolness, 1990<br /><br />
        Rosi Braidotti, “Becoming-World: a New Perspective on European Citizenship” in Bas
        Lafleur, Wietske Maas, Susanne Mors (eds.), Courageous Citizens, How Culture
        Contributes to Social Change, 2018<br /><br />
        Adrienne maree brown, Emergent Strategy - shaping change, changing worlds, 2017<br /><br />
        Marisol de la Cadena, Mario Blaser (eds.), A World of Many Worlds, 2018<br /><br />
        Camille de Toledo, Le fleuve qui voulait écrire, les auditions du parlement de Loire, 2021<br /><br />
        Silvia Federici, Re-enchanting the World: Feminism and the Politics of the Commons,
        2019<br /><br />
        Coco Fusco, “The Border Art Workshop / Taller de Arte Fronterizo, Interview with
        Guillermo Gómez-Peña and Emily Hicks” in Third Text 3 (7), 1989<br /><br />
        Scott F. Gilbert, “Holobiont by Birth: Multilineage Individuals as the Concretion of
        Cooperative Processes”, in Anna L. Tsing et al (eds.), The Art of Living on a Damaged
        Planet, 2017<br /><br />
        Édouard Glissant, Poetics of Relation, 1990<br /><br />
        Édouard Glissant, Traité du Tout-Monde, 1997<br /><br />
        Donna Haraway, “A Manifesto for Cyborgs: Science, Technology and Socialist Feminism
        in the 1980s” in Socialist Review 80, 1985<br /><br />
        Bruno Latour, We Have Never Been Modern, 1991<br /><br />
        John Law, “What's Wrong with a One-World World”, Heterogeneities.net, 2012<br /><br />
        Sophie Lewis, “Hybridity and the Cyborg” in International Encyclopedia of Human
        Geography (Second Edition), 2020<br /><br />
        Peter Linebaugh, The Magna Carta Manifesto - Liberties and Commons for All, 2008<br /><br />
        Jeremy Lurgio, “Saving the Whanganui: can personhood rescue a river?”, in The
        Guardian, 29.11.2019<br /><br />
        Michael Marder, The Phoenix Complex, 2023<br /><br />
        Lynn Margulis, Symbiotic Planet: a new look at evolution, 1998<br /><br />
        Francisca Massardo, Ricardo Rozzi, “The road to biocultural ethics” in Frontiers in
        Ecology and the Environment, 2011<br /><br />
        Nancy Morejon, Nacion y mestizaje en Nicolas Guillen, 1982<br /><br />
        Bonaventure Soh Bejeng Ndikung, Pidginization as Curatorial Method: Messing with
        Languages and Praxes of Curating, 2023<br /><br />
        Aimee Carillo Rowe, “Be Longing: Towards a Feminist Politics of Relation” in NWSA
        journal 17 (2), 2005<br /><br />
        Mihnea Tănăsescu, Environment, Political Representation, and the Challenge of Rights :
        Speaking for Nature, 2016<br /><br />
        Anna L. Tsing, The Art of Living on a Damaged Planet, 2017<br /><br />
        Françoise Vergès, Monsters and Revolutionaries: Colonial Family Romance &
        Métissage, 1999<br /><br />
        Vaness Watts, “Indigenous place-thought and agency amongst humans and non humans
        (First Woman and Sky Woman go on a European world tour!)” in Decolonization:
        Indigeneity, Education & Society 2(1), 2013<br /><br />
        Sarah Whatmore, Hybrid Geographies: Natures, Cultures, Spaces, 2002<br /><br />
        Matthew W. Wilson, “Cyborg geographies: toward hybrid epistemologies” in Gender,
        Place & Culture: A Journal of Feminist Geography 16 (5), 2009<br />
      </div>
    </div>,
    fonts: 2,

  },
  //3
  {
    title: <div>Listening to Reeds</div>,
    author: <div>Zakole</div>,
    category: 'Sound Exploration',
    caption: <div>
      <div>
        Join us to listen to a piece composed of field recordings from Zakole and sounds from the
        never-empty-space inside the plant’s tubular stalks, searching for what is sounding in the air
        they hold. The sound piece is an invitation to listen to a distant sister site, to imagine, navigate,
        and learn about it through sound.</div><br />
      <div>
        Zakole is a collective project dedicated to a wetland located almost in the very centre of
        Warsaw. Zakole Wawerskie encompasses both a picturesque, mostly inaccessible swamp and
        extensive meadows.
      </div>
    </div>,
    albums: [
      {
        cover: abs('21/1_Listening-to-Reeds_thumbnail.jpg'),
        thumbnail: abs('21/1_Listening-to-Reeds_thumbnail.jpg'),
        tracks: [
          { id: '01', title: 'Reeds - Zakole & Zaumne', url: abs('21/Reeds-Zakole_&_Zaumne.mp3') },
        ]
      }
    ],
    date: '23.09.2023 19:00',
    fonts: 1,

  },
  //4
  {
    title: <div>Inside the<br />Ballona/Waachnga:<br /> A visual exploration</div>,
    author: <div>Halina Kliem</div>,
    caption: <div><div>
      Inside the Ballona/Waachnga: A Visual Exploration intends to establish a site-specific
      relationship between the two watery ecologies: Floating and the Ballona Wetlands in Los
      Angeles. Three outdoor screens turn on at dusk. The projected images capture the lifecycle of
      wildflowers from various perspectives through the seasons at Ballona.
    </div><br />
      <div>
        Halina Kliem is an interdisciplinary artist born and raised in the divided city of Berlin. She uses a
        combination of media, including video, photography, and the written word.
      </div></div>,
    gallery: [
      {
        url: abs('6/1_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/1_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/2_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/2_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/3_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/3_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/5_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/5_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/6_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/6_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/7_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/7_Inside-the-Ballona.jpg'),
      },
      {
        url: abs('6/8_Inside-the-Ballona.jpg'),
        thumbnail: abs('6/8_Inside-the-Ballona.jpg'),
      },
    ],
    category: 'Installation',
    fonts: 1,
  },
  //5
  {
    title: <div>Hybrid Creatures</div>,
    author: 'Hybrid Gaze',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Hybrid Creatures_LOW.pdf'),
    },
    category: 'Reading',
    date: '21.09.2023 17:00',
    fonts: 3,
  },
  //6
  {
    title: <div>Schedule</div>,
    author: <div>Festival Programme</div>,
    pdf: {
      url: abs('pdfs/ClimateCare_Schedule2023.pdf'),
    },
    fonts: 2,
  },

  //7
  {
    title: <div>Milpa: <br />Learning from corn,<br /> bean and squash</div>,
    author: <div>Lorene Blanche<br /> Alizée Sérazin<br /> Rosario Talevi</div>,
    caption: <div>
      <div>
        The term “milpa” comes from the Nahuatl language (“milli,” sown field, and “pan,” on top of).
        Also known as the “three sisters”, the Mesoamerican agro-ecological unit embodies ideas of
        hybridity, reciprocity and interdependence. It is a pre-colonial subsistence system that not only
        offers an alternative to eurocentric monocultures, but introduces a cultural contrast: milpa as a
        divergent worldview, diverse, plural, interconnected in difference.
      </div>
      <br />
      <div>
        Alizée Sérazin is an architect and self-taught builder.
        Lorene Blanche Goesele is a trans-disciplinary artistic practitioner.
        Rosario Talevi is an architect, curator, editor and educator.
      </div>
    </div>,
    gallery: [
      {
        url: abs('5/01_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/01_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/02_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/02_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/03_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/03_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/04_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/04_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/05_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/05_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/06_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/06_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/07_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/07_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/08_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/08_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
      {
        url: abs('5/09_Milpa-Learning-from-corn-bean-and-squash.jpg'),
        thumbnail: abs('5/09_Milpa-Learning-from-corn-bean-and-squash.jpg'),
      },
    ],
    date: 'Spring-Summer 2023 Garden',
    fonts: 1,
  },
  //8
  {
    title: <div>The Basin <br />Case Study :<br /> On Coexistence</div>,
    author: <div>Marjetica Potrč</div>,
    caption: <div>
      <div>
        This visual essay presents the changing relationship between people and nature using the case
        study of the rainwater retention basin at Berlin’s former Tempelhof Airport, which in recent years
        has become an experimental hybrid space.
      </div>
      <div>
        2023, ink on paper, series of 11 drawings
        Copyright the artist, courtesy of the artist and Galerie Nordenhake Berlin/Stockholm/Mexico City
      </div><br />
      <div>
        Marjetica Potrč is an artist and architect based in Ljubljana. Her multidisciplinary practice
        includes drawings, architectural case studies and public art projects merging art, architecture,
        ecology and anthropology.
      </div><br />
    </div>,
    gallery: [
      {
        url: abs('2/1_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/1_The-Basin-Case-Study.jpg'),
      },
      {
        url: abs('2/2_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/2_The-Basin-Case-Study.jpg'),
      },
      {
        url: abs('2/3_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/3_The-Basin-Case-Study.jpg'),
      },
      {
        url: abs('2/4_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/4_The-Basin-Case-Study.jpg'),
      },
      {
        url: abs('2/5_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/5_The-Basin-Case-Study.jpg'),
      },
      {
        url: abs('2/6_The-Basin-Case-Study.jpg'),
        thumbnail: abs('2/6_The-Basin-Case-Study.jpg'),
      },
    ],
    fonts: 2,
  },
  //9
  {
    title: <div>Milpa Readings</div>,
    author: <div>Gabriela Aquije<br /> Lorene Blanche <br />Rosario Talevi</div>,
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Milpa readings_LOW.pdf'),
    },
    category: 'Reading',
    date: '30.09.2023 17:00',
    fonts: 3,
  },

  //10
  {
    title: <div>Harvesting Lumbung </div>,
    author: <div>ruangrupa</div>,
    caption:
      <div> <div>
        Lumbung is the Indonesian word for a communal rice-barn, where the surplus harvest is stored
        for the benefit of the community. The lumbung practice enables an alternative economy of
        collectivity, shared resource building and equitable distribution.
      </div><br />
        <div>
          Ruangrupa continues this tradition in its own practice, and the artists, collectives,
          organisations and activists they work with also share their means such as time, space, money, care, ideas, and knowledge.
        </div>
      </div>,
    gallery: [
      {
        url: abs('16/1_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/1_Harvesting-Lumbung.jpg'),
      },
      {
        url: abs('16/2_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/2_Harvesting-Lumbung.jpg'),
      },
      {
        url: abs('16/3_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/3_Harvesting-Lumbung.jpg'),
      },
      {
        url: abs('16/4_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/4_Harvesting-Lumbung.jpg'),
      },
      {
        url: abs('16/5_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/5_Harvesting-Lumbung.jpg'),
      },
      {
        url: abs('16/6_Harvesting-Lumbung.jpg'),
        thumbnail: abs('16/6_Harvesting-Lumbung.jpg'),
      },
    ],
    category: 'Workshop',
    date: '28.09.2023 14:00',
    fonts: 1,
  },

  //11
  {
    title: <div>Architecture<br /> is Climate</div>,
    author: 'Mould',
    category: 'Workshop',
    date: '23.09.2023 14:00',
    text: <div>
      <div>
        The research project Architecture is Climate entangles architecture with the conditions of climate
        breakdown. For too long architecture has stood outside climate, seeing it as a problem to be
        fixed through technocratic intervention. Architecture as part of the modern constitution carries a
        dualistic view of the world: humans and non-humans, nature and technology, culture and
        science. Our lives and societies are structured around this attitude. But what if, as Bruno Latour
        argues, we have been completely wrong? What if this polarity has never existed?
        <br /> <br /></div><div>
        Architectures and climates are not separate entities brought together in orchestrated moments.
        Instead, they are conditions that are produced through one another. Without the pretence of a
        stable discipline producing fixed objects, architecture becomes part of a febrile and disrupted
        world, vulnerable to its contingencies. No longer standing outside and applying superficial
        patches to the wounds of climate, Architecture is Climate binds the discipline and its humans to
        the scars, violence, and emotions of climate breakdown.
        We cannot continue to ask the normative question, ‘What can architecture do for climate
        breakdown?’ Instead, we must ask, ‘What does climate breakdown do to architecture?’
        Building upon this work, we propose a conversational encounter where we delve deeper into the
        entanglement of climate and architecture, discussing possible futures.
      </div> <br />
      <div className='text-faro'>
        Mould is a research collective operating at the intersection of spatial practice and climate
        breakdown. It originated from the collaboration between Central Saint Martins in London and TU
        Braunschweig. Its current members are: Sarah Bovelett, Anthony Powis, Tatjana Schneider,
        Christina Serifi, Jeremy Till, Becca Voelcker.  </div>
    </div>,
    fonts: 1,
  },

  //12
  {
    title: <div>Geopolitics and the Anthropocene</div>,
    author: 'Zakole',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Geopolitics-and-the-Anthropocene.pdf'),
    },
    category: 'Reading',
    date: '23.09.2023 17:00',
    fonts: 3,
  },
  //13
  {
    title: <div>XOIR</div>,
    author: 'Colin Self',
    caption: <div>
      <div>
        Xoir (pronounced “choir”) is a vocal workshop.Typically used as a tool for experimentation and
        exploration in group settings, its focus lies on somatic awareness and collective organising, as
        opposed to memorisation and reciting of sheet music.
      </div><br />
      <div>
        Colin Self is an artist. They compose and choreograph music, performance and environments
        for expanding consciousness, troubling binaries and boundaries of perception and
        communication.
      </div>
    </div>,
    gallery: [
      {
        url: abs('27/1_XOIR.jpg'),
        thumbnail: abs('27/1_XOIR.jpg'),
      },
      {
        url: abs('27/2_XOIR.jpg'),
        thumbnail: abs('27/2_XOIR.jpg'),
      },
      {
        url: abs('27/3_XOIR.jpg'),
        thumbnail: abs('27/3_XOIR.jpg'),
      },
      {
        url: abs('27/4_XOIR.jpg'),
        thumbnail: abs('27/4_XOIR.jpg'),
      },
      {
        url: abs('27/5_XOIR.jpg'),
        thumbnail: abs('27/5_XOIR.jpg'),
      },
      {
        url: abs('27/6_XOIR.jpg'),
        thumbnail: abs('27/6_XOIR.jpg'),
      },
      {
        url: abs('27/7_XOIR.jpg'),
        thumbnail: abs('27/7_XOIR.jpg'),
      },
      {
        url: abs('27/8_XOIR.jpg'),
        thumbnail: abs('27/8_XOIR.jpg'),
      },
    ],
    category: 'Vocal Workshop',
    date: '30.09.2023 20:30',
    fonts: 1,
  },

  //14
  {
    title: <div>With the Mosses</div>,
    author: <div>Jacky Hess<br /> Eva-Fiore Kovacovsky<br /> Lucy Powell<br /> Susanne Jaschko</div>,
    caption: <div><div>
      Far from romanticised ideas of soft moss covering rocks in the forest, at Floating moss grows
      over rusted steel structures, in symbiosis with decaying concrete or polluted water. This
      workshop is an immersive experience in the world of moss.</div><br />
      <div>
        Jacky Hess is a evolutionary biologist and enthusiast of all things small and ephemeral.
        Eva-Fiore Kovacovsky is an artist working with plant specimens and analogue photography.
        Lucy Powell is an artists whose research-based practice is part of a wider ontological inquiry
        into more-than-human nature.
        Dr Susanne Jaschko works as a cultural manager and curator.
      </div></div>,
    gallery: [
      {
        url: abs('10/1_With-the-Mosses.jpg'),
        thumbnail: abs('10/1_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/2_With-the-Mosses.jpg'),
        thumbnail: abs('10/2_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/3_With-the-Mosses.jpg'),
        thumbnail: abs('10/3_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/4_With-the-Mosses.jpg'),
        thumbnail: abs('10/4_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/5_With-the-Mosses.jpg'),
        thumbnail: abs('10/5_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/6_With-the-Mosses.jpg'),
        thumbnail: abs('10/6_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/7_With-the-Mosses.jpg'),
        thumbnail: abs('10/7_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/8_With-the-Mosses.jpg'),
        thumbnail: abs('10/8_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/9_With-the-Mosses.jpg'),
        thumbnail: abs('10/9_With-the-Mosses.jpg'),
      },
      {
        url: abs('10/10_With-the-Mosses.jpg'),
        thumbnail: abs('10/10_With-the-Mosses.jpg'),
      },
    ],
    date: '22.09.2023 14:00',
    category: 'Workshop',
    fonts: 1,
  },
  //15
  {
    title: <div> How to Zoöp?</div>,
    author: <div>Klaas Kuitenbrouwer<br />Rasa Weber</div>,
    text: <div>
      <div>
        Zoöp is short for zoöperation: cooperation with zoë, Greek for ‘life’. Zoöp is an organisational
        model for cooperation between humans and other-than-human life that serves the interests of all
        life. The Zoöp model makes the interests of other-than-human life part of an organisations
        decision-making process. Zoöps work on the continuous improvement of their own ecological
        integrity, by following the structured learning process of the zoönomic annual cycle. They strive
        to become symbiotic with the ecosystems in which they participate, and they work together to
        create a regenerative economy, or human-inclusive ecosystem, or zoönomy,- a network of
        exchange of matter, energy and meaning that supports all life. The Zoöp model was developed
        at Het Nieuwe Instituut by a large group of ecologists, legal experts, artists, designers,
        entrepreneurs and philosophers.
        <br /><br /></div>
      <div>
        The workshop How to Zoöp? will introduce participants to the what and why of the Zoöp model
        and the zoönomic annual cycle. Through a collaborative case study of Floating University,
        participants will investigate and map the ecological integrity of Floating University; identify
        various regenerative and degenerative relations it participates in and will explore the possibilities
        for interventions in the relational web of Floating University that would contribute to its ecological
        integrity.
      </div><br />
      <div className='text-faro'>
        Klaas Kuitenbrouwer is senior researcher at Het Nieuwe Instituut in Rotterdam and teaches
        theory at the Gerrit Rietveld  </div></div>,
    category: 'Workshop',
    date: '23. + 24.09.2023 14:00',
    fonts: 1,
  },

  //16
  {
    title: <div>What is a River?</div>,
    windowTitle: <div>What is a River: Are Rights of Nature an answer to extractivism?</div>,
    author: <div>Elizabeth Gallón Droste<br /> Dr.Christiane Gerstetter<br /> Sina Ribak<br /> Zabriskie</div>,
    caption: <div>
      <div>
        Is legal personhood a tool to protect the rights and wellbeing of entities of more-than-human-
        nature? Are “rights of nature” able to halt destructive profit-driven extractivist actions? Or is the granting of rights yet another anthropocentric move? What can we learn in this respect from
        Indigenous thinking?
      </div>
      <br />
      <div>
        Elizabeth Gallón Droste conducts multimodal, artistic research.
        Dr. Christiane Gerstetter is a lawyer. She works in the Berlin office of the international
        environmental law charity ClientEarth.
        Lorena Carràs is co-founder and co-curator of the Zabriskie bookstore in Berlin.
        Jean-Marie Dhur is co-founder and co-curator of the Zabriskie bookstore in Berlin.
        Sina Ribak is an independent researcher for ecologies and the arts.<br />
        <br />
      </div>
    </div>,
    albums: [
      {
        cover: abs('26/1a_What_is_a_river_thumbnail.jpg'),
        thumbnail: abs('26/1a_What_is_a_river_thumbnail.jpg'),
        tracks: [
          { id: '01', title: '~pes(Elizabeth Gallon Droste & Pablo Gómez Torres)', url: abs('26/pes-leakages.mp3') },
        ]
      }
    ],
    category: 'Conversation',
    date: '30.09.2023 19:00',
    fonts: 1,
  },
  //ROW 3
  //17
  {
    title: <div>Care Income:<br /> A more breathable<br /> world</div>,
    author: 'Elisa Bosisio',
    caption: <div>
      <div>
        How can we heal the present, keeping in mind that our bodies, those of others and the
        nonhuman world are produced by thresholds and not boundaries? This workshop aims to reflect
        collectively on our human condition and that of the environments we inhabit in the present days
        of the reproductive crisis.
      </div>
      <br />
      <div>
        Elisa Bosisio is an Italian feminist activist and a PhD candidate in Political Philosophy at the
        University of Roma Tre.
      </div>
    </div>,
    gallery: [
      {
        url: abs('13/01_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/01_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/02_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/02_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/03_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/03_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/04_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/04_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/05_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/05_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/06_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/06_Care-Income-A-more-breathable-world.jpg'),
      },
      {
        url: abs('13/07_Care-Income-A-more-breathable-world.jpg'),
        thumbnail: abs('13/07_Care-Income-A-more-breathable-world.jpg'),
      },
    ],
    category: 'Workshop',
    date: '23.09.2023 14:00',
    fonts: 1,
  },

  //18
  {
    title: <div>The Phoenix Complex</div>,
    author: 'Michael Marder',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_The-Phoenix-Complex_LOW.pdf'),
    },
    category: 'Reading',
    date: '26.09.2023 17:00',
    fonts: 3,
  },
  //19
  {
    title: <div>Disturbance Theory<br /> and Contaminations<br /> Practice</div>,
    author: <div>Katherine Ball<br /> Cilia Hermann</div>,
    caption: <div><div>
      Floods, forest fires, hurricanes, tornadoes, earthquakes, landslides, volcanic eruptions, lightning
      strikes, windstorms, fir waves, pests and disease. How can we grow to understand disturbances
      are a part of the ecosystems we live in and part of us? How can we hybridise with disturbances,
      rather than separating disturbances as foreign, other or alien? This session is about
      appreciating the ecological roles of disturbance.
    </div><br />
      <div>
        Katherine Ball is a habitat for fungi and bacteria located on planet Earth.
        Cilia Hermann is performing and dancing.
      </div>
    </div>,
    gallery: [
      {
        url: abs('14/1_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/1_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/2_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/2_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/3_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/3_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/4_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/4_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/5_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/5_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/6_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/6_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
      {
        url: abs('14/7_Disturbance-Theory-and-Contaminations-Practice.jpg'),
        thumbnail: abs('14/7_Disturbance-Theory-and-Contaminations-Practice.jpg'),
      },
    ],
    category: 'Workshop',
    date: '27.09. 2023 14:00',
    fonts: 1,

  },
  //20
  {
    title: <div>The Parkmeister*in</div>,
    author: 'PARKS',
    caption: <div>
      Since its beginning in 2019, the PARKS project has been located on a former recycling yard in
      the east of Hamburg.The Parkmeister*in (steward of the park) is the moderator between
      caretakers and municipal representatives, between ecology and use. This workshop uses
      methods from organisational development, concepts from psychoanalysis, feminist perspectives
      and experiences from practice to discuss the role of the Parkmeister*in or “gentle” curator.
      <br /><br />
      Franziska Dehm is an urban designer and researcher moving between urban design, urban
      planning and architecture.
      Johanna Padge is a designer and master carpenter. As a designer, she is interested in both
      social and built architecture.
      Nuriye Tohermes conceptualises and realises participatory and/or collective, site specific
      projects as critical spatial practice.
    </div>,
    gallery: [
      {
        url: abs('15/1_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/1_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/2_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/2_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/3_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/3_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/4_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/4_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/5_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/5_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/6_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/6_The-Parkmeister_in.jpg'),
      },
      {
        url: abs('15/7_The-Parkmeister_in.jpg'),
        thumbnail: abs('15/7_The-Parkmeister_in.jpg'),
      },
    ],
    date: '27.09.2023 14:00',
    category: 'Workshop',
    fonts: 1,

  },
  //21
  {
    title: <div>Wild Tongues</div>,
    author: 'Vida Rucli',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Wild-Tongues_LOW.pdf'),
    },
    category: 'Reading',
    date: '27.09.2023 17:00',
    fonts: 3,
  },
  //22
  {
    title: <div>Hybrid Gaze</div>,
    author: <div>Garance Maurer<br />Jeanne Astrup-Chauvaux<br />Jöran Mandik <br />Katherine Ball</div>,
    caption: <div>
      <div>
        Hybrid Gaze is a subgroup of the Hybrid Infrastructure working group at Floating e.V.
        Association. During Climate Care 2023, Hybrid Gaze participated in the festival’s program to
        absorb and digest the knowledge brought by the various artists and thinkers, and share it back
        to the association and the basin, where it informs the ongoing process of hybrid visioning and
        transformation of the site.</div><br />
      <div>
        Jeanne Astrup-Chauvaux is a human being with a practice situated at the intersection of
        architecture, filmmaking, performance and urban practice.
        Jöran Mandik is an urban practitioner and researcher as well as a facilitator, cultural producer
        and podcaster.
        Garance Maurer is a designer and transdisciplinary artist, specialized in textiles.
        Katherine Ball is a habitat for fungi and bacteria located on planet Earth.
      </div><br/>
      <div className='pdf'>
      <a href='https://online.fliphtml5.com/zcglf/onit/' target='_blank'>Download Report</a>
      </div>
      </div>,
    gallery:[
      {
        url: abs('22/hg0.jpg'),
        thumbnail: abs('22/hg0.jpg'),
      },
      {
        url: abs('22/hg1.jpg'),
        thumbnail: abs('22/hg1.jpg'),
      },
      {
        url: abs('22/hg2.jpg'),
        thumbnail: abs('22/hg2.jpg'),
      },
      {
        url: abs('22/hg3.jpg'),
        thumbnail: abs('22/hg3.jpg'),
      },
      {
        url: abs('22/hg4.jpg'),
        thumbnail: abs('22/hg4.jpg'),
      },
    ],
    category: 'Working Group',
    fonts: 1,
  },
  //23
  {
    title: <div>Let’s Become<br /> Fungal!</div>,
    author: <span>Yasmine Ostendorf -<br/>Rodríguez</span>,
    caption: <div>
      <div>
        A workshop to activate the way of fungal thinking and being, through twelve questions. How
        does mycelium provides concrete inspiration for eco-governance? How do we move from a
        mindset of extraction to one of symbiosis? How do we design fair systems for collective
        decision-making processes? What do we change in our language to become more fungal? How
        do we decentralise power in our organisations?</div><br />
      <div>
        Yasmine Ostendorf-Rodríguez, born in Amsterdam and based in Mexico City, is a curator, writer,
        and researcher working at the intersection of art and ecology.
      </div>
    </div>,
    gallery: [
      {
        url: abs('17/1_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/1_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('17/2_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/2_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('17/3_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/3_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('17/4_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/4_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('5_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/5_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('17/6_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/6_Let_s-Become-Fungal.jpg'),
      },
      {
        url: abs('7_Let_s-Become-Fungal.jpg'),
        thumbnail: abs('17/7_Let_s-Become-Fungal.jpg'),
      },
    ],
    category: 'Workshop',
    date: '29.09.2023 14:00',
    fonts: 1,
  },

  //24
  {
    title: <div>Creatures, Monsters, Cyborgs!</div>,
    author: 'Studio YUKIKO',
    category: 'Kids Workshop',
    caption: <div>
      <div>
        Studio YUKIKO invites children from age five and upwards, with their care-takers, to come and
        play with the leftover visual material of the festival (Newspapers, Posters). By turning soon to be
        old memories into masks, costumes and props children and adults alike become frisky, frolicky,
        floating hybrid creatures, monsters, cyborgs, and more!
      </div><br />
      <div>
        Studio Yukiko is a Berlin-based creative studio specialising in creative direction, art direction,
        brand strategy and graphic design for cultural and commercial clients.
      </div>
    </div>,
    gallery: [
      {
        url: abs('18/1_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/1_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/2_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/2_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/3_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/3_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/4_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/4_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/5_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/5_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/6_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/6_Creatures-Monsters-Cyborgs.jpg'),
      },
      {
        url: abs('18/7_Creatures-Monsters-Cyborgs.jpg'),
        thumbnail: abs('18/7_Creatures-Monsters-Cyborgs.jpg'),
      },
    ],
    date: '30.09.2023 15:00',
    fonts: 3,

  },
  //25
  {
    title: <div>Turning the Collar</div>,
    author: <div>Teresa Dillon<br /> Marek Tuszynski</div>,
    text: <div>
      <div>
        Reflecting on our material relations with objects, through processes of fixing and mending.
        'Turning the Collar' (Ireland, 2022) takes the form of a road trip around the rural county of
        Westmeath, Ireland. The screening is followed by a conversation with director, Teresa Dillon,
        and the co-founder of Tactical Tech, Marek Tuszynski on the intersection of artistic practices,
        repair cultures, policy and legislation.</div><br />
      <div className='text-faro'>
        Teresa Dillon is an artist and researcher whose work explores relationships between humans,
        other species, technology, cities and our environments.
        Marek Tuszynski is the Executive Director and co-founder of Tactical Tech. Marek has been
        working at the nexus of technology and politics, information and activism and the consequences
        of living in a quantified society.
      </div>
    </div>,
    url: 'https://vimeo.com/769356497',
    type: 'vimeo',
    category: 'Film and Conversation',
    date: '22.09.2023 19:00',
    fonts: 1,

  },

  //26
  {
    title: <div>Staying with<br /> the Darkness</div>,
    windowTitle: <div>Staying with the Darkness: Notes on urban light pollution</div>,
    author: <div>Alona Rodeh<br /> Elise Borges<br /> Bill Green</div>,
    caption: <div>
      <div>
        In the nocturnal city, marginalised communities might find shelter, bohemians search for
        meaning and foxes hunt for their next meal. Entire ecosystems thrive. Giving a chance for
        immersion in darkness under moderated conditions, this performative presentation and night
        walk in the surrounding of Floating focuses on demonstrating the four main types of light
        pollution: glare, skyglow, light trespass and clutter.
      </div><br />
      <div>
        Alona Rodeh is an Israeli Berlin-based visual artist. Her ongoing series of urban night walks
        (2018–) highlights the theme of high-intensity illumination.
        Elise Borges is a mental health councillor and Bill Green is an optical engineer. But at night,
        they bring their telescopes to the city center to share views of the night sky with people passing
        by.
      </div>
    </div>,
    gallery: [
      {
        url: abs('20/1_Staying-with-the-darkness.jpg'),
        thumbnail: abs('20/1_Staying-with-the-darkness.jpg'),
      },
      {
        url: abs('20/2_Staying-with-the-darkness.jpg'),
        thumbnail: abs('20/2_Staying-with-the-darkness.jpg'),
      },
      {
        url: abs('20/3_Staying-with-the-darkness.jpg'),
        thumbnail: abs('20/3_Staying-with-the-darkness.jpg'),
      },
      {
        url: abs('20/4_Staying-with-the-darkness.jpg'),
        thumbnail: abs('20/4_Staying-with-the-darkness.jpg'),
      },
    ],
    category: 'Walk and Conversation',
    date: '22.09.2023 20:30',
    fonts: 1,

  },
  //27
  {
    title: <div className='adjust'>The Real <br /> Body of <br />Life <em>&</em> Death</div>,
    windowTitle: <div>The Real Body of Life & Death: Mats, Bacteria, Sun and Love</div>,
    author: 'Between Us and Nature',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_The-Real-Body-of-Life-&-Death_LOW.pdf'),
    },
    category: 'Reading',
    date: '22.09.2023 17:00',
    fonts: 3,
  },
  //28  
  {
    title: <div className='cinecologies'>Cinecologies</div>,
    windowTitle: <div>Cinecologies: Utopian visions</div>,
    author:<div className='cinecologies2'>SiNEMA TRANSTOPIA</div>,
    text: <div>
      <div>
        Cinecologies brings together a range of cinematic approaches which complicate the ways in
        which we engage with the world. Departing from anthropocentric perspectives, these films tackle
        more-than-human relations, using experimental hybrid aesthetic and narrative forms to explore
        our ecological past, present and future.<br /><br />

        Aequador (Laura Huertas Millán, Colombia / France, 2012, 19 minutes, Spanish with English
        subtitles) is an alternative history built as an account of travelling along the Amazon river. In the
        words of the filmmaker, the film presents ‘a parallel present modified by virtual reality, an oneiric
        allegory, an uchronic dystopia’. A science fiction documentary, it contemplates colonisation,
        former utopias in the Latin America forests and their cohabitation with the present.<br /><br />

        Black Pond (Jessica Sarah Rinland, United Kingdom, 2018, 43 minutes, English) explores the
        activity of the members of the Natural History Society, who inhabit a common land in the south
        of England (previously occupied by the 17th century agrarian socialists, the Diggers) and
        engage in the meticulous activities of measuring, recollection, analysis and cohabitation with the
        natural world. A voiceover creates another layer of meaning, as it gathers together memories
        and responses of the Society members when the footage was shown to them after two years of
        filming on the land. The film invites us to listen with our own eyes to achieve a further
        understanding of human’s relationship with land and nature.<br /><br />

        In Maddi Barber’s Paraiso (Maddi Barber, Spain, 2021, 22 minutes, Spanish with English
        subtitles), a forest of pines sends messages to the humans who have gathered beneath their
        branches. The machines are coming and the woodland is reimagined as a sea of digital dots.
        Soon they may be cut down; this place has always been called paradise.<br /><br />

        Larissa Sansour and Søren Lind’s In Vitro (Larissa Sansour/Søren Lind, Palestine / Denmark /
        United Kingdom, 2019, 28 minutes, Arabic with English subtitles) catapults us forward to a place
        beyond catastrophe, an abandoned nuclear reactor below Bethlehem in which scientists are
        preparing to replant the soil above, in the wake of an eco-disaster.<br /><br />

        For This Place is a Message (Webb-Ellis, United Kingdom, 2022, 27 minutes, English with
        English subtitles), artists Webb-Ellis worked collaboratively with young people to find a radical
        new audio-visual language to communicate impending environmental dangers to beings living
        24,000 years from now. This hybrid docu-fiction positions teenagers as prophets and seers,
        dancing, drawing and singing in an attempt to connect with possible post-human futures.<br /><br />

        Curated by Eirini Fountedaki and Rachel Pronger for SiNEMA TRANSTOPIA.
      </div><br />
      <div className='text-faro'>
        SİNEMA TRANSTOPIA by bi'bak explores cinema as a space of social discourse, exchange and
        solidarity.
        Eirini Fountedaki is a curator interested in embodied knowledge and collective reflections
        through film.
        Rachel Pronger is a writer, curator and researcher, and co-founder of the archive activist
        feminist film collective Invisible Women.
      </div>
    </div>,
    date: '24. + 28.09.2023 20:00',
    category: 'Film programme',
    fonts: 1,
  },
  //29
  {
    title: <div>In Due Time:<br /> A ritual against<br /> reproduction</div>,
    author: <div>Stefanie Wenner<br /> Michael Marder</div>,
    category: 'Lecture - Ritual',
    date: '26.09.2023 19:30',
    caption: <div>
      <div>
        Throughout this evening of talks, performances, and dialogues, at the time when the sun sets
        with the supposed promise of being reborn the next day, we will follow the tracks of a mythical
        creature, who is both solar and chthonic, namely the phoenix.
      </div><br />
      <div>
        Stefanie Wenner is a mother of three and a companion to a dog, an author and an artist.

        Michael Marder is IKERBASQUE Research Professor in the Department of Philosophy at the
        University of the Basque Country (UPV-EHU), Vitoria-Gasteiz, Spain.
      </div>
    </div>,
    gallery: [
      {
        url: abs('23/1_In-Due-Time.jpg'),
        thumbnail: abs('23/1_In-Due-Time.jpg'),
      },
      {
        url: abs('23/2_In-Due-Time.jpg'),
        thumbnail: abs('23/2_In-Due-Time.jpg'),
      },
      {
        url: abs('23/3_In-Due-Time.jpg'),
        thumbnail: abs('23/3_In-Due-Time.jpg'),
      },
      {
        url: abs('23/4_In-Due-Time.jpg'),
        thumbnail: abs('23/4_In-Due-Time.jpg'),
      },
      {
        url: abs('23/5_In-Due-Time.jpg'),
        thumbnail: abs('23/5_In-Due-Time.jpg'),
      },
      {
        url: abs('23/6_In-Due-Time.jpg'),
        thumbnail: abs('23/6_In-Due-Time.jpg'),
      },
      {
        url: abs('23/7_In-Due-Time.jpg'),
        thumbnail: abs('23/7_In-Due-Time.jpg'),
      },
    ],
    fonts: 1,
  },
  //30
  {
    title: <div>Hospitality</div>,
    author: <div>TDD</div>,
    caption: <div>
      <div>
        For the Climate Care Festival 2023, TDD cooked plant based seasonal meals with
        a twist for and with Climate Care artists and participants. You will find some of their
        "recipes for many“ in their book Flavours & Friends and in their yearly recipe
        calendar. Bon Appétit!</div> <br />
      <div>
        TDD is a supper club founded in 2007 by Gülsüm Güler & Inci Güler. The
        gatherings and culinary interventions mostly take place in temporary used
        buildings, where a restaurant atmosphere is created. The Team members are
        working in the field of art, food, design and education.
      </div>
    </div>,
    gallery: [
      {
        url: abs('29/1_TDD.jpeg'),
        thumbnail: abs('29/1_TDD.jpeg'),
      },
      {
        url: abs('29/2_TDD.jpeg'),
        thumbnail: abs('29/2_TDD.jpeg'),
      },
      {
        url: abs('29/3_TDD.jpg'),
        thumbnail: abs('29/3_TDD.jpg'),
      },
      {
        url: abs('29/4_TDD.jpg'),
        thumbnail: abs('29/4_TDD.jpg'),
      },
      {
        url: abs('29/5_TDD.jpg'),
        thumbnail: abs('29/5_TDD.jpg'),
      },
      {
        url: abs('29/6_TDD.jpg'),
        thumbnail: abs('29/6_TDD.jpg'),
      },
      {
        url: abs('29/7_TDD.jpg'),
        thumbnail: abs('29/7_TDD.jpg'),
      },
    ],
    fonts: 2,
  },
  //31
  {
    title: <div>Medka - Revisiting Animism</div>,
    author: <div>Gilly Karjevsky<br />Garance Maurer</div>,
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Medka-Revisiting Animism_LOW.pdf'),
    },
    category: 'Reading',
    date: '24.09.2023 17:00',
    fonts: 3,
  },
  //32
  {
    title: <div>Against Curing</div>,
    author: 'MELT',
    caption: <div>
      Curing can be defined as: solving a problem, relieving or eliminating a symptom, and also the process of
      hardening through vulcanisation or other chemical transformational processes. In this performative
      installation we pick up concrete as a material that takes time to become stable. In this way Against
      Curing positions itself as a performative work that interrupts hardening and invites reformulations through
      concrete, care and collaborations.
      <br /><br />
      MELT (Ren Loren Britton & Iz Paehr) study and experiment with shape-shifting processes as they meet
      technologies, sensory media and critical pedagogies in a warming world.
      <br /><br />
    </div>,
    gallery: [
      {
        url: abs('8/1_Against-Curing.jpg'),
        thumbnail: abs('8/1_Against-Curing.jpg'),
      },
      {
        url: abs('8/2_Against-Curing.jpg'),
        thumbnail: abs('8/2_Against-Curing.jpg'),
      },
      {
        url: abs('8/3_Against-Curing.jpg'),
        thumbnail: abs('8/3_Against-Curing.jpg'),
      },
      {
        url: abs('8/4_Against-Curing.jpg'),
        thumbnail: abs('8/4_Against-Curing.jpg'),
      },
      {
        url: abs('8/5_Against-Curing.jpg'),
        thumbnail: abs('8/5_Against-Curing.jpg'),
      },
      {
        url: abs('8/6_Against-Curing.jpg'),
        thumbnail: abs('2023/8/6_Against-Curing.jpg'),
      },
      {
        url: abs('2023/8/7_Against-Curing.jpg'),
        thumbnail: abs('2023/8/7_Against-Curing.jpg'),
      },
      {
        url: abs('8/8_Against-Curing.jpg'),
        thumbnail: abs('8/8_Against-Curing.jpg'),
      },
    ],
    date: '21.09.2023 18:00',
    category: 'Performance',
    fonts: 1,
  },

  //33
  {
    title: <div>Mycelium Teachings and the Arts</div>,
    author: <div>Yasmine Ostendorf-Rodríguez</div>,
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Lets-become-Fungal_LOW.pdf'),
    },
    category: 'Reading',
    date: '29.09.2023 17:00',
    fonts: 3,
  },

  //34
  {
    title: <div>Scenes from<br /> Contaminations 2022</div>,
    author: <div>Katherine Ball<br />Sebastian Díaz de León</div>,
    caption: <div>
      <div>
        Twenty performing artists and dancers embedded themselves in sites with different dynamics
        between nature and culture. Contaminations is an outdoor, experimental educational
        programme for people who want to work with their bodies. This short film shows scenes from
        Contaminations 2022.<br />
        Filmed and Installation design by Sebastian Díaz de León. Edited by Sebastian Díaz de León
        and Katherine Ball.
      </div><br />
      <div>
        Sebastian Díaz de León is an architect. He works independently with photography, film, paint
        and wood.

        Katherine Ball is a habitat for fungi and bacteria located on planet Earth.
      </div></div>,
    gallery: [
      {
        url: abs('24/1_Scenes-from-Contaminations-2022.jpg'),
        thumbnail: abs('24/1_Scenes-from-Contaminations-2022.jpg'),
      },
      {
        url: abs('24/2_Scenes-from-Contaminations-2022.jpg'),
        thumbnail: abs('24/2_Scenes-from-Contaminations-2022.jpg'),
      },
      {
        url: abs('24/3_Scenes-from-Contaminations-2022.jpg'),
        thumbnail: abs('24/3_Scenes-from-Contaminations-2022.jpg'),
      },
      {
        url: abs('24/4_Scenes-from-Contaminations-2022.jpg'),
        thumbnail: abs('24/4_Scenes-from-Contaminations-2022.jpg'),
      },
      {
        url: abs('24/5_Scenes-from-Contaminations-2022.jpg'),
        thumbnail: abs('24/5_Scenes-from-Contaminations-2022.jpg'),
      },
    ],
    date: '27.09.2023 20:00',
    category: 'Film screening and Conversation',
    fonts: 1,
  },
  //35
  {
    title: <div>Bookshop</div>,
    author: 'Zabriskie',
    caption: <div>
      <div >
        Zabriskie is a specialised bookshop with a focus on nature, ecology,
        countercultures and visions, and books and magazines in German and English.
        It is almost like a private library as they only present publications that they
        personally appreciate, treasure and like.
      </div>
    </div>,
    gallery: [
      {
        url: abs('28/1_Zabriskie.jpg'),
        thumbnail: abs('28/1_Zabriskie.jpg'),
      },
      {
        url: abs('28/2_Zabriskie.jpg'),
        thumbnail: abs('28/2_Zabriskie.jpg'),
      },
      {
        url: abs('28/3_Zabriskie.jpg'),
        thumbnail: abs('28/3_Zabriskie.jpg'),
      },
      {
        url: abs('28/4_Zabriskie.jpg'),
        thumbnail: abs('28/4_Zabriskie.jpg'),
      },
      {
        url: abs('28/5_Zabriskie.jpg'),
        thumbnail: abs('28/5_Zabriskie.jpg'),
      },
    ],
    date: 'Thursday-Saturday',
    category: 'Bookshop',
    fonts: 2,
  },
  //36
  {
    title: <div>siasat</div>,
    author: <div>Iswanto Hartono<br />Reza Afisina</div>,
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_siasat_LOW.pdf'),
    },
    category: 'Reading',
    date: '28.09.2023 17:00',
    fonts: 3,
  },
  //37
  {
    title: <div>Harvest <br /> Moon Dinner</div>,
    author: <div>Gabriela Aquije<br /> Mónica Kisic</div>,
    caption: <div>
      <div>
        In this convivial gathering we wish to learn cooperation, reciprocity and collective nourishment
        with the milpa growing through the summer at the Floating University. On a friday afternoon we’ll
        share the labours of harvesting and cooking with these three crops, paying attention to our
        edible memories and pleasures in the process.
      </div><br />
      <div>
        Gabriela Aquije Zegarra is a Peruvian landscape architect and PhD candidate in art and design
        research at the MAKE/SENSE program between Linz and Basel.<br />
        Mónica Kisic is a culinary artist and chef with a Ph.D. in Molecular Biology and a Master's
        degree from Basque Culinary Center, Spain.
      </div>
    </div>,
    gallery: [
      {
        url: abs('25/1_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/1_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/2_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/2_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/3_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/3_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/4_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/4_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/5_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/5_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/6_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/6_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/7_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/7_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/8_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/8_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/9_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/9_Harvest-Moon-Dinner.jpg'),
      },
      {
        url: abs('25/10_Harvest-Moon-Dinner.jpg'),
        thumbnail: abs('24/10_Harvest-Moon-Dinner.jpg'),
      },
    ],
    date: '29.09.2023 17:00',
    category: 'Rhythmic Food Experience',
    fonts: 1,
  },
  //38
  {
    title: <div>Spaces, Processes, Creatures</div>,
    author: <div>Research Map</div>,
    caption:<div className='pdf'>
      <PDFDownloadSpaces/>
    </div>,
    pdf: {
      url: abs('pdfs/Spaces-Processes-Creatures_research-map.pdf'),
    },
    fonts:2,
  },
  //39
  {
    title: <div>Visual Language</div>,
    author: 'Studio YUKIKO',
    caption: <div>
      <div>
        For the 2023 edition, CRITTERKRATIA, the visual identity takes a playful approach to the idea
        of hybrid-coexistence by invoking the childhood game of drawing together, each person
        completing a piece of the picture without seeing each others input. As a result, hybrid-critters
        made of natural, urban, human and non-human parts roam our visuals.
      </div><br />
      <div>
        Studio Yukiko is a Berlin-based creative agency specializing in creative direction, art direction,
        brand strategy, concept generation and graphic design for commercial and cultural clients alike.
      </div>
    </div>,
    gallery: [
      {
        url: abs('30/01_yukiko-visual-language.png'),
        thumbnail: abs('30/01_yukiko-visual-language.png'),
      },
      {
        url: abs('30/02_yukiko-visual-language.jpg'),
        thumbnail: abs('30/02_yukiko-visual-language.jpg'),
      },
      {
        url: abs('30/03_yukiko-visual-language.jpg'),
        thumbnail: abs('30/03_yukiko-visual-language.jpg'),
      },
      {
        url: abs('30/04_yukiko-visual-language.jpg'),
        thumbnail: abs('30/04_yukiko-visual-language.jpg'),
      },
      {
        url: abs('30/05_yukiko-visual-language.jpg'),
        thumbnail: abs('30/05_yukiko-visual-language.jpg'),
      },
      {
        url: abs('30/06_yukiko-visual-language.jpg'),
        thumbnail: abs('30/06_yukiko-visual-language.jpg'),
      },
      {
        url: abs('30/07_yukiko-visual-language.jpg'),
        thumbnail: abs('30/07_yukiko-visual-language.jpg'),
      },
    ],
    fonts: 2,
  },
  //40
  {
    title: <div>Selected Readings</div>,
    author: 'Climate Care Research',
    pdf: {
      url: abs('pdfs/reader_cc_2023_archive_Selected-readings-from-Climate-Care-research_LOW.pdf'),
    },
    category: 'Reading',
    fonts: 3,
  },

]

const objectExported = { ...data, grid };
export default objectExported;