import React  from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'


class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastclick: undefined,
    }
  }

  componentDidMount() {
    // instantiate Video.js
    var handleSetPlayer = this.props.handleSetPlayer;
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      handleSetPlayer(this)
    });
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose()
  }

  render() {
    return (
      <div data-vjs-player>
        <video 
          playsInline={true}
          poster={this.props.poster}
          ref={ node => this.videoNode = node } 
          className={`video-js`}/>
      </div>
    )
  }
}



export default VideoPlayer;
